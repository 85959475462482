
export const validatePassword = (
    { password, currentPassword, confirmPassword }:
        { password: string, currentPassword: string, confirmPassword: string }
) => {
    let length = false;
    if (password.length >= 8) {
        length = true
    }

    let lower = false;
    if (/[a-z]/.test(password)) {
        lower = true;
    }

    let upper = false;
    if (/[A-Z]/.test(password)) {
        upper = true;
    }

    let number = false;
    if (/\d/.test(password)) {
        number = true;
    }

    let specialChars = false;
    if (/[^a-zA-Z0-9]/.test(password)) {
        specialChars = true
    }

    return {
        length,
        lower,
        number,
        specialChars,
        upper,
        valid: length && lower && number && specialChars && upper,
        equal: password === confirmPassword,
        currentHasValue: !!currentPassword,
    }
}

export function validateCvr(cvr: string) {
    const onlyNumbers = /^-?\d+\.?\d*$/.test(cvr)
    const correctLength = cvr.length === 8;
    return onlyNumbers && correctLength;
}

export function formatDate(d: unknown, offsetTimezone?: boolean) {
    if (!d) return "-";
    // @ts-ignore Does not matter what type
    const date = new Date(d);
    if (isNaN(date.getTime())) return "-";
    if (offsetTimezone) date.setHours(-2);
    const day = String(date.getDate()).padStart(2, "0")
    const month = String(date.getMonth() + 1).padStart(2, "0")
    const year = date.getFullYear()
    return `${day}-${month}-${year}`;
}
export function formatDateWithoutYear(d: unknown, offsetTimezone?: boolean) {
    if (!d) return "-";
    // @ts-ignore Does not matter what type
    const date = new Date(d);
    if (isNaN(date.getTime())) return "-";
    if (offsetTimezone) date.setHours(-2);
    const day = String(date.getDate()).padStart(2, "0")
    const month = String(date.getMonth() + 1).padStart(2, "0")
    return `${day}.${month}`;
}
export function formatDateWithElapsedTime(d: unknown, offsetTimezone?: boolean) {
    if (!d) return "-";
    // @ts-ignore Does not matter what type
    const date = new Date(d);
    if (isNaN(date.getTime())) return "-";
    if (offsetTimezone) date.setHours(-2);
    const elapsed = Date.now() - date.getTime();
    const totalSeconds = Math.floor(elapsed / 1000);
    const days = Math.floor(totalSeconds / (3600 * 24));
    const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    if (days > 0) {
        const dayMonthYear = formatDate(d, offsetTimezone);
        return `${dayMonthYear} ${formatTime(d)}`
    } else if (hours < 1) {
        return `${minutes} minutter siden`
    } else {
        return `${hours} timer siden`
    }
}

export function formatTime(d: unknown) {
    // @ts-ignore Does not matter what type
    const date = new Date(d);
    if (isNaN(date.getTime())) return "-";
    const hours = String(date.getHours()).padStart(2, "0")
    const minutes = String(date.getMinutes() + 1).padStart(2, "0")
    return `${hours}:${minutes}`;
}

export function formatPhone(phone: string) {
    const startsWithPlus = phone.startsWith("+")
    if (startsWithPlus) {
        return phone.split('').reduce((acc, curr, i) => {
            if (i === phone.length - 1) {
                return acc + curr
            }
            if (startsWithPlus && i < 2) {
                return acc + curr
            }
            return i % 2 === 0 ? acc + curr + " " : acc + curr;
        }, "")
    } else {
        return phone.split('').reduce((acc, curr, i) => {
            if (i === phone.length - 1) {
                return acc + curr
            }
            return i % 2 === 1 ? acc + curr + " " : acc + curr;
        }, "")
    }
}

export const getDaysInMonth = (year: number, month: number) => new Date(year, month, 0).getDate()


export function formatPrice(val: unknown, noDecimals?: boolean) {
    const num = Number(val);
    if (isNaN(num)) return "-";

    return Intl
        .NumberFormat('da-DK', { currency: "DKK", style: "currency", maximumFractionDigits: noDecimals ? 0 : 2 })
        .format(num)
}
export function formatNumber(val: unknown) {
    const num = Number(val);
    if (isNaN(num)) return "-";

    return Intl
        .NumberFormat('da-DK')
        .format(num)
}
export function formatPercent(val: unknown) {
    const num = Number(val);
    if (isNaN(num)) return "-";

    return Intl
        .NumberFormat('da-DK', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
            style: "percent"
        })
        .format(num)
}


export const STATUS = {
    CANCELLED: "1",
    EXPIRED: "2",
    COMPLETED: "3",
    ACCEPTED: "4",
    PRE_ASSESSED: "5",
    UNDER_PROCESSING: "6",
    NEW_LEAD: "7",
    PAID: "10",
    INITIALIZED: "11",
    REJECTED: "13",
    OPEN: "19",
} as const
export function getStatusColorClassName(value: string) {
    if (value === STATUS.CANCELLED) return "dmf-status-cancelled";
    else if (value === STATUS.EXPIRED) return "dmf-status-expired";
    else if (value === STATUS.COMPLETED) return "dmf-status-completed";
    else if (value === STATUS.ACCEPTED) return "dmf-status-accepted";
    else if (value === STATUS.PRE_ASSESSED) return "dmf-status-pre_assessed";
    else if (value === STATUS.UNDER_PROCESSING) return "dmf-status-under_processing";
    else if (value === STATUS.NEW_LEAD) return "dmf-status-new_lead";
    else if (value === STATUS.PAID) return "dmf-status-paid";
    else if (value === STATUS.INITIALIZED) return "dmf-status-initialized";
    else if (value === STATUS.REJECTED) return "dmf-status-rejected";
    else if (value === STATUS.OPEN) return "dmf-status-open";
    else return "dmf-status-fallback";
}




export const vinCheckRegex = /^[A-Z0-9]{17,17}$/;
/**
 * Validate that vin contains only UPPERCASE letters and NUMBERS and is 17 CHARACTERS LONG
 */
export function validateVin(vin: string) {
    return vinCheckRegex.test(vin)
}
