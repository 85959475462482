import HandshakeDuotone from '@assets/images/handshake-duotone.svg';

type Props = {
    label: string;
}
export default function CaseCompleted({ label }: Props) {
    return (

        <div className="flex justify-between items-center py-4 px-6 rounded-lg bg-gradient-to-r from-dark-blue-hover via-65% via-primary to-dark-blue-active">
            <h3 className="sm:text-xl font-medium text-white">
                {label}
            </h3>
            <img className='sm:size-12 size-8' src={HandshakeDuotone} />
        </div>
    )
}
