import IFrameOfOldEmbeddedFrontend from '@/features/IFrameOfOldEmbeddedFrontend';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/_protected/_equipment/equipment/create')({
    meta: () => [{ title: `Udstyrsliste · NY` }],
    component: CarDataComponentOuter
})

const url = import.meta.env.VITE_OLD_APP_URL + '/udstyrsliste/ny';

function CarDataComponentOuter() {
    return <IFrameOfOldEmbeddedFrontend src={url} />
}
