import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { t } from "i18next";

export default function CasePurchased() {
    return (
        <div className="text-center">
            <CheckCircleIcon className="mx-auto size-20 text-success mb-4" />
            <h3 className="text-lg mt-2 mb-1 text-dark-blue font-medium">
                {t("paid")}!
            </h3>
            <p className="text-sm">
                {t("content:The_registration_fee_has_now_been_paid._We_are_now_ensuring_the_valuation_of_the_vehicle_in_the_DMR,_and_you_will_receive_a_receipt_for_this_as_soon_as_possible.")}
            </p>
        </div>
    )
}
