import { logoutOldApplicationInsideIframe } from '@/hooks/useAuth';
import { getUser } from '@/services/auth';
import { createFileRoute, isRedirect, Outlet, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/_auth')({
    loader: async () => {
        try {
            const data = await getUser()
            if (data) {
                throw redirect({
                    to: "/dashboard",
                })
            }
        } catch (err) {
            if (isRedirect(err)) throw err;
            logoutOldApplicationInsideIframe()
        }
    },
    component: Outlet
})
