import { t } from "i18next";

export default function GenericError() {

    return (
        <div className="flex items-start justify-center flex-col w-full p-1.5 bg-red-100 text-center">
            <h1 className="text-red-600 text-lg">{t("status:error.Something went wrong")}</h1>
            <p className="text-red-400 text-sm">
                {t("status:error.Sorry, an unexpected error occurred. Please try again later.")}
            </p>
        </div>
    );
} 1
