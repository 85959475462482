import { ChangePassword } from '@/types/auth';
import { ReactFormSubmit } from '@/types/helperTypes';
import { validatePassword } from '@/utils/string';
import { PasswordRequirement } from '@components/PasswordRequirement';
import { Spinner } from '@components/Spinner';
import { Button, Field, Input, Label } from '@headlessui/react';
import { changePassword } from '@services/hub';
import { useMutation } from '@tanstack/react-query';
import { errorMessage, successMessage } from '@utils/toast';
import { isAxiosError } from 'axios';
import { t } from 'i18next';
import { useCallback, useEffect, useState } from 'react';

export default function UpdatePassword() {

    const [currentPassword, setCurrentPassword] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [requirements, setRequirements] = useState(validatePassword({ confirmPassword, currentPassword, password }))

    const { mutate, isPending: mutationPending, error } = useMutation({
        mutationFn: async (data: ChangePassword) => {
            return changePassword(data)
        },
        onSuccess: () => {
            successMessage(t("status:success.passwordChanged"))
        },
        onError: (error) => {
            errorMessage(error)
        }
    })

    const resetForm = useCallback(() => {
        setCurrentPassword('')
        setPassword('')
        setConfirmPassword('')
    }, [])


    useEffect(() => {
        const validation = validatePassword({
            confirmPassword,
            currentPassword,
            password
        })
        setRequirements(validation)

    }, [currentPassword, password, confirmPassword])


    async function onSubmit(e: ReactFormSubmit) {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        mutate({
            currentPassword: data.get('currentPassword') as string,
            password: data.get('password') as string,
            passwordConfirmation: data.get('passwordConfirmation') as string
        }, {
            onSuccess: resetForm
        })
    }

    return (

        <Spinner loading={mutationPending}>
            <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-10">
                <div className="px-4 sm:px-0 md:col-span-4">
                    <h2 className="text-lg font-semibold leading-7 text-gray-700">{t("resetPassword")}</h2>
                    <p className="mt-3 text-sm">{t("content:profile.We recommend that you reset your password at least every six months, for extra security.")}</p>
                    <p className="mt-4 font-medium text-sm leading-6 text-gray-700">{t("content:profile.Password requirements")}:</p>
                    <p className="mt-2 text-sm">{t("content:profile.Please follow this guide for a strong password.")}</p>
                    <ul className='list-disc pl-4 mt-2 text-sm'>
                        <li>{t("content:profile.A special character")}</li>
                        <li>{t("content:profile.Minimum 8 characters")}</li>
                        <li>{t("content:profile.Minimum 1 number")}</li>
                        <li>{t("content:profile.Minimum 1 uppercase (A) and 1 lowercase (a) letter")}</li>
                        <li>{t("content:profile.Change your password often")}</li>
                    </ul>
                </div>

                <form className=" md:col-span-6" onSubmit={onSubmit}>
                    <div className="grid gap-4">
                        <Field className="">
                            <Label className="input-label">
                                {t("password")}
                            </Label>
                            <Input
                                type="password"
                                name="currentPassword"
                                className="input mt-1"
                                value={currentPassword}
                                onChange={(e) => setCurrentPassword(e.target.value)}
                            />
                            {error && isAxiosError(error) && error.response?.data?.errors?.currentPassword && (
                                <ul className='grid sm:grid-cols-2 gap-2 max-w-xl mt-3'>
                                    <PasswordRequirement label={error.response.data.errors.currentPassword} valid={false} />
                                </ul>
                            )}

                        </Field>

                        <Field className="">
                            <Label className="input-label">
                                {t("newPassword")}
                            </Label>
                            <Input
                                type="password"
                                name="password"
                                autoComplete='off'
                                className="input mt-1"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            {!!password.length && (
                                <ul className='grid sm:grid-cols-2 gap-2 max-w-xl mt-3'>
                                    <PasswordRequirement label={t("password8CharsLong")} valid={requirements.length} />
                                    <PasswordRequirement label={t("includeSpecialCharacters")} valid={requirements.specialChars} />
                                    <PasswordRequirement label={t("mustIncludeLowerCase")} valid={requirements.lower} />
                                    <PasswordRequirement label={t("mustIncludeUppercase")} valid={requirements.upper} />
                                    <PasswordRequirement label={t("mustIncludeNumber")} valid={requirements.number} />
                                </ul>
                            )}
                        </Field>


                        <Field className="">
                            <Label className="input-label">
                                {t("confirmNewPassword")}
                            </Label>
                            <Input
                                type="password"
                                name="passwordConfirmation"
                                className="input mt-1"
                                autoComplete='off'
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                            {!!confirmPassword.length && (
                                <ul className='mt-3 grid sm:grid-cols-2 gap-2 max-w-xl'>
                                    <PasswordRequirement label={t("passwordMustMatch")} valid={requirements.equal && !!confirmPassword} />
                                </ul>
                            )}
                        </Field>

                        <div className="flex justify-end ">
                            <Button
                                type="submit"
                                className="btn btn-primary"
                            >
                                {t("save")}
                            </Button>
                        </div>
                    </div>
                </form>
            </div>

        </Spinner>

    )
}
