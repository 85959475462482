import DefinitionList from "@/components/DefinitionList";
import { Spinner } from "@/components/Spinner";
import { companyConfig } from "@/config";
import { acceptTaxCaseByInvoice } from "@/services/tax";
import { useTaxCaseAssessment, useTaxCaseBillingDestination, useTaxCaseId, useTaxCaseStatus } from "@/stores/taxCaseStore";
import { TaxCaseAssessment } from "@/types/taxTypes";
import { formatPrice } from "@/utils/string";
import { isAssessmentState } from "@/utils/taxCaseStatus";
import { errorMessage, successMessage } from "@/utils/toast";
import { Button, Checkbox, Field, Label, Radio, RadioGroup } from "@headlessui/react";
import { BuildingOffice2Icon, CheckIcon } from "@heroicons/react/24/outline";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { t } from "i18next";
import { useState } from "react";

const expectedRegistrationDateOptions = [
    {
        value: 3,
        label: "0-3 dage",
    },
    {
        value: 30,
        label: "3-30 dage",
    },
    {
        value: null,
        label: "Ved ikke",
    },
];

type Props = {
    closeSlideOver: () => void
}
export default function AcceptTaxCase({ closeSlideOver }: Props) {
    const assessment = useTaxCaseAssessment()
    const caseId = useTaxCaseId()
    const billingDestinations = useTaxCaseBillingDestination()
    const status = useTaxCaseStatus()

    const [billingDestinationId, setBillingDestinationId] = useState(billingDestinations?.[0].id)
    const [termsAccepted, setTermsAccepted] = useState(false)
    const [fuldmagt, setFuldmagt] = useState(false)
    const [expectedRegistrationDate, setExpectedRegistrationDate] = useState(expectedRegistrationDateOptions[0].value)

    const queryClient = useQueryClient();
    const acceptCase = useMutation({
        mutationKey: ['taxCase', caseId],
        mutationFn: async () => {
            if (!assessment || !isAssessmentState(status)) {
                closeSlideOver()
                throw new Error("Mangler en offentliggjort Forhåndsvurdering")
            }

            return await acceptTaxCaseByInvoice(caseId, {
                status: termsAccepted && fuldmagt,
                billingDestinationId: billingDestinationId,
                expectedRegistrationDate: addNDaysToToday(expectedRegistrationDate)
            })
        },
        onSuccess: async (data) => {
            queryClient.setQueryData(['taxCase', caseId], data.data.data)
            await queryClient.invalidateQueries({ queryKey: ['taxCase', caseId] })
            successMessage(t("status:success.taxCaseAccepted", { taxCaseId: caseId }))
        },
        onError(error) {
            errorMessage(error)
        }
    })

    const onTermsChange = (checked: boolean) => setTermsAccepted(checked)
    const onFuldmagtChange = (checked: boolean) => setFuldmagt(checked)

    function grabPrice(key: keyof TaxCaseAssessment) {
        if (assessment) {
            return formatPrice(assessment[key], true)
        }
        return t("awaiting")
    }
    const VATPrice = assessment?.total && assessment?.totalNoVat
        ? formatPrice(Math.ceil(assessment.total - assessment.totalNoVat), true)
        : t("awaiting")

    return (
        <Spinner loading={acceptCase.isPending}>
            <h2 className="text-2xl font-semibold text-dark-blue">{t("payment")}</h2>
            <p className='sm:text-sm sm:font-medium text-[13px] leading-[22px] mt-2'>
                {t("content:accept.Upon acceptance, you will immediately receive a payment notification for the agreed amount. While we prepare the final invoice, you have the option to transfer with instant payment for even faster processing.")}
            </p>
            {billingDestinations && billingDestinations.length > 1 && (
                <>
                    <h3 className="font-semibold mt-6 mb-3 text-dark-blue">{t("choose") + " " + t("invoicingAddress")}</h3>
                    <RadioGroup className="space-y-2" value={billingDestinationId} onChange={setBillingDestinationId}>
                        {billingDestinations.map((item) => (
                            <Radio key={item.id} value={item.id}
                                className={"flex items-center cursor-pointer gap-4 p-4 rounded-lg transition-colors border data-[checked]:border-primary"}
                            >
                                <div className='rounded-lg bg-gray-50 w-10 h-10 flex items-center justify-center text-primary'>
                                    <BuildingOffice2Icon aria-hidden="true" className='size-5' />
                                </div>
                                <div>
                                    <p className='text-dark-blue-active text-sm font-semibold mb-1'>
                                        {item.address} {item.zip} {item.city}
                                    </p>
                                    <p className="text-xs font-medium">
                                        {t("cvr")}: {item.cvr}
                                    </p>

                                </div>
                            </Radio>
                        ))}
                    </RadioGroup>
                </>
            )}

            <hr className='my-8' />

            <h3 className="font-semibold mb-3 text-dark-blue">{t("paymentTotal")}</h3>
            <DefinitionList items={[
                { label: t("estimatedRegistrationFee"), value: grabPrice('estimatedRegistrationFee') },
                { label: t("estimatedRiskFee"), value: grabPrice('estimatedCollateral') },
                { label: t("expeditionFee"), value: grabPrice('fee') },
                { label: t("numberPlates"), value: grabPrice('numberPlates') },
                { label: t("totalPrice"), value: grabPrice('total'), strong: true },
                { label: t("includingVat"), value: VATPrice },
            ]} />

            <hr className='my-8' />
            <p className="text-sm font-semibold text-dark-blue">Hvornår forventer du at registrere køretøjet?</p>
            <RadioGroup
                value={expectedRegistrationDate}
                onChange={setExpectedRegistrationDate}
                className="mt-2 mb-4 flex gap-3 flex-wrap max-md:flex-col"
            >
                {expectedRegistrationDateOptions.map(option => (
                    <Field key={option.value} className="flex-1" >
                        <Radio
                            value={option.value}
                            className="flex items-center gap-2 cursor-pointer rounded-sm p-2 border border-dashed border-gray-300 group transition-colors data-[checked]:border-primary"
                        >
                            <span className="flex size-4 items-center justify-center rounded-full border  transition-colors group-data-[checked]:border-primary">
                                <span className="size-1 rounded-full transition-opacity bg-primary opacity-0 group-data-[checked]:opacity-100" />
                            </span>
                            <Label className="cursor-pointer text-dark-blue text-base">{option.label}</Label>
                        </Radio>

                    </Field>
                ))}
            </RadioGroup>

            <h3 className="font-semibold mb-3 text-dark-blue">{t("confirmation")}</h3>
            <Field className="flex items-start gap-2 mb-4 flex-wrap text-sm max-sm:text-xs">
                <Checkbox
                    className="group flex-none size-6 rounded-md bg-gray-200 p-1 ring-1 ring-gray-300 ring-inset data-[checked]:bg-white"
                    aria-required="true"
                    checked={termsAccepted}
                    onChange={onTermsChange}
                >
                    <CheckIcon className="hidden flex-none size-4 fill-black group-data-[checked]:block" />
                </Checkbox>
                <Label className="flex-1 min-w-0 text-sm break-words">
                    Jeg bekræfter at have læst og accepteret de generelle {' '}
                    <a onClick={e => e.stopPropagation()}
                        className="link"
                        target="_blank"
                        href={companyConfig.links.businessTerms}
                    >
                        forretningsbetingelser
                    </a>
                    {' '} for værdifastsættelse af køretøjer ved Dansk Motor Finans
                </Label>
            </Field>
            <Field className="flex items-start gap-2 mb-4 flex-wrap text-sm max-sm:text-xs">
                <Checkbox
                    className="group flex-none size-6 rounded-md bg-gray-200 p-1 ring-1 ring-gray-300 ring-inset data-[checked]:bg-white"
                    aria-required="true"
                    checked={fuldmagt}
                    onChange={onFuldmagtChange}
                >
                    <CheckIcon className="hidden flex-none size-4 fill-black group-data-[checked]:block" />
                </Checkbox>
                <Label className="flex-1 min-w-0 text-sm break-words">
                    {t("status:terms.fuldmagt")}
                </Label>
            </Field>

            {/* <hr className='my-8' /> */}

            <hr className='my-8' />

            <Button disabled={!fuldmagt || !termsAccepted}
                onClick={() => acceptCase.mutate()}
                className="btn btn-primary w-full btn-lg">
                {t("accept")}
            </Button>
        </Spinner>
    )
}

function addNDaysToToday(days: number | null) {
    if (!days) return null;
    const today = new Date();
    today.setDate(today.getDate() + days)
    return today.toISOString();
}
