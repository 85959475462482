import DMFLogo from "@assets/DMF-Logo/DMFPRO/Horizontal/dmf-white3x.png";
import { Button, Field, Fieldset, Input, Label } from '@headlessui/react';
import { ArrowLongLeftIcon } from '@heroicons/react/24/outline';
import * as Sentry from "@sentry/react";
import { useMutation } from '@tanstack/react-query';
import {
    createFileRoute,
    useParams,
    useRouter
} from '@tanstack/react-router';
import { t } from 'i18next';
import { ChangeEvent, useState } from 'react';
import { PasswordRequirement } from '../../components/PasswordRequirement';
import { UnAuthenticatedLayout } from '../../components/UnauthenticatedLayout';
import { resetPassword } from '../../services/auth';
import { validatePassword } from '../../utils/string';
import { errorMessage, successMessage } from '../../utils/toast';

export const Route = createFileRoute('/_auth/forgot-password/$token')({
    meta: () => [{ title: t("resetPassword") }],
    component: ForgotPassword,
})

function ForgotPassword() {
    const [requirements, setRequirements] = useState<ReturnType<typeof validatePassword> | null>(null)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const params = useParams({ from: "/_auth/forgot-password/$token" })
    const router = useRouter()
    const { mutate, isPending } = useMutation({
        mutationFn: async (data: Parameters<typeof resetPassword>[0]) => {
            return resetPassword(data)
        },
        onSuccess: () => {
            successMessage(t("status:success.newPasswordSaved"))
            router.invalidate()
            router.navigate({ to: "/login" })
        },
        onError: (error) => {
            errorMessage(error)
        }
    })

    function onPasswordChange(e: ChangeEvent<HTMLInputElement>) {
        try {
            setPassword(e?.target?.value)
            const validation = validatePassword({
                confirmPassword: '',
                currentPassword: '',
                password: e?.target?.value
            })
            setRequirements(validation)
        } catch (error) {
            Sentry.captureException(error)
        }
    }

    const onFormSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
        try {
            evt.preventDefault()
            mutate({
                email,
                password,
                token: params.token
            })
        } catch (error) {
            errorMessage(error)
        }
    }
    const goBackToLogin = () => router.navigate({ to: "/login" });

    return (

        <UnAuthenticatedLayout>
            <img
                className="mx-auto w-64 z-10 relative"
                src={DMFLogo}
                alt="DMF Pro"
            />
            <div className="mt-20 sm:mx-auto sm:w-full sm:max-w-md bg-white sm:px-12 sm:py-8 p-4 rounded-sm">
                <h2 className="mb-10 text-center text-2xl font-bold leading-9 tracking-tight text-dark-blue-hover">
                    {t("resetPassword")}
                </h2>
                <Button className="-ml-4 mb-2 link btn" onClick={goBackToLogin}>
                    <ArrowLongLeftIcon className='size-5' aria-hidden="true" />
                    {t("back")}
                </Button>
                <form className="space-y-6" onSubmit={onFormSubmit}>
                    <Fieldset disabled={isPending} className="w-full grid gap-4">
                        <Field>
                            <Label className="input-label">
                                {t("email")}
                            </Label>
                            <Input
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                name="email"
                                type="email"
                                autoComplete="off"
                                required
                                className="input mt-1"
                            />
                        </Field>
                        <Field>
                            <Label className="input-label">
                                {t("newPassword")}
                            </Label>
                            <Input
                                value={password}
                                name="password"
                                type="password"
                                autoComplete="off"
                                required
                                className="input mt-1"
                                onChange={onPasswordChange}
                            />
                            {!!requirements && (
                                <ul className='grid gap-2 max-w-xl mt-3'>
                                    <PasswordRequirement label={t("password8CharsLong")} valid={!!requirements?.length} />
                                    <PasswordRequirement label={t("mustIncludeLowerCase")} valid={!!requirements?.lower} />
                                    <PasswordRequirement label={t("mustIncludeUppercase")} valid={!!requirements?.upper} />
                                    <PasswordRequirement label={t("mustIncludeNumber")} valid={!!requirements?.number} />
                                    <PasswordRequirement label={t("includeSpecialCharacters")} valid={!!requirements?.specialChars} />
                                </ul>
                            )}
                        </Field>

                        <Button
                            type="submit"
                            disabled={!email || !requirements?.valid}
                            className="btn btn-primary justify-center mt-4"
                        >
                            {isPending ? t('loading') : t("resetPassword")}
                        </Button>
                    </Fieldset>
                </form>
            </div>
        </UnAuthenticatedLayout>
    )
}
