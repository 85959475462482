import { EnumItem } from "@/types/appTypes";
import { TaxCaseResource } from "@/types/taxTypes";
import { taxCaseProgressSteps } from "@/utils/taxCaseStatus";
import clsx from "clsx";

type ProcessTimeLineProps = {
    status: EnumItem,
    flags: TaxCaseResource['flags']
}
export function TaxCaseProgressTimeLine({ status, flags }: ProcessTimeLineProps) {
    const isPurchased = !!flags?.paymentReceivedAt
    const currentStepIndex = isPurchased && status.value !== '3' // 3 = Gennemført. This means the case is at step 'Betalt'
        ? 3 // index 3 which is step 4.
        : taxCaseProgressSteps.findIndex(step => step.value.includes(status.value))

    return (
        <div aria-label="Progress" className="mb-6">
            <ol role="list" className="flex sm:items-center sm:justify-between max-sm:flex-col">
                {taxCaseProgressSteps.map((step, index) => {

                    // Default: to-be-completed step
                    let lineColor = 'bg-gray-200';
                    let dotColor = 'bg-gray-300';
                    let textColor = "text-text-gray";
                    if (index === currentStepIndex) { // Current step
                        lineColor = 'bg-primary/50';
                        dotColor = 'bg-primary';
                        textColor = "text-primary";
                    } else if (index < currentStepIndex) { // Completed steps
                        lineColor = 'bg-primary';
                        dotColor = 'bg-primary';
                        textColor = "text-text-gray";
                    }

                    let lineDisplay = 'block'
                    let textAlignment = "sm:-translate-x-1/2 left-3";
                    let listItemFlex = 'flex-1'
                    if (index === 0) {
                        textAlignment = "left-0";
                    } else if (index === taxCaseProgressSteps.length - 1) {
                        textAlignment = "right-0";
                        listItemFlex = 'flex-none';
                        lineDisplay = 'hidden';
                    }


                    return (
                        <li key={step.name} className={clsx('relative max-sm:flex max-sm:gap-2', listItemFlex)}>
                            <div className="flex items-center max-sm:flex-col" aria-hidden="true">
                                <span className="size-6 bg-transparent flex items-center justify-center">
                                    <span className={clsx("size-2 rounded-full ", dotColor)}></span>
                                </span>
                                <div className={clsx("sm:h-0.5 sm:w-full max-sm:h-10 max-sm:w-0.5 bg-gray-100", lineColor, lineDisplay)} />
                            </div>
                            <div className={clsx("sm:absolute top-6 text-sm text-dark-blue max-sm:-mt-1.5", textColor, textAlignment)}>{step.name}</div>
                        </li>
                    )
                }

                )}
            </ol>
        </div>
    )
}
