import { Button, Dialog, DialogPanel, DialogTitle, Field, Fieldset, Input, Label, Transition, TransitionChild } from '@headlessui/react';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { t } from 'i18next';
import { ChangeEvent, useState } from 'react';
import { Spinner } from '../../components/Spinner';
import { useBillingMutation } from '../../hooks/useBillingDestination';
import { getCVRData } from '../../services/hub';
import { validateCvr } from '../../utils/string';
import { errorMessage, successMessage } from '../../utils/toast';

const useCvrMutation = (cvr: string) => {
    return useMutation({
        mutationKey: ['getCVRData', cvr],
        mutationFn: async (data: Parameters<typeof getCVRData>[0]) => (await getCVRData(data)).data.data,
        onError(error) {
            errorMessage(error)
        },
    })
}


type Props = {
    open: boolean;
    setOpen: (open: boolean) => void;
}
export default function AddNewInvoicingAddress({ open, setOpen }: Props) {
    const [cvr, setCvr] = useState('');
    const [billingEmail, setBillingEmail] = useState('');
    const [cvrValid, setCvrValid] = useState(false)
    const [emailValid, setEmailValid] = useState(false)
    const queryClient = useQueryClient()

    const { mutate, data, reset, isPending: isFetchingCVR } = useCvrMutation(cvr)
    const { mutate: save, isPending: isSaving } = useBillingMutation()

    const onCvrChange = (e: ChangeEvent<HTMLInputElement>) => {
        setCvr(e.target.value)
        setCvrValid(validateCvr(e.target.value))
        reset()
    }
    const onEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
        setBillingEmail(e.target.value)
        setEmailValid(e.target.checkValidity())
    }
    const onFetchCvrData = () => mutate({ cvr });


    function onSave() {
        if (!data) {
            return errorMessage(t("status:error.unknownError"));
        }
        save(
            {
                ...data,
                email: billingEmail
            },
            {
                onSuccess(data) {
                    successMessage(t("status:success.billingAddressAdded") + ": " + data.data.data.title)
                    setOpen(false)
                    queryClient.invalidateQueries({ queryKey: ['billingDestinations'] })
                    reset()
                },
            }
        )
    }

    function onCancel() {
        // TODO --> Reset form?
        setOpen(false)
    }

    return (
        <Transition show={open}>
            <Dialog className="relative z-50" onClose={setOpen}>
                <TransitionChild>
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity duration-300 data-[closed]:opacity-0" />
                </TransitionChild>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <TransitionChild>
                            <DialogPanel className="relative overflow-hidden rounded-lg bg-white p-4 text-left shadow-xl sm:my-8 sm:w-full sm:max-w-2xl sm:p-12 transition transform-gpu duration-300 data-[closed]:opacity-0 data-[closed]:translate-y-4 data-[closed]:scale-90">
                                <Spinner loading={isFetchingCVR || isSaving}>
                                    <DialogTitle as="h3" className="text-2xl font-semibold leading-6 text-gray-900">
                                        {t("add") + " " + t("invoicingAddress")}
                                    </DialogTitle>
                                    <p className="mt-5 text-sm text-text-secondary">
                                        {t("content:invoicing.Get an invoice issued for the registration fee for a different CVR number. It can be an advantage if your business has other departments with separate CVR numbers.")}
                                    </p>
                                    <Fieldset className='sm:mt-12 mt-6'>
                                        <div className='flex gap-3'>
                                            <Field className={"flex-1"}>
                                                <Label className="input-label">{t("cvr") + " " + t("number")}</Label>
                                                <Input
                                                    type='text'
                                                    className="input mt-1"
                                                    autoComplete='billing tel'
                                                    value={cvr}
                                                    onChange={onCvrChange}
                                                />
                                            </Field>
                                            <Button onClick={onFetchCvrData}
                                                disabled={!cvrValid}
                                                className="btn btn-outline flex-none self-end"
                                            >
                                                <ArrowPathIcon className="h-5 w-5" aria-hidden="true" />
                                                {t("updateFromVirk")}
                                            </Button>
                                        </div>
                                        <ul className='mt-4 sm:p-6 p-4 rounded-lg bg-gray-100 text-sm font-medium'>
                                            <li>{data?.title}&nbsp;</li>
                                            <li>{data?.address} &nbsp;</li>
                                            <li>{data?.zip} {data?.city} &nbsp;</li>
                                        </ul>
                                        <Field className="mt-6">
                                            <Label className="input-label">{t("invoicingEmail")}</Label>
                                            <Input
                                                type='email'
                                                autoComplete='billing email'
                                                className="input mt-1"
                                                value={billingEmail}
                                                onChange={onEmailChange}

                                            />
                                        </Field>
                                    </Fieldset>

                                    <div className="mt-6 sm:mt-12 flex justify-between sm:gap-3 pt-6 border-t">
                                        <Button
                                            className="btn btn-outline"
                                            onClick={onCancel}
                                        >
                                            {t("cancel")}
                                        </Button>
                                        <Button
                                            className="btn btn-primary"
                                            onClick={onSave}
                                            disabled={!data || !emailValid}
                                        >
                                            {t("save")}
                                        </Button>
                                    </div>
                                </Spinner>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}
