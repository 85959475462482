import { RouterContext } from '@/main';
import ContentLoading from '@components/ContentLoading';
import NotFound from '@components/NotFound';
import * as Sentry from '@sentry/react';
import { createRootRouteWithContext, ErrorComponent, Outlet, useMatches } from '@tanstack/react-router';
import React, { ReactNode, useEffect } from 'react';

const TITLE = "DMF Pro"
export const Route = createRootRouteWithContext<RouterContext>()({
    component: RootComponent,
    notFoundComponent: NotFound,
    pendingComponent: ContentLoading,
    meta: () => [{ title: TITLE }],
    onError(err) {
        Sentry.captureException(err)
    },
    errorComponent: ErrorComponent
})

function Meta({ children }: { children: ReactNode }) {
    const matches = useMatches();
    const meta = matches.at(-1)?.meta?.find((meta) => meta.title);

    useEffect(() => {
        document.title = [meta?.title, TITLE].filter(Boolean).join(' · ');
    }, [meta]);

    return children;
}

function RootComponent() {
    return (
        <Meta>
            <Outlet />
            <TanStackRouterDevtools initialIsOpen={false} />
            <ReactQueryDevtools initialIsOpen={false} />
        </Meta>
    )
}




const TanStackRouterDevtools =
    import.meta.env.PROD
        ? () => null // Render nothing in production
        : React.lazy(() =>
            // Lazy load in development
            import('@tanstack/router-devtools').then((res) => ({
                default: res.TanStackRouterDevtools,
                // For Embedded Mode
                // default: res.TanStackRouterDevtoolsPanel
            })),
        )
const ReactQueryDevtools =
    import.meta.env.PROD
        ? () => null
        : React.lazy(() =>
            import('@tanstack/react-query-devtools').then((res) => ({
                default: res.ReactQueryDevtools,
            })),
        )
