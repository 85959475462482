import BasicSlideOver from "@/components/BasicSlideOver";
import enums from "@/enums/enums";
import { Button, Field, Label, Radio, RadioGroup } from "@headlessui/react";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { t } from "i18next";
import { useState } from "react";

const customerTypes = enums.customer.type.filter(item => item.value !== "127");
const vehicleTypes = enums.vehicle.type.filter(item => item.value === "1" || item.value === "2") // Personbil | Varebil

type Props = {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    taxCaseId: string;
}
export default function CopyToLeasing({ open, setOpen, taxCaseId }: Props) {
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const [customerTypeId, setCustomerTypeId] = useState(customerTypes[0].value)
    const [vehicleTypeId, setVehicleTypeId] = useState(vehicleTypes[0].value)

    function copyToLeasingCase() {
        navigate({
            to: "/leasing",
            from: "/tax/$id",
            state: (prev) => ({
                ...prev,
                toLeasing: {
                    customerTypeId: customerTypeId,
                    taxCaseId: taxCaseId,
                    vehicleTypeId: vehicleTypeId,
                }
            })

        })
        queryClient.invalidateQueries({ queryKey: ['user'] })
    }

    return (
        <BasicSlideOver open={open} setOpen={setOpen} >
            <div className="bg-white h-full w-full sm:p-8 p-4">
                <h2 className="text-lg font-semibold text-dark-blue mb-6">Opret leasingsag</h2>
                <Field className="flex items-center justify-between mb-2">
                    <Label className="text-sm">{t("customerType")}</Label>
                    <RadioGroup value={customerTypeId} onChange={setCustomerTypeId}
                        aria-label={t("customerType")}
                        className="flex bg-gray-100 w-fit rounded-sm"
                    >
                        {customerTypes.map((type) => (
                            <Field key={type.value} className="flex items-center gap-2">
                                <Radio
                                    value={type.value}
                                    className="btn btn-sm cursor-pointer data-[checked]:bg-primary data-[checked]:text-white"
                                >
                                    {type.name}
                                </Radio>
                            </Field>
                        ))}
                    </RadioGroup>
                </Field>
                <Field className="flex items-center justify-between">
                    <Label className="text-sm">{t("vehicleType")}</Label>
                    <RadioGroup value={vehicleTypeId} onChange={setVehicleTypeId}
                        aria-label={t("vehicleType")}
                        className="flex bg-gray-100 w-fit rounded-sm"
                    >
                        {vehicleTypes.map((type) => (
                            <Field key={type.value} className="flex items-center gap-2">
                                <Radio
                                    value={type.value}
                                    className="btn btn-sm cursor-pointer data-[checked]:bg-primary data-[checked]:text-white"
                                >
                                    {type.name}
                                </Radio>
                            </Field>
                        ))}
                    </RadioGroup>
                </Field>
                <div className="flex justify-end gap-2 mt-4 pt-4 border-t">
                    <Button className="btn btn-gray " onClick={() => setOpen(false)}>
                        {t("cancel")}
                    </Button>
                    <Button className="btn btn-primary" onClick={copyToLeasingCase}>
                        {t("createCase")}
                    </Button>
                </div>
            </div>
        </BasicSlideOver>
    )
}
