import { Button, Transition } from "@headlessui/react"
import { ShieldExclamationIcon } from "@heroicons/react/24/outline"
import { t } from "i18next"
import { useState } from "react"
import RequestRecalculation from "../RequestRecalculation"

export default function CaseExpired({ taxCaseId }: { taxCaseId: number | string }) {
    const [showForm, setShowForm] = useState(false)
    function onShow() {
        setShowForm(true)
    }
    if (showForm) {
        return (
            <Transition appear as="div" className="transition duration-300 data-[closed]:opacity-0 data-[closed]:-translate-y-12">
                <RequestRecalculation taxCaseId={taxCaseId} />
            </Transition>
        )
    }
    return (
        <div className="text-center">
            <ShieldExclamationIcon className="mx-auto mb-2 size-16 text-error" />
            <h3 className="text-lg text-dark-blue font-medium">
                {t("content:The_case_has_expired")}
            </h3>
            <p className="text-sm mt-2">
                {t("content:ThePreAssessmentHasExpiredDueToLegalLimits")}
            </p>
            <Button
                className="btn btn-primary mt-4"
                onClick={onShow}
            >
                {t("requestRecalculation")}
            </Button>
        </div>
    )
}
