import { Button, Dialog, DialogBackdrop, DialogPanel, DialogTitle } from "@headlessui/react";
import { useEffect, useRef, useState } from "react";

const COOKIEBOT_ID = import.meta.env.VITE_COOKIEBOT_ID

export default function CookieDeclarationButton() {
    const [isOpen, setIsOpen] = useState(false)

    function open() {
        setIsOpen(true)
    }

    function close() {
        setIsOpen(false)
    }

    return (
        <>
            <Button
                onClick={open}
                className="link"
            >
                Cookiepolitik
            </Button>
            <Dialog open={isOpen} as="div" className="relative z-10 focus:outline-none" onClose={close}>
                <DialogBackdrop transition className="fixed inset-0 bg-black/40 backdrop-blur-[1px] duration-300 data-[closed]:opacity-0" />
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 pt-24">
                        <DialogPanel
                            transition
                            className="w-full max-w-5xl rounded-xl bg-white p-6 duration-300 ease-out data-[closed]:scale-90 data-[closed]:translate-y-[500px] data-[closed]:translate-x-80  data-[closed]:opacity-0"
                        >
                            <DialogTitle as="h1" className="text-lg font-medium mb-8">
                                Cookie Deklaration
                            </DialogTitle>
                            <LoadCookiebotDeclarationScript />
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>
        </>

    )
}


function LoadCookiebotDeclarationScript() {
    const cookieRef = useRef<HTMLDivElement>(null)
    useEffect(() => {
        if (cookieRef.current) {
            const cookieDeclarationScript = document.createElement("script")
            cookieDeclarationScript.src = `https://consent.cookiebot.com/${COOKIEBOT_ID}/cd.js`
            cookieDeclarationScript.id = "CookieDeclaration"
            cookieDeclarationScript.async = true;
            cookieDeclarationScript.type = 'text/javascript'
            cookieRef.current.appendChild(cookieDeclarationScript)
        }
        return () => {
            if (cookieRef.current) {
                cookieRef.current.innerHTML = "";
            }
        }
    }, [])
    return (
        <div className="text-sm" ref={cookieRef}></div>
    )
}
