import { LinkResource, MediaResource, PaginatedData } from "../types/appTypes";
import { CreateTaxCaseByLink, CreateTaxCaseByVin, TaxCaseResource } from "../types/taxTypes";
import axios from "../utils/axios";

const API_BASE_URL = import.meta.env.VITE_API_URL;
const API_PATH = import.meta.env.VITE_API_PATH;
const API_URL = API_BASE_URL + API_PATH;


/**
 * Retrieve a paginated list of cases
 * GET http://localhost:8007/api/v2/tax/case
 * Query Parameters:
 *      filter
 *          q: string
 *          status: number
 *      per_page: string
 *      sort
 */
export const getTaxCases = (page?: number, perPage?: number, q?: string | number, status?: string | number, sort?: "id" | "-id") => {
    const search = new URLSearchParams(`page=${encodeURIComponent(page || 1)}&per_page=${encodeURIComponent(perPage || 20)}`)
    if (sort) search.append("sort", encodeURIComponent(sort || ''))
    if (q) {
        decodeURIComponent(String(q || '')).split(" ").forEach(item => {
            if (item) {
                search.append("filter[q]", encodeURIComponent(decodeURIComponent(String(item || ''))))
            }
        })
    }
    if (status) search.append("filter[status]", encodeURIComponent(status || ''))
    return axios.get<PaginatedData<TaxCaseResource>>(`${API_URL}/tax/case?${search.toString()}`);
}

/**
 * Retrieve the given tax case
 * GET http://localhost:8007/api/v2/tax/case/{taxCase}
 */
export const getTaxCase = (taxCaseId: string | number) => {
    return axios.get<{ data: TaxCaseResource }>(`${API_URL}/tax/case/${taxCaseId}`);
}

/**
 * Create a new tax case using a VIN as the source
 * POST http://localhost:8007/api/v2/tax/case/vin
 * See "creating with link" for details about the request values.
 */
export const createTaxCaseByVin = (data: CreateTaxCaseByVin) => {
    return axios.post<{ data: LinkResource | TaxCaseResource }>(`${API_URL}/tax/case/vin`, data);
}

/**
 * Create a new tax case using a LINK as the source
 * POST http://localhost:8007/api/v2/tax/case/vin
 */
export const createTaxCaseByLink = (data: CreateTaxCaseByLink) => {
    return axios.post<{ data: LinkResource | TaxCaseResource }>(`${API_URL}/tax/case/link`, data);
}

/**
 * Copy the case to a new import case
 * POST http://localhost:8007/api/v2/tax/case/{taxCase}/copyToImportCase
 */
export const copyTaxCaseToImportCase = (taxCaseId: string | number) => {
    return axios.post<{ data: LinkResource }>(`${API_URL}/tax/case/${taxCaseId}/copyToImportCase`);
}

/**
 * Copy the case to a new tax case
 * POST http://localhost:8007/api/v2/tax/case/{taxCase}/copyToTaxCase
 */
export const copyTaxCaseTotAXCase = (taxCaseId: string | number, data: { evaluationTypeId: number }) => {
    return axios.post<{ data: LinkResource }>(`${API_URL}/tax/case/${taxCaseId}/copyToTaxCase`, data);
}

/**
 * Accept the tax case (invoice)
 * POST http://localhost:8007/api/v2/tax/case/{taxCase}/accept
 * Confirms user acceptance of the evaluation and initiates the invoicing process for the case. This acceptance is binding.
 * NOTE: {status:boolean} ==> terms and conditions
 */
export const acceptTaxCaseByInvoice = (taxCaseId: string | number, data: { status: boolean, billingDestinationId: string | undefined, expectedRegistrationDate: string | null }) => {
    return axios.post<{ data: TaxCaseResource }>(`${API_URL}/tax/case/${taxCaseId}/accept`, data);
}

/**
 * Accept the tax case (saved payment card) \
 * POST http://localhost:8007/api/v2/tax/case/{taxCase}/payWithSavedCard \
 * Confirms user acceptance of the evaluation and initiates the invoicing process for the case with payment taken from the saved payment card. This acceptance is binding. \
 * NOTE: {status:boolean} ==> terms and conditions  \
 */
export const acceptTaxCaseBySavedCard = (taxCaseId: string | number, data: { status: boolean }) => {
    return axios.post<{ data: TaxCaseResource }>(`${API_URL}/tax/case/${taxCaseId}/payWithSavedCard`, data);
}

/**
 * Request a recalculation on the case \
 * POST http://localhost:8007/api/v2/tax/case/{taxCase}/recalculate \
 * Query Parameters
 *  payWithCard?: boolean
 *      Allows user to purchase the case by paying for it with card. If card option is selected, the user must navigate to the payment page to complete the purchase. This is meant to be used by first-party applications.
 * If the case has expired, and you would like it to be re-calculated based on the current market. \
 */
export const requestTaxCaseRecalculation = (taxCaseId: string | number, data: { mileage: number; note: string; paymentMethod: number | null; }) => {
    return axios.post<{ data: LinkResource | TaxCaseResource }>(`${API_URL}/tax/case/${taxCaseId}/recalculate`, data);
}

/**
 * Upload a file to the tax case \
 * POST http://localhost:8007/api/v2/tax/case/{taxCase}/media \
 * NOTE: --header 'Content-Type: multipart/form-data' \
 * NOTE: \<form method="post" action='http://localhost:8007/api/v2/tax/case/{taxCase}/media' encType='multipart/form-data'>
 * NOTE: exptects this shape: formData.append("items[]", file)
 */
export const uploadFileToTaxCase = (taxCaseId: string | number, formData: FormData) => {
    return axios.post<{ data: MediaResource }>(`${API_URL}/tax/case/${taxCaseId}/media`, formData);
}
export const uploadFileToTaxCaseURL = (taxCaseId: string | number) => {
    return `${API_URL}/tax/case/${taxCaseId}/media`
}


/**
 * Delete a file that belongs to the tax case \
 * DELELTE http://localhost:8007/api/v2/tax/case/{taxCase}/media/{media} \
 * NOTE: media --> The media UUID
 */
export const deleteFileFromTaxCase = (taxCaseId: string | number, mediaUUID: string) => {
    return axios.delete<{ data: MediaResource }>(`${API_URL}/tax/case/${taxCaseId}/media/${mediaUUID}`);
}
