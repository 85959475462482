import HourglassOutlined from "@/assets/icons/HoursglassOutlined";
import { t } from "i18next";

export default function InvoiceGenerated() {
    return (
        <div className="text-center">
            <span className="rounded-full size-16 flex items-center justify-center mx-auto bg-primary mb-4">
                <HourglassOutlined className="size-10 fill-white" />
            </span>
            <h3 className="text-lg text-dark-blue font-medium">
                {t("content:tax.status.Invoice is ready for you")}!
            </h3>
        </div>
    )
}
