import { Button, Field, Input, Label } from '@headlessui/react';
import { t } from 'i18next';
import { useRef } from 'react';
import ContentLoading from '../../components/ContentLoading';
import { Spinner } from '../../components/Spinner';
import { useDealerQuery, useMutateDealerContactData } from '../../hooks/useDealer';
import { ReactFormSubmit } from '../../types/helperTypes';


export default function UpdateDealerContactData() {
    const { data } = useDealerQuery()
    const { mutate, isPending: mutationPending, data: updatedDealer } = useMutateDealerContactData()
    const formRef = useRef<HTMLFormElement>(null)
    if (!data) {
        return <ContentLoading />
    }
    const dealer = data.data

    async function handleSubmit(e: ReactFormSubmit) {
        e.preventDefault();
        const formData = new FormData(e.currentTarget);
        mutate({
            phone: formData.get("phone")?.toString() || dealer.phone,
            email: formData.get("email")?.toString() || dealer.email,
            contactPerson: formData.get("contactPerson")?.toString() || dealer.contactPerson,
        })
    }


    return (

        <Spinner loading={mutationPending}>
            <div className="grid grid-cols-1 gap-8 md:grid-cols-10">
                <div className="md:col-span-4">
                    <h2 className="text-lg font-semibold text-gray-700">{t("contactInformation")}</h2>
                    <p className="text-sm text-text-secondary">
                        {t("content:dealer.Enter the company's primary contact information here.")}
                    </p>
                </div>

                <form ref={formRef} className="md:col-span-6 grid gap-4" onSubmit={handleSubmit}>

                    <Field>
                        <Label className="input-label">
                            {t("companyMainPhoneNumber")}
                        </Label>
                        <Input
                            type="tel"
                            name="phone"
                            autoComplete='work tel'
                            className="input mt-1"
                            defaultValue={updatedDealer?.data.phone ?? dealer.phone}
                        />
                    </Field>


                    <Field >
                        <Label className="input-label">
                            {t("companyEmail")}
                        </Label>
                        <Input
                            type="email"
                            name="email"
                            autoComplete='work email'
                            className="input mt-1"
                            defaultValue={updatedDealer?.data.email ?? dealer.email}
                        />
                    </Field>

                    <Field >
                        <Label className="input-label">
                            {t("primaryContactPerson")}
                        </Label>
                        <Input
                            type="tel"
                            name="contactPerson"
                            className="input mt-1"
                            defaultValue={updatedDealer?.data.contactPerson ?? dealer.contactPerson}
                        />
                    </Field>

                    <div className='flex justify-end'>
                        <Button
                            type='submit'
                            className="btn btn-primary"
                        >
                            {t("save")}
                        </Button>

                    </div>
                </form>
            </div>
        </Spinner>

    )
}
