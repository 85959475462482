import { HistoryState, useNavigate, useRouterState } from "@tanstack/react-router";
import { useEffect, useRef } from "react";

const OLD_APP_URL = import.meta.env.VITE_OLD_APP_URL
type Props = {
    src: string;
}

interface LocalHistoryState extends HistoryState {
    toLeasing?: {
        taxCaseId?: string,
        customerTypeId?: string,
        vehicleTypeId?: string,
    }
}

export default function IFrameOfOldEmbeddedFrontend({ src }: Props) {
    const ref = useRef<HTMLIFrameElement>(null)
    const routerState = useRouterState({ select: (s) => s.location.state }) as LocalHistoryState
    function postMessageToIFrame() {
        if (routerState.toLeasing && ref.current) {
            ref.current?.contentWindow?.postMessage({
                data: routerState, actionType: "copyTaxCaseToLeasingCase"
            }, "*")
        }

    }

    useEffect(() => {
        if (ref.current) {
            ref.current.onload = function () {
                postMessageToIFrame()
            }
        }
    }, [])


    const nav = useNavigate()
    useEffect(() => {
        const doNav = (event: MessageEvent<{ type: string, path: string, from: string }>) => {
            // Make sure the messages come from the correct origin:
            if (event.origin !== OLD_APP_URL) {
                return; // Incorrect Origin
            }
            const path = event.data.path;
            const from = event.data.from
            if (event.data.type !== "url-change" || typeof path !== 'string') {
                return // Wrong type
            } else if (path === '/bilimport') {
                return nav({ to: "/import" })
            } else if (path === '/bilimport/ny') {
                return nav({ to: "/import/create" })
            } else if (/^\/bilimport\/\d+$/.test(path)) { // '/bilimport/83249'
                const params = path.split("/").pop()
                return nav({ to: "/import/$id", params: { id: params ?? '' } })
            } else if (path === '/leasing-dmf') {
                return nav({ to: "/leasing" })
            } else if (path === '/leasing-dmf/ny' && !from?.includes("leasing")) {
                return nav({ to: "/leasing/create", replace: true })
            } else if (/^\/leasing-dmf\/\d+$/.test(path)) { // '/leasing-dmf/83249'
                const params = path.split("/").pop()
                return nav({ to: "/leasing/$id", params: { id: params ?? '' } })
            } else if (path === '/udstyrsliste') {
                return nav({ to: "/equipment" })
            } else if (path === '/udstyrsliste/ny') {
                return nav({ to: "/equipment/create" })
            } else if (path.indexOf('/tax') === 0) {
                return nav({ to: "/tax" })
            } else if (path === '/tax/ny') {
                return nav({ to: "/tax/create" })
            } else if (/^\/tax\/\d+$/.test(path)) { // '/tax/12345'
                const param = path.split('/').pop()
                return nav({ to: "/tax/$id", params: { id: param ?? '' } })
            } else if (path === '/abonnement') {
                return nav({ to: "/dealership/subscription" })
            } else if (path === '/dashboard') {
                return nav({ to: "/dashboard" })
            } else if (path === '/min-konto') {
                return nav({ to: "/dealership/profile" })
            }

        }

        window.addEventListener("message", doNav);
        return () => {
            window.removeEventListener('message', doNav)
        }
    }, []);

    useEffect(() => {
        function waitForLoginToComplete() {
            if (!ref.current || !src) {
                return;
            }
            if (!window.isLoggingIntoDMFNet) {
                ref.current.src = src;
                return;
            } else {
                setTimeout(() => { waitForLoginToComplete() }, 250)
            }
        }
        waitForLoginToComplete()
    }, [])

    return <iframe ref={ref} sandbox="allow-popups allow-downloads allow-scripts allow-same-origin allow-forms" className="w-full flex-1" name="oldFrame" />
}
