import { keepPreviousData, queryOptions, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useSearch } from "@tanstack/react-router";
import { t } from "i18next";
import { archiveUser, getDealer, getDealerStatistics, getUsers, inviteNewUser, updateDealer, updateDealerFromSource, updateUserValues } from "../services/hub";
import { PatchDealer } from "../types/dealer";
import { errorMessage, successMessage } from "../utils/toast";

export const useDealerQuery = () => {
    return useQuery({
        queryKey: ['dealer'],
        queryFn: async () => {
            const data = await getDealer();
            return data.data;
        },
        staleTime: Infinity,
        retry: false,
    })
}

export const usePaginatedUsersQuery = () => {
    const search = useSearch({ from: "/_protected/_dealership/dealership/users" })
    return useQuery({
        queryKey: ['dealerUsers', search.page, search.per_page],
        queryFn: async () => {
            const data = await getUsers(search.page, search.per_page);
            return data.data;
        },
        placeholderData: keepPreviousData,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        staleTime: Infinity,
        retry: false,
        gcTime: 1000 * 60 * 10
    })
}

export const paginatedUsersQueryOptions = () => {
    return queryOptions({
        queryKey: ['dashboardDealerUsers'],
        queryFn: async () => {
            const data = await getUsers(1, 6);
            return data.data;
        },
        staleTime: 0,
        retry: false,
    })
}

export const useInviteNewUser = () => {
    return useMutation({
        mutationFn: inviteNewUser,
    })
}
export const useArchiveUser = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: archiveUser,
        onSuccess() {
            queryClient.invalidateQueries({ queryKey: ['dealerUsers'] })
        },
        onError(error) {
            errorMessage(error);
        },
    })
}

export const useUpdateUserValues = () => {
    const queryClient = useQueryClient()
    const search = useSearch({ strict: false })
    return useMutation({
        mutationFn: async (data: Parameters<typeof updateUserValues>[0]) => await updateUserValues(data),
        onSuccess() {
            queryClient.invalidateQueries({ queryKey: search.page ? ['dealerUsers', search.page] : ['dealerUsers'] })
            queryClient.invalidateQueries({ queryKey: ['dashboardDealerUsers'] })
        },
    })
}



export const useMutateDealerFromSource = () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: async () => {
            const data = await updateDealerFromSource()
            return data.data;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['dealer'] })
        },
        onError: (error) => errorMessage(error)
    })
}


export const useMutateDealerContactData = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: async (dealer: PatchDealer) => {
            const data = await updateDealer(dealer)
            return data.data;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['dealer'] })
            successMessage(t("status:success.contactInfoUpdated.dealer"))
        },
        onError: (error) => errorMessage(error)
    })

}


export const useDealerStatsQuery = () => {
    return useQuery({
        queryKey: ['dealerStats', 30],
        queryFn: async () => {
            const data = await getDealerStatistics(30);
            return data.data;
        },
        placeholderData: keepPreviousData,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        staleTime: 1000 * 60 * 10,
        gcTime: 1000 * 60 * 10
    })
}
