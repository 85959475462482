import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';

type Props = {
    btn: React.ReactNode;
    body: string;
}
export default function Tooltip({ body, btn }: Props) {
    return (
        <Popover className="inline-block leading-[0px]">
            <PopoverButton className="btn-text data-[open]:text-gray-900" title={body}>{btn}</PopoverButton>
            <PopoverPanel
                anchor={{
                    to: "bottom",
                    gap: 12,
                    padding: 14,
                }}
                transition
                className="p-1.5 rounded-md bg-gray-900/15 backdrop-blur text-xs transition duration-200 ease-in-out data-[closed]:-translate-y-3 data-[closed]:opacity-0"
            >
                <div className='max-w-64'>
                    {body}
                </div>
            </PopoverPanel>
        </Popover>
    )
}
