import IFrameOfOldEmbeddedFrontend from "@/features/IFrameOfOldEmbeddedFrontend";
import { createFileRoute, useParams } from "@tanstack/react-router";

export const Route = createFileRoute('/_protected/_leasing/leasing/$id')({
    meta: ({ params }) => [{ title: `LEASING · ${params.id}` }],
    component: LeasingComponentOuter
})

const url = import.meta.env.VITE_OLD_APP_URL + '/leasing-dmf';

function LeasingComponentOuter() {
    const params = useParams({ from: "/_protected/_leasing/leasing/$id" })
    return <IFrameOfOldEmbeddedFrontend src={`${url}/${params.id}`} />
}
