
type Props = React.SVGProps<SVGSVGElement>
export default function TAXIcon(props: Props) {
    return (
        <svg width="32" height="33" {...props} viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor"
                className="fill-opacity-duotone"
                fillOpacity={0.2}
                d="M4 7.5C4 5.84315 5.34315 4.5 7 4.5H25C26.6569 4.5 28 5.84315 28 7.5V12.169C28 12.7128 27.8522 13.2463 27.5725 13.7125L27.4261 13.9565C26.8561 14.9066 26.8561 16.0934 27.4261 17.0435L27.5725 17.2875C27.8522 17.7537 28 18.2872 28 18.831V25.5C28 27.1569 26.6569 28.5 25 28.5H7C5.34315 28.5 4 27.1569 4 25.5V7.5Z"
            />
            <path stroke="currentColor"
                d="M11.6667 14.8333V9.5M9 12.1667H14.3333M9 21.5H14.3333M15.5 4.5H10.4C8.15979 4.5 7.03969 4.5 6.18404 4.93597C5.43139 5.31947 4.81947 5.93139 4.43597 6.68404C4 7.53969 4 8.65979 4 10.9V22.1C4 24.3402 4 25.4603 4.43597 26.316C4.81947 27.0686 5.43139 27.6805 6.18404 28.064C7.03969 28.5 8.15979 28.5 10.4 28.5H21.6C23.8402 28.5 24.9603 28.5 25.816 28.064C26.5686 27.6805 27.1805 27.0686 27.564 26.316C27.783 25.8862 27.892 25.3898 27.9462 24.7183"
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path stroke="currentColor"
                d="M25 15.7699C26.6569 15.7699 28 14.5084 28 12.9524C28 11.3963 26.6569 10.1349 25 10.1349H22M25 15.7699C26.6569 15.7699 28 17.0313 28 18.5873C28 20.1434 26.5555 21.5 24.8987 21.5H22C20.8896 21.5 19.9201 20.9334 19.4013 20.0913M25 15.7699H22C20.3431 15.7699 19 14.5084 19 12.9524C19 11.3963 20.3431 10.1349 22 10.1349M22 10.1349C20.3431 10.1349 19 8.87348 19 7.31743C19 5.76138 20.4445 4.5 22.1013 4.5H24.8987C26.0091 4.5 26.9786 5.06659 27.4973 5.90874"
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round" />
        </svg>

    )
}
