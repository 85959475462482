type Props = React.SVGProps<SVGSVGElement>
export default function HourglassOutlined(props: Props) {
    return (
        <svg version="1.1"
            id="Layer_1" {...props}
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 512 512"
            xmlSpace="preserve"
        >
            <path d="M415.7,427.1c-8.7-76.9-43.8-108.8-69.5-132C328.5,279,320,270.6,320,256c0-14.4,8.5-22.6,26.2-38.4
                c25.9-23.2,61.4-54.9,69.5-132.8c1.4-13.1-2.9-26.3-11.9-36.2C394.4,38,380.8,32,366.4,32H145.6c-14.3,0-28,6-37.4,16.5
                c-9,9.9-13.3,23.2-11.9,36.3c8.1,77.7,43.5,109.2,69.3,132.2c17.8,15.9,26.4,24.2,26.4,39.1c0,15.1-8.6,23.6-26.5,39.7
                c-25.5,23-60.5,54.7-69.2,131.3c-1.5,13,2.8,26.2,11.7,36.2c9.5,10.6,23.2,16.7,37.6,16.7h220.8c14.5,0,28.2-6.1,37.6-16.7
                C412.9,453.4,417.2,440.2,415.7,427.1z M380.2,442c-3.4,3.8-8.4,6-13.8,6H145.6c-5.4,0-10.4-2.2-13.8-6c-1.7-1.9-4.4-5.8-3.7-11.3
                c7.3-64.6,34.7-89.4,58.9-111.3c18.2-16.5,37-33.5,37-63.5c0-29.9-18.9-46.7-37.1-63c-24.3-21.7-51.9-46.3-58.7-111.6
                c-0.4-4.2,0.9-8.3,3.9-11.5c3.4-3.7,8.3-5.9,13.6-5.9h220.8c5.3,0,10.3,2.2,13.7,5.9c2.9,3.3,4.3,7.3,3.8,11.5
                c-6.8,65.6-34.6,90.4-59,112.3c-18.3,16.3-37,33-37,62.4s18.7,46.4,36.7,62.8c24.3,22.1,51.8,47,59.2,112
                C384.5,436.2,381.8,440.1,380.2,442z M352.4,402.9c10,12,6.5,29.1-9.1,29.1H169.1c-15.6,0-20-18-9.1-29.2c26.5-26.8,80-46,80-76.8
                V224c0-19.8-38-35-61.5-67.2c-3.9-5.3-3.5-12.8,6.4-12.8h142.7c8.4,0,10.2,7.4,6.4,12.8C310.8,189,272,204,272,224v102
                C272,356.5,327.7,373,352.4,402.9z"
            />
        </svg>

    )
}
