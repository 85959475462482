import IFrameOfOldEmbeddedFrontend from "@/features/IFrameOfOldEmbeddedFrontend";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute('/_protected/_leasing/leasing/create')({
    meta: () => [{ title: `LEASING · NY` }],
    component: LeasingComponentOuter
})

const url = import.meta.env.VITE_OLD_APP_URL + '/leasing-dmf/ny';

function LeasingComponentOuter() {

    return <IFrameOfOldEmbeddedFrontend src={url} />
}
