import { companyConfig } from "@/config";
import { useTaxCaseStatus } from "@/stores/taxCaseStore";
import { EnumItem } from "@/types/appTypes";
import { formatPhone } from "@/utils/string";
import { getStatusStepsCompleted } from "@/utils/taxCaseStatus";
import DMFPatternBottom from "@assets/dmf-pattern/pattern-bottom-sm.svg";
import DMFPatternTop from "@assets/dmf-pattern/pattern-top-sm.svg";
import { t } from "i18next";


export default function TaxCaseAccepted() {
    const status = useTaxCaseStatus()
    const tel = companyConfig.phone.company
    return (
        <div className='mx-auto sm:px-12 px-4 h-[70vh] rounded-lg sm:py-8 py-6 relative bg-[#111928] overflow-hidden'>
            <div className="absolute top-0 left-0 -translate-x-1/3 -translate-y-1/3 rounded-full blur-3xl opacity-15 z-0 pointer-events-none w-96 h-96 bg-[#00BAF2]"></div>
            <div className="absolute bottom-0 right-0 translate-x-1/2 translate-y-1/2 rounded-full blur-3xl opacity-15 z-0 pointer-events-none w-96 h-96 bg-[#00BAF2]"></div>
            <div className="absolute bottom-1/2 right-1/2 translate-x-1/2 translate-y-1/2 blur-[200px] opacity-5 z-0 pointer-events-none w-full h-full bg-[#00BAF2]"></div>
            <img
                src={DMFPatternTop}
                className="absolute top-0 left-0 pointer-events-none z-0"
                alt=""
                aria-hidden="true"
            />
            <img
                src={DMFPatternBottom}
                className="absolute bottom-0 right-0 pointer-events-none z-0"
                alt=""
                aria-hidden="true"
            />
            <div className='flex flex-wrap h-full text-center'>
                <div className='self-center basis-full'>
                    <p className='text-white uppercase tracking-widest text-[13px] font-semibold mb-4'>
                        {getStatusText(status)}
                    </p>
                    <h2 className="text-white sm:text-3xl text-2xl font-semibold mb-6">
                        {t("content:accept.Thank you very much for your acceptance of this matter - you will hear from us shortly!")}
                    </h2>
                    <p className='text-white text-sm'>{t("content:generic.Need faster expedition? Call us on")}<br /> <a className='link' href={'tel:' + tel} >{formatPhone(tel)}</a></p>
                </div>
                <p className="mt-auto text-text-secondary text-xs font-medium">
                    {t("content:accept.You will immediately receive a payment notification for the agreed amount. While we prepare the final invoice, you have the option to transfer with immediate payment, for even faster processing.")}
                </p>
            </div>
        </div>
    )
}

function getStatusText(status: EnumItem) {
    const foo = getStatusStepsCompleted(status)
    if (foo?.completedState) {
        return t("completed")
    }
    if (foo.purchasedState) {
        return t("paid")
    }
    if (foo.acceptedState) {
        return t("accepted")
    }
    if (foo.assessmentState) {
        return t("preassessed")
    }
    return t("new") + " " + t("case").toLowerCase()
}
