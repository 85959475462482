import { Button } from '@headlessui/react';
import { PlusCircleIcon, TrashIcon } from '@heroicons/react/24/outline';
import { useQueryClient } from '@tanstack/react-query';
import { t } from 'i18next';
import { useState } from 'react';
import ContentLoading from '../../components/ContentLoading';
import { Spinner } from '../../components/Spinner';
import { useBillingDestinationQuery, useDeleteBillingDestination } from '../../hooks/useBillingDestination';
import { BillingDestinationResource } from '../../types/paymentTypes';
import { successMessage } from '../../utils/toast';
import AddNewInvoicingAddress from './AddNewInvoicingAddress';


export default function ManageInvoicingAddresses() {
    const { data, isFetching, status } = useBillingDestinationQuery()
    const [modalOpen, setModalOpen] = useState(false)
    const queryClient = useQueryClient()

    const { mutate, isPending } = useDeleteBillingDestination()


    function onAddNewInvoicingAddress() {
        setModalOpen(true);
    }

    function onDelete(billingDestination: BillingDestinationResource) {
        mutate(billingDestination.id, {
            onSuccess: () => {
                queryClient.invalidateQueries({ queryKey: ['billingDestinations'] })
                successMessage('deleted ' + billingDestination.title)
            }
        })
    }


    if (status === 'pending' || !data) {
        return <ContentLoading />
    }

    return (<>
        <Spinner loading={isFetching || isPending}>
            <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-10">
                <div className="px-4 sm:px-0 md:col-span-4">
                    <h2 className="text-lg font-semibold leading-7 text-gray-900">{t("invoicingAddress")}</h2>
                    <p className="mt-1 text-sm leading-6 text-gray-600">
                        {t("content:dealer.Save invoicing addresses if you need to send registration fee invoicing to another CVR number. For example, if you have several departments with independent CVR numbers.")}
                    </p>
                </div>

                <div className="md:col-span-6 flex items-start justify-end">
                    <Button onClick={onAddNewInvoicingAddress}
                        className="btn btn-primary flex-none">
                        <PlusCircleIcon className='w-5 h-5' aria-hidden="true" />
                        {t("addNewInvoicingAddress")}
                    </Button>

                </div>
            </div>

            <ul className="mt-12 divide-y space-y-6 [&>*]:pt-6 [&>*:first-child]:pt-0">
                {data.data.map(billing => (
                    <li key={billing.id} className='flex justify-between'>
                        <div>
                            <h3 className='font-bold'>{billing.title}</h3>
                            <p className='text-sm mt-1 font-medium'>cvr: {billing.cvr} - {billing.email}</p>
                        </div>
                        <Button className="btn btn-outline danger"
                            onClick={() => onDelete(billing)}
                        >
                            <TrashIcon className='w-5 h-5' aria-hidden="true" />
                            {t("delete")}
                        </Button>
                    </li>
                ))}
            </ul>
        </Spinner>
        <AddNewInvoicingAddress open={modalOpen} setOpen={setModalOpen} />
    </>)
}
