import getFallbackCarImg from "@/utils/getFallbackCarImg";
import { useCallback, useEffect, useState } from "react";

export default function useFallbackCarLogo(carBrand: string | undefined) {
    const [src, setSrc] = useState('')
    const getImgSrc = useCallback(async () => {
        try {
            const module = await getFallbackCarImg(String(carBrand).toLowerCase() as Parameters<typeof getFallbackCarImg>[0]);
            setSrc(module.default)
        } catch (err) {
            setSrc('');
        }
    }, [carBrand])

    useEffect(() => {
        if (carBrand) {
            getImgSrc()
        }
    }, [carBrand])

    return src
}

