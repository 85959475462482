import { Button, DialogTitle, Field, Input, Label, Select } from '@headlessui/react';
import { t } from 'i18next';
import BasicSlideOver from '../../components/BasicSlideOver';
import { Spinner } from '../../components/Spinner';
import { dealerRoles } from '../../enums/enums';
import { useInviteNewUser } from '../../hooks/useDealer';
import { ReactFormSubmit } from '../../types/helperTypes';
import { errorMessage, successMessage } from '../../utils/toast';

type Props = {
    open: boolean;
    setOpen: (open: boolean) => void;
}
export default function InviteNewUser({ open, setOpen }: Props) {
    const { mutate, isPending } = useInviteNewUser()

    async function onSubmit(e: ReactFormSubmit) {
        e.preventDefault()
        const formData = new FormData(e.currentTarget)
        mutate(
            {
                email: formData.get("email")?.toString() || "",
                role: {
                    id: (formData.get("role")?.toString() as ("dealer_sales" | "dealer_admin")) || "dealer_sales"
                }
            },
            {
                onSuccess: () => {
                    setOpen(false)
                    successMessage(t("status:success.activationLinkSentToUsersEmail"))
                },
                onError(error) {
                    errorMessage(error);
                },
            }
        )
    }

    return (
        <BasicSlideOver setOpen={setOpen} open={open}>
            <Spinner className='flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl' loading={isPending}>
                <div className="px-4 sm:px-6">
                    <DialogTitle as='h3' className="text-2xl font-semibold text-gray-800 mb-5">{t("inviteUser")}</DialogTitle>
                    <p>{t("content:dealer.Send activation link to the user you want to invite to your company profile. The user creates his own password upon activation.")}</p>
                </div>
                <form onSubmit={onSubmit}
                    className="relative mt-6 flex-1 px-4 sm:px-6 flex flex-col gap-4"
                >
                    <Field>
                        <Label className="input-label">{t("usersEmailAddress")}</Label>
                        <Input
                            required
                            type='email'
                            autoComplete='email'
                            name="email"
                            className="input mt-1"
                        />
                    </Field>
                    <Field>
                        <Label className="input-label">{t("role")}/{t("permissions")}</Label>
                        <Select required name="role"
                            className={"input mt-1"}
                        >
                            {dealerRoles.map(role => (
                                <option key={role.value} value={role.value}>{role.name}</option>
                            ))}
                        </Select>
                    </Field>
                    <div className='mt-auto pt-6 border-t text-right'>
                        <Button type='submit'
                            className="btn btn-primary"
                        >
                            {t("sendActivationLink")}
                        </Button>
                    </div>
                </form>
            </Spinner>
        </BasicSlideOver>
    )
}
