import IFrameOfOldEmbeddedFrontend from "@/features/IFrameOfOldEmbeddedFrontend";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute('/_protected/_import/import/')({
    meta: () => [{ title: `IMPORT` }],
    component: ImportComponentOuter
})

const url = import.meta.env.VITE_OLD_APP_URL + '/bilimport';

function ImportComponentOuter() {
    return <IFrameOfOldEmbeddedFrontend src={url} />
}
