import { companyConfig } from '@/config';
import { formatPhone } from '@/utils/string';
import StartUpFigure from "@assets/figures/StartUp.png";
import { XCircleIcon } from '@heroicons/react/24/solid';
import { createFileRoute, Link, useSearch } from '@tanstack/react-router';
import { t } from 'i18next';
import { z } from 'zod';

export const Route = createFileRoute('/_protected/purchase')({
    validateSearch: z.object({
        value: z.string().or(z.number()).optional().catch('cancel'),
        additional: z.object({
            type: z.enum(["subscription", "taxRecalculate"]).optional(),
            priceNoTax: z.number().optional(),
            title: z.string().optional()
        }).optional(),
    }),
    beforeLoad: () => ({ title: "Tak for din anmodning" }),
    meta: () => [{ title: "Tak for din anmodning" }],
    component: PurchaseComponent
})


function PurchaseComponent() {
    const search = useSearch({ from: "/_protected/purchase" })

    function renderInner() {
        if (search.value === 'success' && search.additional?.type === 'subscription') {
            return <SubscriptionConfirmationComponent price={search.additional.priceNoTax} title={search.additional.title} />
        }
        if (search.value === 'cancel') {
            return <CancelOrder />
        }
        return <OrderConfirmationComponent />
    }

    const taxMail = companyConfig.emails.tax
    const importMail = companyConfig.emails.import
    const leasingMail = companyConfig.emails.leasing

    return (
        <div className='sm:px-6 sm:pb-6 pb-4 px-4 lg:pt-[108px] pt-20 flex-1 flex flex-col min-h-full'>
            <div className="text-center bg-white mx-auto max-w-3xl w-full sm:px-8 sm:py-6 p-4 rounded-lg shadow">
                {renderInner()}
            </div>
            <div className='flex flex-wrap gap-x-4 gap-y-2 text-xs justify-center py-4'>
                <a className="link whitespace-nowrap max-sm:flex-1" href={`mailto:${taxMail}`}>&#8226; {taxMail}</a>
                <a className="link whitespace-nowrap max-sm:flex-1" href={`mailto:${importMail}`}>&#8226; {importMail}</a>
                <a className="link whitespace-nowrap max-sm:flex-1" href={`mailto:${leasingMail}`}>&#8226; {leasingMail}</a>
            </div>
        </div>
    )
}

function CancelOrder() {
    const phone = companyConfig.phone.company
    const infoMail = companyConfig.emails.info

    return (
        <>
            <h3 className="text-dark-blue text-2xl font-semibold ">
                {t("content:orderConfirmation.Order cancelled")}
            </h3>
            <p className="text-sm mt-6">
                {t("content:orderConfirmation.You have cancelled your order.")}
            </p>
            {/* <img className="inline-block max-w-sm w-full" loading="lazy" src={StartUpFigure} alt="Start up" /> */}
            <XCircleIcon className='size-24 mx-auto my-4 text-red-500' />
            <p className="max-w-72 mx-auto text-xs">
                {t("content:generic.Need faster expedition? Call us on")}
                <a href={`tel:${phone}`} className="link inline-block">{formatPhone(phone)}</a>
                {' '}{t("orOn")}{' '}
                <a className="link" href={`mailto:${infoMail}`}>{infoMail}</a>
            </p>
            <hr className="my-6" />
            <div className="flex flex-wrap justify-center gap-3">
                <Link to="/tax" search={{ page: 1 }} className="btn btn-outline flex-1 whitespace-nowrap">
                    {t("viewTaxCaseArchive")}
                </Link>
                <Link to="/dashboard" className="btn btn-outline flex-1 whitespace-nowrap">
                    {t("dashboard")}
                </Link>
                <Link to="/dealership/subscription" className="btn btn-primary flex-1 whitespace-nowrap">
                    {t("subscription")}
                </Link>
            </div>
        </>
    )
}

function OrderConfirmationComponent() {
    const phone = companyConfig.phone.company
    const infoMail = companyConfig.emails.info
    return (
        <>
            <h3 className="text-dark-blue text-2xl font-semibold ">
                {t("content:orderConfirmation.Thank you very much for your request")}
            </h3>
            <img className="inline-block max-w-sm w-full" loading="lazy" src={StartUpFigure} alt="Start up" />
            <p className="max-w-72 mx-auto text-xs">
                {t("content:generic.Need faster expedition? Call us on")}
                <a href={`tel:${phone}`} className="link inline-block">{formatPhone(phone)}</a>
                {' '}{t("orOn")}{' '}
                <a className="link" href={`mailto:${infoMail}`}>{infoMail}</a>
            </p>
            <hr className="my-6" />
            <div className="flex flex-wrap justify-center gap-3">
                <Link to="/tax/create" className="btn btn-outline flex-1 whitespace-nowrap">
                    {t("createTaxCase")}
                </Link>
                <Link to="/tax" search={{ page: 1 }} className="btn btn-outline flex-1 whitespace-nowrap">
                    {t("viewTaxCaseArchive")}
                </Link>
                <Link to="/dashboard" className="btn btn-primary flex-1 whitespace-nowrap">
                    {t("dashboard")}
                </Link>
            </div>
        </>
    )
}


function SubscriptionConfirmationComponent({ price, title }: {
    title?: string;
    price?: number;
}) {
    const phone = companyConfig.phone.company
    const infoMail = companyConfig.emails.info

    return (
        <>
            <h3 className="text-dark-blue text-2xl font-semibold ">
                {t("content:orderConfirmation.Thank you for your subscription")}
            </h3>
            <div className='mx-auto mt-6 p-4 rounded-sm bg-gray-50 max-w-72 w-full text-center'>
                <p className="text-sm">
                    Du har abonneret til pakken <strong>{title}</strong> til en pris af <small><b>{price} kr/md</b></small>.
                </p>
            </div>
            <img className="inline-block max-w-sm w-full" loading="lazy" src={StartUpFigure} alt="Start up" />
            <p className="max-w-72 mx-auto text-xs">
                {t("content:generic.Need faster expedition? Call us on")}
                <a href={`tel:${phone}`} className="link inline-block">{formatPhone(phone)}</a>
                {' '}{t("orOn")}{' '}
                <a className="link" href={`mailto:${infoMail}`}>{infoMail}</a>
            </p>
            <hr className="my-6" />
            <div className="flex flex-wrap justify-center gap-3">
                <Link to="/tax/create" className="btn btn-outline flex-1 whitespace-nowrap">
                    {t("createTaxCase")}
                </Link>
                <Link to="/dashboard" search={{ page: 1 }} className="btn btn-outline flex-1 whitespace-nowrap">
                    {t("dashboard")}
                </Link>
                <Link to="/dealership/subscription" className="btn btn-primary flex-1 whitespace-nowrap">
                    {t("subscription")}
                </Link>
            </div>
        </>
    )
}
