
type Props = React.SVGProps<SVGSVGElement>
export default function LEASINGIcon(props: Props) {
    return (
        <svg width="32" height="33" viewBox="0 0 32 33" {...props} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M22.6667 3.16675H9.33337C7.12424 3.16675 5.33337 4.95761 5.33337 7.16675V24.5001C5.33337 26.7092 7.12424 28.5001 9.33337 28.5001H14.6667V25.1667L16 23.1667C16.4445 21.8334 17.6 19.1667 18.6667 19.1667H26.6667V7.16675C26.6667 4.95761 24.8758 3.16675 22.6667 3.16675Z"
                fill="currentColor"
                className="fill-opacity-duotone"
                fillOpacity="0.2"
            />
            <path
                d="M26.6667 15.1667V9.56675C26.6667 7.32654 26.6667 6.20643 26.2307 5.35079C25.8472 4.59814 25.2353 3.98622 24.4827 3.60272C23.627 3.16675 22.5069 3.16675 20.2667 3.16675H11.7334C9.49316 3.16675 8.37306 3.16675 7.51741 3.60272C6.76476 3.98622 6.15284 4.59814 5.76935 5.35079C5.33337 6.20643 5.33337 7.32654 5.33337 9.56675V21.8334C5.33337 24.0736 5.33337 25.1937 5.76935 26.0494C6.15284 26.802 6.76476 27.4139 7.51741 27.7974C8.37306 28.2334 8.4265 28.2334 10.6667 28.2334M15.3334 15.1667H10.6667M21.3334 9.83341H10.6667"
                stroke="currentColor"
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.0333 22.6283L16.1333 23.7821L17.0651 20.8499C17.2574 20.2446 17.3536 19.942 17.532 19.7183C17.6895 19.5207 17.8918 19.3678 18.1203 19.2735C18.379 19.1667 18.6831 19.1667 19.2914 19.1667H24.7086C25.3168 19.1667 25.6209 19.1667 25.8797 19.2735C26.1082 19.3678 26.3105 19.5207 26.468 19.7183C26.6463 19.942 26.7425 20.2446 26.9348 20.8499L27.8666 23.7821L28.9666 22.6283M18.1866 23.7821H25.8133C27.0454 23.7821 27.6615 23.7821 28.1321 24.0337C28.546 24.2549 28.8826 24.6079 29.0935 25.0422C29.3333 25.5358 29.3333 26.182 29.3333 27.4744V27.9103C29.3333 28.2678 29.3333 28.4465 29.3051 28.5951C29.1894 29.2054 28.7346 29.6825 28.1528 29.8039C28.0111 29.8334 27.9978 29.8334 27.6571 29.8334C27.6571 29.8334 26.8888 29.8334 26.074 29.8334C25.2592 29.8334 24.4444 28.295 24.4444 28.295H19.5555C19.5555 28.295 18.7407 29.8334 17.9259 29.8334C17.1111 29.8334 16.3428 29.8334 16.3428 29.8334C16.0021 29.8334 15.9888 29.8334 15.8472 29.8039C15.2653 29.6825 14.8105 29.2054 14.6948 28.5951C14.6666 28.4465 14.6666 28.2678 14.6666 27.9103V27.4744C14.6666 26.182 14.6666 25.5358 14.9064 25.0422C15.1173 24.6079 15.4539 24.2549 15.8678 24.0337C16.3385 23.7821 16.9545 23.7821 18.1866 23.7821Z"
                stroke="currentColor"
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round" />
        </svg>

    )
}
