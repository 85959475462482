import { createFileRoute } from '@tanstack/react-router'
import { t } from 'i18next'
import UpdateDealerContactData from '../../../features/dealer/UpdateDealerContactData'
import UpdateDealerLegalData from '../../../features/dealer/UpdateDealerLegalData'


export const Route = createFileRoute('/_protected/_dealership/dealership/')({
    meta: () => [{ title: t("dealer") }],
    component: Dealer
})

function Dealer() {
    return (
        <section>
            <div className="sm:p-8 p-4 border-b">
                <h2 className="text-2xl font-semibold text-dark-blue">
                    {t("yourCompany")}
                </h2>
                <p className="font-medium text-[14px]">{t("content:dealer.Enter your information on your company. If you wish to change the CVR number, contact support")}</p>
            </div>
            <div className="divide-y sm:px-8 px-4">
                <div className="sm:py-8 py-6">
                    <UpdateDealerLegalData />
                </div>
                <div className="sm:py-8 py-6">
                    <UpdateDealerContactData />
                </div>

                {/* 2024-08-07
                    Turn off this feature until we know if we want to keep it.
                    The idea was to make the application a 'white-label solution' for dealers.
                    Now the dealer doesn't need the ability to upload a logo, as we will be using the DMF logo instead.
                */}
                {/* <div className="sm:py-8 py-6">
                    <UploadDealerLogo />
                </div> */}
            </div>
        </section>
    )
}

