import HourglassOutlined from "@/assets/icons/HoursglassOutlined";
import { t } from "i18next";

export default function InvoiceGenerating() {
    return (
        <div className="text-center">
            <span className="rounded-full size-16 flex items-center justify-center mx-auto bg-primary mb-4">
                <HourglassOutlined className="size-10 fill-white" />
            </span>
            <h3 className="text-lg text-dark-blue font-medium">
                {t("content:tax.status.Invoice is generating")}!
            </h3>
            <p className="text-sm mt-1">
                {t("content:tax.status.Generation of invoice has begun, which You will receive by email. Please wait a few minutes")}
            </p>
        </div>
    )
}
