import { EnumItem } from '../types/appTypes';
import enumsJson from './enums.json';
const enums = enumsJson.data
export default enums;


export const checkIsAdmin = (roleValue: EnumItem['value']) => {
    const adminEnumValues = ['dealer_admin', 'super_admin', 'dmf_admin'];
    return adminEnumValues.includes(roleValue);
}
export const statuses = enums.general.status;
export const roles = enums.general.roles;
export const useCases = enums.vehicle.useCase;
export const dealerRoles: EnumItem[] = roles.filter(role => role.value === 'dealer_admin' || role.value === 'dealer_sales');
export const getExpiredStatus = () => enums.general.status.find(item => item.value === '2');
export const specialUseCases = enums.vehicle.useCase;
export const taxCaseEvaluationTypes = enums.taxCase.evaluationType;
