import LoadingDMFSvg from "@assets/images/loading.svg"
export default function ContentLoading() {

    return (
        <div className='h-48 w-full flex flex-col items-center justify-center'>
            <img src={LoadingDMFSvg} alt="" className="w-10" width={40} height={50} />
            <span>Vi er på sagen!</span>
        </div>
    )
}
