import Pattern from '@assets/dmf-pattern/dmf-pattern-2.png';
import { ReactNode } from "react";

type Props = {
    children: ReactNode
}
export function UnAuthenticatedLayout({ children }: Props) {
    return (

        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 overflow-hidden relative signup-bg ">

            <div className='z-10'>
                {children}
            </div>

            <div className='bg-primary-active/20 -rotate-45 rounded-full' style={{
                position: "absolute",
                left: "-300px",
                top: "-100px",
                width: "900px",
                height: "1250px",
                filter: "blur(100px)",
            }}></div>
            <div className="absolute bottom-0 right-0 overflow-hidden -z-10 mix-blend-overlay opacity-30 max-md:hidden">
                <img
                    src={Pattern}
                    width={1553}
                    height={1080}
                    className="w-full"
                />
            </div>
        </div>
    )
}
