import { SubscriptionPackageResource } from "@/types/appTypes";
import axios from "../utils/axios";

const API_BASE_URL = import.meta.env.VITE_API_URL;
const API_PATH = import.meta.env.VITE_API_PATH;
const API_URL = API_BASE_URL + API_PATH;



type StripeSessionId = {
    data: {
        code: string,
        email: string;
    }
}

/**
 * Get the Stripe Session ID that can be used in the pricing table
 * GET http://localhost:8007/api/v2/hub/paymentPortal/sessionId
 * Limits itself to the dealership that the authenticated user belongs to.
 */
export const getStripeSessionId = () => {
    return axios.get<StripeSessionId>(`${API_URL}/hub/paymentPortal/sessionId`);
}

/**
 * Get the external customer portal url, and optionally target a specific flow
 * GET http://localhost:8007/api/v2/hub/paymentPortal/externalUrl/{actionType}
 * This operation is restricted to the dealership associated with the authenticated user.
 * actionType - string or null - required
    Optional. Possible options are swap_<PACKAGE_CODE>, payment_method_update, subscription_cancel, subscription_update, customer_portal
    Default: null
 */
export const getStripeCustomerPortalUrl = (actionType?: "payment_method_update" | "subscription_cancel" | "subscription_update" | "customer_portal" | string) => {
    return axios.get<{ data: { result: string } }>(`${API_URL}/hub/paymentPortal/externalUrl${actionType ? "/" + actionType : ""}`);
}


/**
 * List possible subscription packages available
 * GET http://localhost:8007/api/v2/hub/paymentPortal/packages
 */
export const getSubscriptionPortalPackages = () => {
    return axios.get<{ data: SubscriptionPackageResource[] }>(`${API_URL}/hub/paymentPortal/packages`);
}
