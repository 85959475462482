import { createFileRoute } from '@tanstack/react-router'
import { t } from 'i18next'
import { z } from 'zod'
import ViewPaymentHistory from '../../../features/dealer/ViewPaymentHistory'

const paymentHistorySchema = z.object({
    year: z.number().catch(new Date().getFullYear()),
    month: z.number().catch(new Date().getMonth() + 1),
})

export const Route = createFileRoute('/_protected/_dealership/dealership/payment-history')({
    meta: () => [{ title: t("paymentHistory") }],
    validateSearch: paymentHistorySchema,
    component: PaymentHistory
})


function PaymentHistory() {

    return (
        <section>
            <ViewPaymentHistory />
        </section>
    )
}
