import StatusTag from "@/components/StatusTag"
import { TaxCaseResource } from "@/types/taxTypes"
import { printMakeModelOrLink } from "@/utils/printHelpers"
import { formatDate, formatDateWithElapsedTime, formatNumber, formatPrice } from "@/utils/string"
import { Link, useSearch } from "@tanstack/react-router"
import clsx from "clsx"
import { t } from "i18next"

type Props = {
    taxCases: TaxCaseResource[] | null;
    selectedTaxCase: TaxCaseResource | null;
    setSelectedTaxCase: (taxCase: TaxCaseResource) => void;
    openDrawer: () => void;
}
export default function TaxCaseTable({ taxCases, selectedTaxCase, setSelectedTaxCase, openDrawer }: Props) {
    const search = useSearch({ from: "/_protected/_tax/tax/" })
    function onVehicleItemClick(taxCase: TaxCaseResource) {
        setSelectedTaxCase(taxCase)
        openDrawer()
    }

    return (
        <div className="min-w-0 overflow-x-auto border-b">
            <table className="tax-dashboard-table min-w-full divide-gray-300">
                <thead className="bg-gray-100 z-10">
                    <tr>
                        <th scope="col" className="dmf-table-cell first">
                            {t("caseNumber_short")}
                        </th>
                        <th scope="col" className="dmf-table-cell hidden sm:table-cell">
                            {t("date")}
                        </th>
                        <th scope="col" className="dmf-table-cell hidden sm:table-cell">
                            {t("creator")}
                        </th>
                        <th scope="col" className="dmf-table-cell hidden sm:table-cell">
                            {t("vehicle")}
                        </th>
                        <th scope="col" className="dmf-table-cell">
                            {t("vin")}
                        </th>
                        <th scope="col" className="dmf-table-cell ">
                            {t("firstRegDate", { context: "short" })}
                        </th>
                        <th scope="col" className="dmf-table-cell hidden md:table-cell">
                            {t("mileage")}
                        </th>
                        <th scope="col" className="dmf-table-cell hidden md:table-cell">
                            {t("status")}
                        </th>
                        <th scope="col" className="dmf-table-cell text-right pr-4">
                            {t("registrationFee_short")}
                        </th>
                    </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white w-full">
                    {taxCases?.map((taxCase) => (
                        <tr className={clsx(taxCase.id === selectedTaxCase?.id && "dmf-cell-focused")} key={taxCase.id} onClick={() => onVehicleItemClick(taxCase)}>
                            <td className="dmf-table-cell first max-sm:text-xs">
                                <Link to={"/tax/$id"}
                                    params={{ id: taxCase.id }}
                                    onClick={(e) => e.stopPropagation()}
                                    // @ts-expect-error
                                    state={{ search: search }}
                                    className="link"
                                >
                                    {taxCase.id}
                                </Link>
                            </td>
                            <td title={formatDateWithElapsedTime(taxCase.createdAt)} className="dmf-table-cell hidden md:table-cell">
                                {formatDateWithElapsedTime(taxCase.createdAt)}
                            </td>
                            <td title={taxCase.client?.name} className="dmf-table-cell hidden md:table-cell">
                                {taxCase.client?.name}
                            </td>
                            <td title={printMakeModelOrLink(taxCase.vehicle?.brand, taxCase.vehicle?.model, taxCase.vehicle?.link, 'link-as-text') as string} className="dmf-table-cell max-w-64 truncate hidden sm:table-cell">
                                {printMakeModelOrLink(taxCase.vehicle?.brand, taxCase.vehicle?.model, taxCase.vehicle?.link)}
                            </td>
                            <td title={taxCase.vehicle?.vin || ""} className="dmf-table-cell max-sm:text-xs md:max-w-64 max-w-40 truncate">
                                {taxCase.vehicle?.vin}
                            </td>
                            <td title={formatDate(taxCase.vehicle?.firstRegDate)} className="dmf-table-cell max-sm:text-xs">
                                {formatDate(taxCase.vehicle?.firstRegDate)}
                            </td>
                            <td title={formatNumber(taxCase.vehicle?.mileage)} className="dmf-table-cell hidden md:table-cell">
                                {formatNumber(taxCase.vehicle?.mileage)} km
                            </td>
                            <td title={taxCase.status.name} className="dmf-table-cell hidden md:table-cell">
                                <StatusTag {...taxCase.status} />
                            </td>
                            <td title={formatPrice(taxCase.assessment?.estimatedRegistrationFee, true)} className="dmf-table-cell pl-3 pr-4 text-right font-medium text-dark-blue">
                                {formatPrice(taxCase.assessment?.estimatedRegistrationFee, true)}
                            </td>
                        </tr>
                    ))}
                </tbody>

            </table>
        </div>

    )
}
