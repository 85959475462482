import IFrameOfOldEmbeddedFrontend from "@/features/IFrameOfOldEmbeddedFrontend";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute('/_protected/_leasing/leasing/')({
    beforeLoad: () => ({ title: "LEASING" }),
    meta: () => [{ title: "LEASING" }],
    component: LeasingComponentOuter
})

const url = import.meta.env.VITE_OLD_APP_URL + '/leasing-dmf';

function LeasingComponentOuter() {
    return <IFrameOfOldEmbeddedFrontend src={url} />
}
