import { EnumItem } from "@/types/appTypes";
import { getStatusColorClassName } from "@/utils/string";

export default function StatusTag({ name, value }: EnumItem) {
    const colorClass = getStatusColorClassName(value);
    return (
        <span className={`rounded-[3px] inline-block px-2.5 py-0.5 whitespace-nowrap font-medium text-[12px] leading-5 ${colorClass}`}>
            {name}
        </span>
    )
}
