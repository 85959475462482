import DMFLogo from "@assets/DMF-Logo/DMFPRO/Horizontal/dmf-white3x.png";
import { Button, Description, Field, Fieldset, Input, Label } from '@headlessui/react';
import { ArrowLongLeftIcon, CheckCircleIcon } from '@heroicons/react/24/outline';
import { useMutation } from '@tanstack/react-query';
import {
    createFileRoute,
    useRouter
} from '@tanstack/react-router';
import { t } from 'i18next';
import * as React from 'react';
import { z } from 'zod';
import { UnAuthenticatedLayout } from '../../components/UnauthenticatedLayout';
import { initializeDealerRegistration } from '../../services/auth';
import { errorMessage, successMessage } from '../../utils/toast';
import { raise } from '../../utils/utils';

export const Route = createFileRoute('/_auth/sign-up/')({
    validateSearch: z.object({
        redirect: z.string().optional().catch(''),
    }),
    meta: () => [{ title: t("createAccount") }],
    component: Signup,
})

function Signup() {

    const { mutate, isPending, status } = useMutation({
        mutationFn: async (email: string) => {
            return initializeDealerRegistration(email)
        },
        onSuccess: () => {
            successMessage(t("status:success.activationLinkSent"))
        },
        onError: (error) => {
            errorMessage(error)
        }
    })

    const router = useRouter()

    const onFormSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
        try {
            evt.preventDefault()
            const data = new FormData(evt.currentTarget)
            const email = data.get("email")?.toString() || raise(t("status:error.emailRequired"));
            mutate(email)
        } catch (error) {
            errorMessage(error)
        }

    }
    const goBack = () => router.history.back();

    return (

        <UnAuthenticatedLayout>
            <img
                className="mx-auto w-64 z-10 relative mb-20"
                src={DMFLogo}
                alt="DMF Pro"
            />

            <div className="mt-20 sm:mx-auto sm:w-full sm:max-w-md bg-white sm:px-12 sm:py-8 p-4 rounded-sm ">
                <h2 className="mb-10 text-center text-2xl font-bold leading-9 tracking-tight text-dark-blue-hover">
                    {t("create")} {t("account").toLowerCase()}
                </h2>
                <Button className="-ml-4 mb-3 link btn" onClick={goBack}>
                    <ArrowLongLeftIcon className='size-5' aria-hidden="true" />
                    {t("back")}
                </Button>
                {status === 'success' ? (
                    <div className="rounded-md bg-green-100 p-4 flex">
                        <CheckCircleIcon className="h-5 w-5 text-green-500" aria-hidden="true" />
                        <div className="ml-3">
                            <h2 className="text-green-900 font-semibold mb-1">{t("status:success.emailSent")}</h2>
                            <p className="text-sm font-medium text-green-800">{t("content:login.Check your email and open to the link we sent to continue")}</p>
                        </div>
                    </div>
                ) : (
                    <form className="space-y-6" onSubmit={onFormSubmit}>
                        <Fieldset disabled={isPending} className="w-full grid gap-4">
                            <Field>
                                <Description className="text-sm mb-2">
                                    {t("content:Enter_your_email_we'll_send_you_a_link_to_create_your_account")}
                                </Description>
                                <Label className="input-label">
                                    {t("email")}
                                </Label>
                                <Input
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    required
                                    className="input mt-2"
                                />
                                <Description className="text-xs mt-1 mb-3">
                                    {t("content:Note_You_have_to_use_the_email_you_want_to_create_your_user_account_under.")}
                                </Description>
                            </Field>

                            <Button
                                type="submit"
                                className="btn btn-primary justify-center"
                            >
                                {isPending ? t('loading') : t("sendActivationLink")}
                            </Button>
                        </Fieldset>
                    </form>
                )}

            </div>
        </UnAuthenticatedLayout>
    )
}
