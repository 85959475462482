import BasicSlideOver from '@/components/BasicSlideOver'
import { subscriptionConfig } from '@/config'
import { useSubscriptionPackages } from '@/hooks/useStripe'
import { contactSupport } from '@/services/hub'
import { getStripeCustomerPortalUrl } from '@/services/stripe'
import { SubscriptionPackageResource } from '@/types/appTypes'
import { ReactFormSubmit } from '@/types/helperTypes'
import { formatNumber } from '@/utils/string'
import { errorMessage, successMessage } from '@/utils/toast'
import { Button, Field, Label, Textarea } from '@headlessui/react'
import { CheckBadgeIcon } from '@heroicons/react/24/outline'
import { useMutation } from '@tanstack/react-query'
import { Link, createFileRoute, useLoaderData } from '@tanstack/react-router'
import clsx from 'clsx'
import { t } from 'i18next'
import { useState } from 'react'
import { Spinner } from '../../../components/Spinner'

export const Route = createFileRoute('/_protected/_dealership/dealership/subscription')({
    meta: () => [{ title: t("subscription") }],
    component: Subscription
})

function Subscription() {
    const { data: packages, isPending } = useSubscriptionPackages()
    const [enterpriseFormOpen, setEnterpriseFormOpen] = useState(false);
    const { user } = useLoaderData({ from: "/_protected" })
    const mutateContact = useMutation({
        mutationFn: (args: Parameters<typeof contactSupport>) => contactSupport(...args),
        onSuccess() {
            successMessage(t("messageSent"))
            setEnterpriseFormOpen(false);
        },
        onError(error) {
            errorMessage(error)
        },
    })
    function onSubmitEnterprise(e: ReactFormSubmit) {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        mutateContact.mutate([{
            message: data.get("message")?.toString() || "",
            type: "general"
        }])
    }
    return (
        <>
            <Spinner loading={isPending}>
                <section>
                    <div className="xl:p-8 p-8 md:flex md:items-center md:justify-between border-b">
                        <div className="min-w-0 flex-1">
                            <h2 className="text-2xl font-semibold text-dark-blue-hover sm:truncate">
                                {t("administerSubscription")}
                            </h2>
                            <p className="mt-1 text-[14px]">
                                {t("currentSubscription")}: <span className="font-medium">{user.subscription.details?.package?.title ?? subscriptionConfig.starterSubscription.title}</span>
                            </p>
                        </div>
                        <div className="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0">
                            <Link className='text-primary hover:text-primary-hover active:text-primary-active'
                                to='/redirect-to-customer-portal'
                            >
                                {t("goTo")} {t("subscription")}
                            </Link>
                        </div>
                    </div>


                    {packages && (
                        <div className={clsx(
                            "2xl:p-6 p-4 grid-cols-5 gap-4 2xl:grid flex flex-nowrap sm:[&>*]:basis-80 scroll-pl-4 [&>*]:flex-none",
                            "max-sm:[&>*]:basis-5/6 max-md:no-scrollbar overflow-x-auto snap-x snap-mandatory [&>*]:snap-always [&>*]:snap-start"
                        )}>

                            {packages.sort((a, b) => a.level - b.level).map((subscriptionPackage, i) => (
                                <Package key={i} {...subscriptionPackage} openEnterpriseForm={() => setEnterpriseFormOpen(true)} />
                            ))}
                        </div>
                    )}
                </section>
            </Spinner>
            <BasicSlideOver open={enterpriseFormOpen} setOpen={setEnterpriseFormOpen} size='sm'>
                <Spinner loading={mutateContact.isPending} className='h-full w-full sm:p-6 p-4 bg-white'>
                    <h2 className='text-xl font-semibold text-dark-blue mb-4'>{t("contactFormForEnterprise")}</h2>
                    <p className='text-sm mb-2'>
                        {t("content:support.If you need more services, or need a tailored subscription package, you can contact us with your wishes. Describe your needs and wishes below, and we will contact you as soon as possible.")}
                    </p>
                    <form onSubmit={onSubmitEnterprise}>
                        <Field>
                            <Label className={"input-label"}>{t("message")}</Label>
                            <Textarea name='message'
                                placeholder={t("describeYourRequirements")}
                                required
                                className="input min-h-24 mt-1"
                            />
                        </Field>
                        <Button type='submit' className="btn btn-primary w-full mt-4">{t("contactUs")}</Button>
                    </form>
                </Spinner>
            </BasicSlideOver>
        </>
    )
}




function Package({ description, features, id, priceNoVat, productCode, recommended, title, openEnterpriseForm }: SubscriptionPackageResource & { openEnterpriseForm: () => void }) {
    const { user } = useLoaderData({ from: "/_protected" })
    const isCurrentSubscription = (user.subscription.details?.package?.id || null) === id;
    const [loading, setLoading] = useState(false)
    async function getLinkToStripe() {
        try {
            setLoading(true)
            const { data } = await getStripeCustomerPortalUrl("swap_" + productCode)
            if (data.data.result) {
                window.open(data.data.result, "_self")
            }
        } catch (err) {
            errorMessage(err)
        } finally {
            setLoading(false)
        }
    }

    function handleClick() {
        if (productCode === 'enterprise') {
            openEnterpriseForm()
        } else {
            getLinkToStripe()
        }
    }

    return (
        <Spinner loading={loading}
            className={clsx(recommended ? "bg-dark-blue" : 'bg-gray-100', 'p-4 pt-6 rounded-sm flex flex-col relative')}
        >
            {recommended && (
                <span className='absolute top-0 -translate-y-1/2 bg-primary text-white font-semibold rounded-full leading-8 px-4 text-[12px] self-center'>Vi anbefaler</span>
            )}
            <h3 className={clsx(recommended ? "text-white" : 'text-dark-blue', 'font-semibold text-lg')}>{title}</h3>
            <p className={clsx(recommended ? "text-white/85" : 'text-text-secondary', 'text-sm h-16')}>{description}</p>
            <p className={clsx(recommended ? "text-white" : 'text-dark-blue', 'text-3xl font-semibold mt-4')}>
                {printPrice({ priceNoVat, productCode })}
            </p>
            <p className={clsx(recommended ? "text-white/85" : 'text-text-secondary', ' text-sm mb-8')}>/Måned + Moms</p>
            <ul className={clsx(recommended ? "text-white" : 'text-text-primary', ' text-sm mb-4 space-y-1')}>
                {features.map((feat, i) => (
                    <li key={i} className='flex gap-1'>
                        <CheckBadgeIcon className='size-5 text-[#52c41a] flex-none' />
                        <PrintMarkdown text={feat} />
                    </li>
                ))}
            </ul>
            <Button disabled={isCurrentSubscription}
                onClick={handleClick}
                className={clsx("btn btn-primary mt-auto w-full", recommended && "data-[disabled]:text-primary data-[disabled]:bg-white data-[disabled]:opacity-30")}
            >
                {isCurrentSubscription ? t("yourSubscription") : `${t("changeTo")} ${title}`}
            </Button>
        </Spinner>
    )
}

function PrintMarkdown({ text }: { text: string }) {
    const boldPattern = /\*\*(.+?)\*\*(?!\*)/g;
    const italicPattern = /\*([^*><]+)\*/g;

    let html = text.replace(boldPattern, (w) => {
        const insideText = w.replace(/\*/g, "");
        return "<b>" + insideText + "</b>";
    })
    html = html.replace(italicPattern, (w) => {
        const insideText = w.replace(/\*/g, "");
        return "<em>" + insideText + "</em>";
    })
    return <span dangerouslySetInnerHTML={{ __html: html }}></span>
}

function printPrice({ priceNoVat, productCode }: Pick<SubscriptionPackageResource, "priceNoVat" | "productCode">) {
    if (productCode === 'free') {
        return t("free_price");
    }
    if (productCode === 'enterprise') {
        return t("contactUs")
    }
    return `Kr. ${formatNumber(priceNoVat)},-`
}
