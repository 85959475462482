import HourglassOutlined from "@/assets/icons/HoursglassOutlined";
import { t } from "i18next";

export default function CaseAccepted() {
    return (
        <div className="text-center">
            <span className="rounded-full size-16 flex items-center justify-center mx-auto bg-primary mb-4">
                <HourglassOutlined className="size-10 fill-white" />
            </span>
            <h3 className="text-lg text-dark-blue font-medium">
                {t("content:Thank_you_for_accepting_the_case")}
            </h3>
            <p className="text-sm mt-1">
                {t("content:We_have_sent_you_a_payment_notification_via_email._As_soon_as_you_have_paid,_we_can_register_the_vehicle,_and_you_will_receive_your_receipt_for_registration.")}
            </p>
            <p className="text-sm mt-1">
                {t("content:You_will_receive_a_detailed_invoice_via_email_in_approximately_20_minutes.")}
            </p>
        </div>
    )
}
