import EquipmentIcon from '@/assets/icons/CarDataIcon';
import IMPORTIcon from '@/assets/icons/IMPORTIcon';
import TAXIcon from '@/assets/icons/TAXIcon';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { PlusIcon } from '@heroicons/react/24/outline';
import { Link, useLoaderData } from '@tanstack/react-router';
import clsx from 'clsx';

export default function FloatingShortcutButtons() {
    const { user } = useLoaderData({ from: "/_protected" })
    return (
        <div className="fixed bottom-4 right-4">
            <Menu>
                {({ open }) => (
                    <>
                        <MenuButton className="btn btn-primary size-14 p-0 rounded-full shadow-lg shadow-black/30 z-10">
                            <PlusIcon className={clsx("size-8 origin-center transition-transform", open && "rotate-45")} aria-hidden="true" />
                        </MenuButton>

                        <MenuItems
                            transition
                            modal={false}
                            anchor="bottom"
                            className="origin-top-right flex flex-col items-end gap-2 px-2 py-4 transition ease-out focus:outline-none data-[closed]:translate-y-6 data-[closed]:opacity-0"
                        >
                            <MenuItem>
                                <Link className='btn btn-primary h-12 whitespace-nowrap rounded-full shadow-lg shadow-black/30 uppercase font-serif' to="/tax/create">
                                    <span>TAX</span>
                                    <TAXIcon className='scale-90' aria-hidden="true" />
                                </Link>
                            </MenuItem>
                            {user.products.import && (
                                <MenuItem>
                                    <Link className='btn btn-primary h-12 whitespace-nowrap rounded-full shadow-lg shadow-black/30 uppercase font-serif' to="/import/create">
                                        <span>IMPORT</span>
                                        <IMPORTIcon aria-hidden="true" />
                                    </Link>
                                </MenuItem>
                            )}
                            {user.products.vehicleReport && (
                                <MenuItem>
                                    <Link className='btn btn-primary h-12 whitespace-nowrap rounded-full shadow-lg shadow-black/30 uppercase font-serif' to="/equipment/create">
                                        <span>UDSTYR</span>
                                        <EquipmentIcon aria-hidden="true" />
                                    </Link>
                                </MenuItem>
                            )}
                        </MenuItems>
                    </>
                )}

            </Menu>
        </div>

    )
}
