import { Dialog, DialogPanel, TransitionChild } from "@headlessui/react";
import { useLoaderData } from "@tanstack/react-router";
import { useEffect, useState } from "react";

const baseSrc = import.meta.env.VITE_SURVEY_URI;
const surveyKey = 'ms_survey_20241007';

export default function MSSurveyForm() {
    const { user } = useLoaderData({ from: "/_protected" })
    const storageValue = window.localStorage ? Number(window.localStorage.getItem(surveyKey)) : null;
    const [open, setOpen] = useState<boolean>(storageValue === 0);

    let src = baseSrc ?? '';
    src = src.replaceAll('__EMAIL__', encodeURIComponent(user.email))
    src = src.replaceAll('__NAME__', encodeURIComponent(user.name))

    useEffect(() => {
        window.localStorage?.setItem(surveyKey, "1")
    }, [])

    const onClose = () => {
        window.localStorage?.setItem(surveyKey, "1")
        setOpen(false);
    }

    return src !== '' ? (
        <>
            <Dialog className="relative z-50" open={open} onClose={onClose}>
                <TransitionChild>
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity duration-300 data-[closed]:opacity-0" />
                </TransitionChild>
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                        <TransitionChild>
                            <DialogPanel className="relative overflow-hidden rounded-lg bg-white p-4 text-left shadow-xl sm:my-8 sm:w-full sm:max-w-2xl sm:p-12 transition transform-gpu duration-300 data-[closed]:opacity-0 data-[closed]:translate-y-4 data-[closed]:scale-90">
                                <iframe
                                    width="640px"
                                    height="680px"
                                    src={src}
                                    frameBorder="0"
                                    marginWidth={0}
                                    marginHeight={0}
                                    style={{ "border": "none", "maxWidth": "100%", "maxHeight": "100vh" }}
                                    allowFullScreen={true}
                                    // @ts-ignore
                                    webkitallowfullscreen="webkitallowfullscreen"
                                    mozallowfullscreen="mozallowfullscreen"
                                    msallowfullscreen="msallowfullscreen">
                                </iframe>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </Dialog>
        </>
    ) : null
}