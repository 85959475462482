import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { t } from "i18next";
import { getProfile, patchProfile } from "../services/hub";
import { PatchProfile } from "../types/auth";
import { errorMessage, successMessage } from "../utils/toast";

export const useProfile = () => {
    return useQuery({
        queryKey: ['profile'],
        queryFn: async () => {
            const data = await getProfile();
            return data.data;
        },
        retry: false,
        staleTime: 1000 * 5
    })
}

export const useMutateProfile = () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: async (profile: PatchProfile) => {
            const data = await patchProfile(profile)
            return data.data;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['profile'] })
            queryClient.invalidateQueries({ queryKey: ['dealerUsers'] })
            queryClient.invalidateQueries({ queryKey: ['users'] })
            queryClient.invalidateQueries({ queryKey: ['dashboardDealerUsers'] })
            queryClient.invalidateQueries({ queryKey: ['dealer'] })
            successMessage(t("status:success.contactInfoUpdated.profile"))
        },
        onError: (error) => errorMessage(error)
    })
}
