import { Select } from "@headlessui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { Link, ReactNode, useNavigate, useSearch } from "@tanstack/react-router";
import { t } from "i18next";
import { ChangeEvent } from "react";
import { PaginatedData } from "../types/appTypes";

function grapPageNumber(link: string | null) {
    if (link) {
        const url = new URL(link)
        const page = url.searchParams.get('page');
        return page ? Number(page) : 1;
    }
    return 1;
}

type PagniationButtonsProps = {
    pagination: PaginatedData<unknown>['meta'];
}
export default function PaginatedDataPaginationButtons({ pagination }: PagniationButtonsProps) {
    const navigate = useNavigate()
    const perPage = useSearch({
        strict: false,
        select: (search) => search.per_page,
    })
    function onPerSizeChange(e: ChangeEvent<HTMLSelectElement>) {
        navigate({
            to: ".",
            search: (s) => ({ ...s, per_page: Number(e.target.value) })
        })
    }
    return (
        <div className="flex xl:flex-row md:flex-col flex-row justify-between items-center gap-4">
            <p className='text-sm'>
                <span className='max-sm:hidden'>{t("showing")} {' '}</span>
                <span className='sm:font-medium'>{pagination.from}</span> {' '}

                <span className='max-sm:hidden'>{t("to")} {' '}</span>
                <span className='sm:hidden'>-</span>

                <span className='sm:font-medium'>{pagination.to}</span> {' '}

                <span className='max-sm:hidden'>{t("of")} {' '}</span>
                <span className='sm:hidden'>/ {' '}</span>

                <span className='sm:font-medium'>{pagination.total}</span> {' '}

                <span className='max-sm:hidden'>{t("results").toLowerCase()} {' '}</span>
            </p>

            <div className="flex gap-4 flex-wrap justify-center">
                <div className='flex'>
                    {pagination.links.map((link, i) => {
                        let label: ReactNode = link.label;
                        let classes = "pagination-btn";

                        if (i === 0) {
                            classes += " first-btn"
                            label = <ChevronLeftIcon className='w-4 h-4' aria-label='Prev' />

                        } else if (i === pagination.links.length - 1) {
                            classes += " last-btn"
                            label = <ChevronRightIcon className='w-4 h-4' aria-label='Next' />
                        } else {
                            classes += " max-md:hidden"
                        }

                        if (link.active) {
                            return (
                                <span className={`${classes} pagination-active`} key={i}>
                                    {label}
                                </span>
                            )
                        }

                        if (!link.url) {
                            return (
                                <span className={`${classes} no-link`} key={i}>
                                    {label}
                                </span>
                            )
                        }

                        return (
                            <Link key={i}
                                search={(search) => ({ ...search, page: grapPageNumber(link.url) })}
                                to={"."}
                                className={classes}
                            >
                                {label}
                            </Link>

                        )
                    })}
                </div>
                <Select className="rounded-md appearance-none cursor-pointer border-0 ring-1 ring-blue-100 py-0 h-8 pl-3 pr-8 focus:ring-blue-400 text-sm leading-6"
                    value={Number(perPage) || 20}
                    onChange={onPerSizeChange}
                >
                    <option value={10}>10 / side</option>
                    <option value={20}>20 / side</option>
                    <option value={50}>50 / side</option>
                    <option value={100}>100 / side</option>
                </Select>

            </div>
        </div>

    )
}


