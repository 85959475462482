import { Button, Field, Input, Label, Select } from '@headlessui/react';
import clsx from 'clsx';
import { t } from 'i18next';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import Avatar from '../../components/Avatar';
import ContentLoading from '../../components/ContentLoading';
import { Spinner } from '../../components/Spinner';
import { dealerRoles } from '../../enums/enums';
import { useMutateProfile, useProfile } from '../../hooks/useProfile';
import { ReactFormSubmit } from '../../types/helperTypes';
import { MAX_UPLOAD_SIZE_AVATAR, convertBytes, fileToBase64 } from '../../utils/file';
import { errorMessage } from '../../utils/toast';
import { raise } from '../../utils/utils';

export default function UpdateUserProfile() {
    const { data: profile } = useProfile()

    const { mutate: updateProfile, isPending: mutationPending } = useMutateProfile()
    const fileInputRef = useRef<HTMLInputElement>(null)
    const [src, setSrc] = useState(profile?.data.avatar?.uri)


    useEffect(() => { setSrc(profile?.data.avatar?.uri) }, [profile])

    if (!profile) {
        return <ContentLoading />
    }

    const previewAvatar = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.currentTarget.files) {
            const [file] = e.currentTarget.files;
            const previewSrc = URL.createObjectURL(file)
            setSrc(previewSrc);
        }
    }

    function deleteProfilePicture() {
        setSrc(undefined)
        updateProfile({
            avatar: {
                uri: null
            }
        })
    }

    const user = profile.data;

    async function onSubmit(e: ReactFormSubmit) {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        const args: Parameters<typeof updateProfile>[0] = {
            initials: data.get('initials')?.toString() || user.initials,
            phone: data.get('phone')?.toString() || user.phone,
            name: data.get('name')?.toString() || user.name,
        }

        const file = data.get('avatar') as File;
        if (file.size) {
            try {
                if (convertBytes(file.size, "mb") > MAX_UPLOAD_SIZE_AVATAR) {
                    raise(t("status:error.fileSize", { actualFilseSize: convertBytes(file.size, 'mb').toFixed(1), maxFileSize: MAX_UPLOAD_SIZE_AVATAR }));
                }
                args.avatar = { uri: await fileToBase64(file) }
            } catch (err) {
                errorMessage(err)
                setSrc(user.avatar?.uri)
            }
        }
        updateProfile(args)
    }


    return (

        <Spinner loading={mutationPending}>
            <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-10">
                <div className="px-4 sm:px-0 md:col-span-4">
                    <h2 className="text-lg font-semibold leading-7 text-gray-700">{t("yourInformation")}</h2>
                    <p className="mt-1 text-sm leading-6 text-gray-600">{t("content:profile.Enter your information. Contact your administrator to change email and role.")}</p>
                </div>

                <form className=" md:col-span-6" onSubmit={onSubmit}>
                    <div className="grid gap-4">
                        <Field className="flex gap-3 items-center">
                            <Avatar
                                imgSrc={src}
                                size='lg'
                                initials={user?.initials}
                            />
                            <div>
                                <div className='flex gap-2'>
                                    <Button onClick={() => fileInputRef.current?.click()} className="btn btn-outline block" >
                                        {t("uploadAFile")}
                                    </Button>
                                    <Button onClick={deleteProfilePicture} disabled={!profile.data.avatar?.uri} className="btn btn-gray">
                                        {t("delete")}
                                    </Button>
                                </div>
                                <Input
                                    name="avatar"
                                    type="file"
                                    className="sr-only"
                                    accept='image/*'
                                    ref={fileInputRef}
                                    onChange={previewAvatar}
                                />
                                <p className="text-xs leading-5 text-gray-400">JPG, GIF eller PNG. 1MB max.</p>
                            </div>
                        </Field>

                        <Field className="">
                            <Label className="input-label">
                                {t("fullName")}
                            </Label>
                            <Input
                                type="text"
                                name="name"
                                autoComplete="given-name"
                                className="input mt-1"
                                defaultValue={user?.name}
                            />
                        </Field>


                        <Field className="">
                            <Label className="input-label">
                                {t("initials")}
                            </Label>
                            <Input
                                type="text"
                                name="initials"
                                className="input mt-1"
                                placeholder={'ABC'}
                                defaultValue={user?.initials}
                            />
                        </Field>

                        <Field className="">
                            <Label className="input-label">
                                {t("phoneNumber")}
                            </Label>
                            <Input
                                type="tel"
                                name="phone"
                                autoComplete="home tel"
                                className="input mt-1"
                                defaultValue={user?.phone}
                            />
                        </Field>

                        <Field disabled className="">
                            <Label className="input-label">
                                {t("email")}
                            </Label>
                            <Input
                                type="email"
                                name="email"
                                autoComplete="work email"
                                className="input mt-1"
                                defaultValue={user?.email}
                            />
                        </Field>

                        <Field disabled className="">
                            <Label className="input-label">
                                {t("role")}
                            </Label>
                            <div className="relative">
                                <Select
                                    name='role'
                                    defaultValue={user?.role.value}
                                    className={clsx(
                                        'input',
                                        // Make the text of each option black on Windows
                                        '*:text-black'
                                    )}
                                >
                                    {dealerRoles.map(role => (
                                        <option key={role.value} value={role.value}>{role.name}</option>
                                    ))}
                                </Select>
                            </div>
                        </Field>

                        <div className="flex justify-end">
                            <Button
                                type="submit"
                                className="btn btn-primary"
                            >
                                {t("save")}
                            </Button>
                        </div>
                    </div>
                </form>
            </div>

        </Spinner>

    )
}
