type CarBrands =
    | "kia"
    | "aston"
    | "ssangyong"
    | "chrysler"
    | "navistar"
    | "lancia"
    | "paccar"
    | "lincoln"
    | "maserati"
    | "eicher"
    | "genesis"
    | "geo"
    | "packard"
    | "ariel"
    | "general-motors"
    | "general motors"
    | "maxus"
    | "vlf"
    | "caterham"
    | "ds"
    | "hummer"
    | "acura"
    | "gt-r"
    | "mazda"
    | "proton"
    | "lada"
    | "karma"
    | "polestar"
    | "alpine"
    | "alfa"
    | "byd"
    | "lucid"
    | "nismo"
    | "pagani"
    | "lynkco"
    | "seat"
    | "smart"
    | "brabus"
    | "volvo"
    | "saic"
    | "hino"
    | "iveco"
    | "international harvester"
    | "international-harvester"
    | "ram"
    | "maz"
    | "mercury"
    | "uaz"
    | "tesla"
    | "saturn"
    | "chevrolet"
    | "cadillac"
    | "audi"
    | "bugatti"
    | "oldsmobile"
    | "jetta"
    | "international trucks"
    | "international-trucks"
    | "fisker"
    | "noble"
    | "dacia"
    | "lamborghini"
    | "suzuki"
    | "mclaren"
    | "honda"
    | "peterbilt"
    | "ktm"
    | "vencer"
    | "opel"
    | "infiniti"
    | "xpeng"
    | "porsche"
    | "nissan"
    | "daewoo"
    | "daihatsu"
    | "saleen"
    | "bmw"
    | "toyota"
    | "bentley"
    | "exeed"
    | "geely"
    | "peugeot"
    | "koenigsegg"
    | "abt"
    | "great"
    | "daimler"
    | "mg"
    | "kenworth"
    | "dmc"
    | "gmc"
    | "western star"
    | "western-star"
    | "scion"
    | "lexus"
    | "buick"
    | "alpina"
    | "isuzu"
    | "rolls royce"
    | "rolls-royce"
    | "ferrari"
    | "mercedes-benz"
    | "mercedes"
    | "mercedes benz"
    | "_generic-front-1"
    | "_generic-front-2"
    | "_generic-front-side-1"
    | "land-rover"
    | "dodge"
    | "rover"
    | "fiat"
    | "jaguar"
    | "studebaker"
    | "chevrolet corvette"
    | "corvette"
    | "chevrolet-corvette"
    | "vinfast"
    | "skoda"
    | "scania"
    | "jeep"
    | "mustang"
    | "hyundai"
    | "daf"
    | "pontiac"
    | "holden"
    | "mack"
    | "maybach"
    | "datsun"
    | "viper"
    | "renault"
    | "amg"
    | "tata"
    | "abarth"
    | "saab"
    | "man"
    | "elemental"
    | "citroen"
    | "ford"
    | "hennessey"
    | "bmw-m"
    | "bmw m"
    | "mitsubishi"
    | "vauxhall"
    | "audi"
    | "subaru"
    | "lotus"
    | "volkswagen"
    | "vw"
    | "mini"
    | "sterling";


export default async function getFallbackCarImg(brand: CarBrands) {
    switch (brand) {
        case "kia": return (await import('@assets/cars/Kia-logo-640x321.webp'))
        case "aston": return (await import('@assets/cars/Aston-Martin-logo-2003-640x286.jpg'))
        case "ssangyong": return (await import('@assets/cars/SsangYong-logo-640x422.webp'))
        case "chrysler": return (await import('@assets/cars/chrysler-logo-2009-640.webp'))
        case "navistar": return (await import('@assets/cars/Navistar-International-logo-640x104.jpg'))
        case "lancia": return (await import('@assets/cars/Lancia-logo-2007-640x550.webp'))
        case "paccar": return (await import('@assets/cars/Paccar-logo-640x110.jpg'))
        case "lincoln": return (await import('@assets/cars/Lincoln-logo-2019-640x222.jpg'))
        case "maserati": return (await import('@assets/cars/maserati-logo-2020-640.webp'))
        case "eicher": return (await import('@assets/cars/Eicher-logo-640x323.jpg'))
        case "genesis": return (await import('@assets/cars/Genesis-logo-640x248.jpg'))
        case "geo": return (await import('@assets/cars/Geo-logo-640x172.jpg'))
        case "packard": return (await import('@assets/cars/packard-logo-650x650-show.webp'))
        case "ariel": return (await import('@assets/cars/Ariel-logo-2000-640x550.jpg'))
        case "general motors":
        case "general-motors": return (await import('@assets/cars/General-Motors-logo-2010-3300x3300.png'))
        case "maxus": return (await import('@assets/cars/Maxus-logo-2014-640x550.webp'))
        case "vlf": return (await import('@assets/cars/vlf-logo-600x900-show.webp'))
        case "caterham": return (await import('@assets/cars/Caterham-logo-640x550.webp'))
        case "ds": return (await import('@assets/cars/DS-logo-2009-640x486.jpg'))
        case "hummer": return (await import('@assets/cars/Hummer-logo-2000x205.png'))
        case "acura": return (await import('@assets/cars/Acura-logo-1990-640x406.jpg'))
        case "gt-r": return (await import('@assets/cars/GT-R-logo-640x454.webp'))
        case "mazda": return (await import('@assets/cars/mazda-logo-2018-vertical-640.webp'))
        case "proton": return (await import('@assets/cars/Proton-logo-2016-640x550.webp'))
        case "lada": return (await import('@assets/cars/Lada-logo-silver-640x248.jpg'))
        case "karma": return (await import('@assets/cars/Karma-logo-640x400.jpg'))
        case "polestar": return (await import('@assets/cars/Polestar-logo-640x505.jpg'))
        case "alpine": return (await import('@assets/cars/Alpine-logo-640x341.jpg'))
        case "alfa": return (await import('@assets/cars/Alfa-Romeo-logo-2015-640x550.webp'))
        case "byd": return (await import('@assets/cars/BYD-logo-2007-640x396.jpg'))
        case "lucid": return (await import('@assets/cars/Lucid-Motors-logo-640x36.webp'))
        case "nismo": return (await import('@assets/cars/Nismo-logo-640x79.jpg'))
        case "pagani": return (await import('@assets/cars/Pagani-logo-1992-640x343.webp'))
        case "lynkco": return (await import('@assets/cars/lynkco-logo-2150x400-show.webp'))
        case "seat": return (await import('@assets/cars/SEAT-logo-2012-640x508.jpg'))
        case "smart": return (await import('@assets/cars/Smart-logo-1994-640x550.webp'))
        case "brabus": return (await import('@assets/cars/Brabus-logo-640x281.jpg'))
        case "volvo": return (await import('@assets/cars/Volvo-logo-2014-640x550.webp'))
        case "saic": return (await import('@assets/cars/SAIC-Motor-logo-640x550.webp'))
        case "hino": return (await import('@assets/cars/Hino-logo-640x550.jpg'))
        case "iveco": return (await import('@assets/cars/Iveco-logo-silver-640x122.jpg'))
        case "international harvester":
        case "international-harvester": return (await import('@assets/cars/International-Harvester-logo-640x550.jpg'))
        case "international trucks":
        case "international-trucks": return (await import('@assets/cars/International-Trucks-logo-640x550.jpg'))
        case "ram": return (await import('@assets/cars/RAM-logo-2009-640x550.webp'))
        case "maz": return (await import('@assets/cars/MAZ-logo-640x377.jpg'))
        case "mercury": return (await import('@assets/cars/Mercury-logo-1980-640x550.jpg'))
        case "uaz": return (await import('@assets/cars/UAZ-logo-640x364.jpg'))
        case "tesla": return (await import('@assets/cars/tesla-logo-2007-full-640.png'))
        case "saturn": return (await import('@assets/cars/Saturn-logo-1985-640x550.webp'))
        case "chevrolet": return (await import('@assets/cars/Chevrolet-logo-2013-640x281.jpg'))
        case "corvette":
        case "chevrolet corvette":
        case "chevrolet-corvette": return (await import('@assets/cars/chevrolet-corvette-logo-2020-640.webp'))
        case "cadillac": return (await import('@assets/cars/cadillac-logo-2021-full-640.webp'))
        case "audi": return (await import('@assets/cars/audi-logo-2016-640.webp'))
        case "bugatti": return (await import('@assets/cars/Bugatti-logo-640x327.webp'))
        case "oldsmobile": return (await import('@assets/cars/Oldsmobile-logo-1996-640x260.jpg'))
        case "jetta": return (await import('@assets/cars/jetta-logo-2019-1300x1100-show.webp'))
        case "fisker": return (await import('@assets/cars/Fisker-logo-2007-640x550.webp'))
        case "noble": return (await import('@assets/cars/Noble-logo-640x375.jpg'))
        case "dacia": return (await import('@assets/cars/Dacia-logo-2008-640x550.webp'))
        case "lamborghini": return (await import('@assets/cars/lamborghini-logo-1998-640.webp'))
        case "suzuki": return (await import('@assets/cars/Suzuki-logo-640x285.jpg'))
        case "mclaren": return (await import('@assets/cars/McLaren-logo-2002-640x92.jpg'))
        case "honda": return (await import('@assets/cars/honda-logo-2000-full-640.webp'))
        case "peterbilt": return (await import('@assets/cars/Peterbilt-logo-640x325.webp'))
        case "ktm": return (await import('@assets/cars/KTM-logo-640x200.jpg'))
        case "vencer": return (await import('@assets/cars/Vencer-logo-640x479.webp'))
        case "opel": return (await import('@assets/cars/Opel-logo-2009-640x496.webp'))
        case "infiniti": return (await import('@assets/cars/Infiniti-logo-1989-640x308.jpg'))
        case "xpeng": return (await import('@assets/cars/xpeng-logo-2021-2700x1100-show.webp'))
        case "porsche": return (await import('@assets/cars/porsche-logo-2014-full-640.webp'))
        case "nissan": return (await import('@assets/cars/nissan-logo-2020-black-show.webp'))
        case "daewoo": return (await import('@assets/cars/Daewoo-logo-640x404.webp'))
        case "daihatsu": return (await import('@assets/cars/Daihatsu-logo-1997-1280x233.webp'))
        case "saleen": return (await import('@assets/cars/Saleen-logo-640x456.jpg'))
        case "bmw": return (await import('@assets/cars/bmw-logo-2020-gray.webp'))
        case "bmw m":
        case "bmw-m": return (await import('@assets/cars/BMW-M-logo-640x231.jpg'))
        case "toyota": return (await import('@assets/cars/toyota-logo-2020-europe-640.webp'))
        case "bentley": return (await import('@assets/cars/bentley-logo-2002-640.webp'))
        case "exeed": return (await import('@assets/cars/exeed-logo-1200x200-show.webp'))
        case "geely": return (await import('@assets/cars/Geely-logo-2014-640x370.webp'))
        case "peugeot": return (await import('@assets/cars/Peugeot-logo-2010-640x451.jpg'))
        case "koenigsegg": return (await import('@assets/cars/Koenigsegg-logo-1994-640x550.webp'))
        case "abt": return (await import('@assets/cars/ABT-Sportsline-logo-silver-640x250.jpg'))
        case "great": return (await import('@assets/cars/Great-Wall-logo-2007-640x550.webp'))
        case "daimler": return (await import('@assets/cars/Daimler-logo-640x80.jpg'))
        case "mg": return (await import('@assets/cars/MG-logo-red-2010-640x550.webp'))
        case "kenworth": return (await import('@assets/cars/Kenworth-logo-640x550.webp'))
        case "dmc": return (await import('@assets/cars/DMC-logo-640x124.jpg'))
        case "gmc": return (await import('@assets/cars/GMC-logo-640x145.jpg'))
        case "western star":
        case "western-star": return (await import('@assets/cars/Western-Star-logo-640x419.webp'))
        case "scion": return (await import('@assets/cars/Scion-logo-2003-640x442.jpg'))
        case "lexus": return (await import('@assets/cars/Lexus-logo-1988-640x266.jpg'))
        case "buick": return (await import('@assets/cars/Buick-logo-2002-640x200.jpg'))
        case "alpina": return (await import('@assets/cars/Alpina-logo-640x550.webp'))
        case "isuzu": return (await import('@assets/cars/Isuzu-logo-1991-640x106.jpg'))
        case "rolls royce":
        case "rolls-royce": return (await import('@assets/cars/Rolls-Royce-logo-640x550.webp'))
        case "ferrari": return (await import('@assets/cars/ferrari-logo-2002-640.webp'))
        case "mercedes":
        case "mercedes benz":
        case "mercedes-benz": return (await import('@assets/cars/Mercedes-Benz-logo-2011-640x369.jpg'))
        case "_generic-front-1": return (await import('@assets/cars/_Generic-1.webp'))
        case "_generic-front-2": return (await import('@assets/cars/_Generic-2.png'))
        case "_generic-front-side-1": return (await import('@assets/cars/_Generic-3.png'))
        case "land-rover": return (await import('@assets/cars/Land-Rover-logo-2011-640x335.webp'))
        case "dodge": return (await import('@assets/cars/dodge-logo-2010-640.webp'))
        case "rover": return (await import('@assets/cars/Rover-logo-2003-640x550.webp'))
        case "fiat": return (await import('@assets/cars/Fiat-logo-2006-640x550.webp'))
        case "jaguar": return (await import('@assets/cars/jaguar-logo-2021-640.webp'))
        case "studebaker": return (await import('@assets/cars/Studebaker-logo-640x550.webp'))
        case "vinfast": return (await import('@assets/cars/vinfast-logo-900x850-show.webp'))
        case "skoda": return (await import('@assets/cars/Skoda-logo-2016-640x550.jpg'))
        case "scania": return (await import('@assets/cars/Scania-logo-640x169.jpg'))
        case "jeep": return (await import('@assets/cars/jeep-logo-1993-640.webp'))
        case "mustang": return (await import('@assets/cars/Mustang-logo-2010-640x359.jpg'))
        case "hyundai": return (await import('@assets/cars/hyundai-logo-2011-640.webp'))
        case "daf": return (await import('@assets/cars/DAF-logo-640x281.jpg'))
        case "pontiac": return (await import('@assets/cars/Pontiac-logo-640x440.jpg'))
        case "holden": return (await import('@assets/cars/Holden-logo-2016-640x550.jpg'))
        case "mack": return (await import('@assets/cars/Mack-logo-2014-640x314.jpg'))
        case "maybach": return (await import('@assets/cars/Maybach-logo-640x353.jpg'))
        case "datsun": return (await import('@assets/cars/Datsun-logo-2013-640x344.webp'))
        case "viper": return (await import('@assets/cars/Viper-logo-640x550.jpg'))
        case "renault": return (await import('@assets/cars/Renault-logo-2015-640x550.jpg'))
        case "amg": return (await import('@assets/cars/AMG-logo-black-640x62.jpg'))
        case "tata": return (await import('@assets/cars/Tata-logo-2000-640x550.jpg'))
        case "abarth": return (await import('@assets/cars/Abarth-logo-640x540.webp'))
        case "saab": return (await import('@assets/cars/Saab-logo-2013-640x143.jpg'))
        case "man": return (await import('@assets/cars/MAN-logo-640x355.jpg'))
        case "elemental": return (await import('@assets/cars/Elemental-logo-640x218.jpg'))
        case "citroen": return (await import('@assets/cars/Citroen-logo-2009-640x550.webp'))
        case "ford": return (await import('@assets/cars/ford-logo-2017-640.webp'))
        case "hennessey": return (await import('@assets/cars/Hennessey-text-logo-1186x130.webp'))
        case "mitsubishi": return (await import('@assets/cars/Mitsubishi-logo-640x550.jpg'))
        case "vauxhall": return (await import('@assets/cars/Vauxhall-logo-2008-red-640x478.webp'))
        case "subaru": return (await import('@assets/cars/subaru-logo-2019-640.webp'))
        case "lotus": return (await import('@assets/cars/Lotus-logo-2019-640x550.webp'))
        case "vw":
        case "volkswagen": return (await import('@assets/cars/Volkswagen-logo-2019-640x500.jpg'))
        case "mini": return (await import('@assets/cars/Mini-logo-2001-640x270.jpg'))
        case "sterling": return (await import('@assets/cars/Sterling-logo-640x367.jpg'))
        default: return (await import('@assets/cars/_Generic-3.png'))
    }
}
