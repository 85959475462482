// import DashboardIcon from '@/assets/DMF-Logo/DashboardPro/dashboard-fb2x.png';
import DashboardIcon from '@/assets/icons/DashboardIcon';
// import IMPORTIcon from '@/assets/DMF-Logo/ImportPro/import-fb2x.png';
// import LEASINGIcon from '@/assets/DMF-Logo/LeasingPro/leasing-fb2x.png';
// import EquipmentIcon from '@/assets/DMF-Logo/UdstyrPro/udstyr-fb2x.png';
import EquipmentIcon from '@/assets/icons/CarDataIcon';
import IMPORTIcon from '@/assets/icons/IMPORTIcon';
import LEASINGIcon from '@/assets/icons/LEASINGIcon';
// import DMFProLogo from '@assets/DMF-Logo/DMFPRO/Horizontal/dmf-fb2x.png';
import DMFIcon from '@/assets/icons/DMFIcon';
// import TAXPROIcon from '@assets/DMF-Logo/TaxPro/tax-fb2x.png';
import TAXPROIcon from '@assets/icons/TAXIcon';

import { Link, useLocation } from '@tanstack/react-router';

type Props = {
    className?: string;
}

export default function SubLogo({ className }: Props) {
    const path = useLocation().pathname
    if (path.indexOf("/tax") === 0) {
        return <Tax className={className} />
    } else if (path.indexOf("/import") === 0) {
        return <Import className={className} />
    } else if (path.indexOf("/leasing") === 0) {
        return <Leasing className={className} />
    } else if (path.indexOf("/equipment") === 0) {
        return <Equipment className={className} />
    } else if (path.indexOf("/dealership") === 0) {
        return <DMFPro className={className} />
    } else {
        return <Dashboard className={className} />
    }
}

function Tax({ className }: Props) {
    return (
        <Link to="/tax" className={className + " h-8 flex gap-1.5 items-center text-dark-blue"}>
            {/* <img src={TAXPROIcon} alt="Tax Pro" className='inline-block w-auto h-8' /> */}
            <TAXPROIcon className=' [&>*]:fill-transparent' />
            <span className='text-xl font-serif uppercase'>
                Tax
            </span>
            <span className='text-xs bg-primary rounded-sm py-0.5 px-1 font-semibold text-white'>PRO</span>
        </Link>
    )
}
function Import({ className }: Props) {
    return (
        // <Link to="/import" className={className + " h-8 flex gap-1.5 items-center"}>
        //     <img src={IMPORTIcon} alt="Import pro" className='inline-block w-auto h-8' />
        // </Link>
        <Link to="/import" className={className + " h-8 flex gap-1.5 items-center text-dark-blue"}>
            <IMPORTIcon className=' [&>*]:fill-transparent' />
            <span className='text-xl font-serif uppercase'>
                Import
            </span>
            <span className='text-xs bg-primary rounded-sm py-0.5 px-1 font-semibold text-white'>PRO</span>
        </Link>
    )
}
function Leasing({ className }: Props) {
    return (
        // <Link to="/leasing" className={className + " h-8 flex gap-1.5 items-center"}>
        //     <img src={LEASINGIcon} alt="Leasing Pro" className='inline-block w-auto h-8' />
        // </Link>
        <Link to="/leasing" className={className + " h-8 flex gap-1.5 items-center text-dark-blue"}>
            <LEASINGIcon className=' [&>*]:fill-transparent' />
            <span className='text-xl font-serif uppercase'>
                Leasing
            </span>
            <span className='text-xs bg-primary rounded-sm py-0.5 px-1 font-semibold text-white'>PRO</span>
        </Link>
    )
}
function Equipment({ className }: Props) {
    return (
        // <Link to="/equipment" className={className + " h-8 flex gap-1.5 items-center"}>
        //     <img src={EquipmentIcon} alt="Udstyr PRO" className='inline-block w-auto h-8' />
        // </Link>
        <Link to="/equipment" className={className + " h-8 flex gap-1.5 items-center text-dark-blue"}>
            <EquipmentIcon className=' [&>*]:fill-transparent' />
            <span className='text-xl font-serif uppercase'>
                Udstyr
            </span>
            <span className='text-xs bg-primary rounded-sm py-0.5 px-1 font-semibold text-white'>PRO</span>
        </Link>
    )
}
function DMFPro({ className }: Props) {
    return (
        // <Link to="/dealership" className={className + " h-8 flex gap-1.5 items-center"}>
        //     <img src={DMFProLogo} alt="DMF Pro" className='inline-block w-auto h-9' />
        // </Link>
        <Link to="/dealership" className={className + " h-8 flex items-center text-dark-blue"}>
            <DMFIcon className='h-10 w-10' />
            <span className='text-xl font-serif uppercase mr-1.5'>
                DMF
            </span>
            <span className='text-xs bg-primary rounded-sm py-0.5 px-1 font-semibold text-white'>PRO</span>
        </Link>
    )
}
function Dashboard({ className }: Props) {
    return (
        // <Link to="/dashboard" className={className + " h-8 flex gap-1.5 items-center"}>
        //     <img src={DashboardIcon} alt="Dashboard Pro" className='inline-block w-auto h-8' />
        // </Link>
        <Link to="/dashboard" className={className + " h-8 flex gap-1.5 items-center text-dark-blue"}>
            <DashboardIcon className=' [&>*]:fill-transparent' />
            <span className='text-xl font-serif uppercase'>
                Dashboard
            </span>
            <span className='text-xs bg-primary rounded-sm py-0.5 px-1 font-semibold text-white'>PRO</span>
        </Link>
    )
}
