import { DashboardData } from "@/types/appTypes";
import DMFHQFront from '@assets/images/dashboard-newsentry-1.png';
import { Button, Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import { ArrowTopRightOnSquareIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { PlayIcon } from "@heroicons/react/24/solid";
import { t } from "i18next";
import { useState } from "react";

type Props = DashboardData['data']['newsFeed'][number]

export default function DashboardCard(props: Props) {
    return (
        <div className="relative bg-white rounded-sm h-full border overflow-clip max-xl:min-w-72">
            <InnerCard {...props} />
        </div>
    )
}

function InnerCard(props: Props) {
    switch (props.category) {
        case "newsEntry": return <DMFNewsEntryCard {...props} />;
        case "externalArticle": return <ArticleCard {...props} />;
        case "internalArticle": return <ArticleCard {...props} />;
        case "video": return <VideoCard {...props} />
        default: return <></>
    }
}


function DMFNewsEntryCard({ title, text }: Props) {
    const [isOpen, setIsOpen] = useState(false)
    function openArticle() {
        setIsOpen(true)
    }
    function closeArticle() {
        setIsOpen(false)
    }

    return (
        <>
            <Button className={"block text-left w-full"} onClick={openArticle}>
                {/* <span className="absolute top-5 left-5 rounded-full inline-block leading-[26px] text-[12px] font-medium text-cyan-600 bg-cyan-100 px-2.5">DMF Pro</span> */}
                <img className="h-40 w-full object-cover object-bottom" src={DMFHQFront} alt="DMF HQ Front" />
                <div className="p-5 border-t h-[100px]">
                    <p className="text-xs font-medium">{t("news")}</p>
                    <h3 className="font-medium text-dark-blue leading-5 line-clamp-2">{title}</h3>
                </div>
            </Button>
            <Dialog open={isOpen} as="div" className="relative z-10 focus:outline-none" onClose={closeArticle}>
                <DialogBackdrop transition className="fixed inset-0 bg-dark-blue/30 backdrop-[1px]" />
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4">
                        <DialogPanel
                            transition
                            className="w-full max-w-2xl bg-white rounded-lg p-4 relative duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0"
                        >
                            <Button onClick={closeArticle} className="btn btn-gray absolute right-2 top-2 size-10 p-0"><XMarkIcon className="size-5" aria-hidden="true" /></Button>
                            <div className="mb-4">
                                <h3 className="text-lg text-dark-blue font-medium">
                                    {title}
                                </h3>
                            </div>
                            <div dangerouslySetInnerHTML={{ __html: text || "" }}></div>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

function ArticleCard({ link, subtitle, title, coverImage }: Props) {
    return (

        <a className={"block text-left"} target="_blank" href={link || "#"}>
            {/* {category === 'externalArticle' ? (
                <span className="absolute top-5 left-5 rounded-full inline-block leading-[26px] text-[12px] font-medium text-yellow-700 bg-yellow-100 px-2.5">{t("external")}</span>
            ) : (
                <span className="absolute top-5 left-5 rounded-full inline-block leading-[26px] text-[12px] font-medium text-green-600 bg-green-100 px-2.5">danskmotorfinans.dk</span>
            )} */}
            <img className="h-40 w-full object-cover" src={coverImage || DMFHQFront} alt={title} />
            <div className="p-5 border-t h-[100px]">
                <p className="text-xs font-medium">
                    {subtitle} {' '}
                    <ArrowTopRightOnSquareIcon className="align-top inline size-4" />
                </p>
                <h3 className="font-medium text-dark-blue leading-5 line-clamp-2">{title}</h3>
            </div>
        </a>
    )
}

function VideoCard({ title, coverImage, subtitle, link }: Props) {
    const [isOpen, setIsOpen] = useState(false)
    function playVideo() {
        setIsOpen(true)
    }
    function closeVideo() {
        setIsOpen(false)
    }

    return (
        <>
            <Button className={"block text-left"} onClick={playVideo}>
                <span className="absolute z-[2] top-12 left-1/2 -translate-x-1/2 flex items-center justify-center w-20 h-16 bg-primary rounded-md shadow-[0_0_40px_2px_rgba(0,0,0,0.2)]">
                    <PlayIcon className="text-white size-7" />
                </span>
                <img className="h-40 w-full object-cover" src={coverImage || DMFHQFront} alt="DMF HQ Front" />
                <div className="absolute z-[1] top-0 inset-x-0 h-40 bg-dark-blue/20"></div>
                <div className="p-5 border-t h-[100px]">
                    <p className="text-xs font-medium line-clamp-1">{subtitle}</p>
                    <h3 className="font-medium text-dark-blue leading-5 line-clamp-2">{title}</h3>
                </div>
            </Button>
            {link && (
                <Dialog open={isOpen} as="div" className="relative z-10 focus:outline-none" onClose={closeVideo}>
                    <DialogBackdrop transition className="fixed inset-0 bg-dark-blue/30 backdrop-[1px]" />
                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4">
                            <DialogPanel
                                transition
                                className="w-full max-w-4xl duration-300 relative ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0 shadow-[0_0px_44px_7px_rgba(0,0,0,0.6)]"
                            >
                                <Button onClick={closeVideo} className="btn btn-gray bg-opacity-20 hover:bg-opacity-40 absolute right-2 top-2 size-10 p-0"><XMarkIcon className="size-5" aria-hidden="true" /></Button>
                                <video width="720" controls className="w-full h-auto object-cover" poster={coverImage || DMFHQFront}>
                                    <source src={link} type="video/mp4" />
                                    <source src={link} type="video/webm" />
                                    <source src={link} type="video/mpeg" />
                                    Your browser does not support the video tag.
                                </video>
                            </DialogPanel>
                        </div>
                    </div>
                </Dialog>
            )}
        </>
    )
}
