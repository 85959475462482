import { FaceFrownIcon, FaceSmileIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";

// FaceSmileIcon, FaceFrownIcon
type PasswordRequirementProps = {
    valid: boolean;
    label: string;
}
export function PasswordRequirement({ label, valid }: PasswordRequirementProps) {
    return (
        <li className={clsx(
            "flex gap-1 items-center text-xs",
            valid ? "text-green-700" : "text-red-500"
        )}>
            <span>
                {valid ? (
                    <FaceSmileIcon className="h-5 w-5 flex-none" />
                ) : (
                    <FaceFrownIcon className="h-5 w-5 flex-none" />
                )}
            </span>
            {label}
        </li>
    )
}
