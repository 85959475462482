import DMFLogo from "@assets/DMF-Logo/DMFPRO/Horizontal/dmf-white3x.png";
import { Button, Description, Field, Fieldset, Input, Label } from '@headlessui/react';
import { ArrowLongLeftIcon, CheckCircleIcon } from '@heroicons/react/24/outline';
import { useMutation } from '@tanstack/react-query';
import {
    Link,
    createFileRoute,
    useRouter
} from '@tanstack/react-router';
import { t } from 'i18next';
import * as React from 'react';
import { z } from 'zod';
import { UnAuthenticatedLayout } from '../../components/UnauthenticatedLayout';
import { csrf, initializeResetPassword } from '../../services/auth';
import { errorMessage } from '../../utils/toast';
import { raise } from '../../utils/utils';

export const Route = createFileRoute('/_auth/forgot-password/')({
    validateSearch: z.object({
        redirect: z.string().optional().catch(''),
    }),
    meta: () => [{ title: t("forgotPassword") }],
    component: ForgotPassword,
})

function ForgotPassword() {

    const { mutate, isPending, status } = useMutation({
        mutationFn: async (email: string) => {
            await csrf()
            return initializeResetPassword(email)
        },
        onError: (error) => {
            errorMessage(error)
        }
    })

    const router = useRouter()

    const onFormSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
        try {
            evt.preventDefault()
            const data = new FormData(evt.currentTarget)
            const email = data.get("email")?.toString() || raise(t("status:error.emailRequired"));
            mutate(email)
        } catch (error) {
            errorMessage(error)
        }

    }
    const goBack = () => router.history.back();

    return (
        <UnAuthenticatedLayout>
            <img
                className="mx-auto w-64 z-10 relative"
                src={DMFLogo}
                alt="DMF Pro"
            />

            <div className="mt-20 sm:mx-auto sm:w-full sm:max-w-md bg-white sm:px-12 sm:py-8 p-4 rounded-sm">
                <h2 className="mb-10 text-center text-2xl font-bold leading-9 tracking-tight text-dark-blue-hover">
                    {t("forgotPassword")}
                </h2>
                <Button className="-ml-4 mb-2 link btn" onClick={goBack}>
                    <ArrowLongLeftIcon className='size-5' aria-hidden="true" />
                    {t("back")}
                </Button>
                {status === 'success' ? (
                    <div className="rounded-md bg-green-100 p-4 flex">
                        <CheckCircleIcon className="size-6 text-green-500 flex-none mt-0.5" aria-hidden="true" />
                        <div className="ml-3">
                            <h2 className="text-green-900 font-semibold text-lg mb-1">{t("status:success.emailSent")}</h2>
                            <p className="text-sm font-medium text-green-800">{t("status:success.passwordResetLinkSent")}</p>
                        </div>
                    </div>
                ) : (
                    <form className="space-y-6" onSubmit={onFormSubmit}>
                        <Fieldset disabled={isPending} className="w-full grid gap-4">

                            <Field>
                                <Description className="text-sm mb-2">
                                    {t("content:Enter_your_email_we'll_send_you_a_link_to_reset_your_password.")}
                                </Description>
                                <Label className="input-label">
                                    {t("email")}
                                </Label>
                                <Input
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    required
                                    className="input mt-2"
                                />
                            </Field>

                            <Button
                                type="submit"
                                className="btn btn-primary justify-center"
                            >
                                {isPending ? t('loading') : t("sendResetLink")}
                            </Button>
                        </Fieldset>
                    </form>
                )}

                <p className="mt-10 text-center text-sm text-gray-500">
                    {t("content:login.Don't have access?")}{' '}
                    <Link to="/sign-up" className="link">
                        {t("createANewUser")}
                    </Link>
                </p>
            </div>
        </UnAuthenticatedLayout>
    )
}
