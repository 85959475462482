import { companyConfig } from '@/config';
import DMFLogo from "@assets/DMF-Logo/DMFPRO/Horizontal/dmf-white3x.png";
import { Button, Checkbox, Field, Fieldset, Input, Label } from '@headlessui/react';
import { ArrowPathIcon, CheckIcon } from '@heroicons/react/24/outline';
import { useMutation } from '@tanstack/react-query';
import { createFileRoute, redirect, useLoaderData, useParams, useRouter, useRouterState } from '@tanstack/react-router';
import { isAxiosError } from 'axios';
import clsx from 'clsx';
import { t } from 'i18next';
import { useRef, useState } from 'react';
import { PasswordRequirement } from '../../components/PasswordRequirement';
import { Spinner } from '../../components/Spinner';
import { UnAuthenticatedLayout } from '../../components/UnauthenticatedLayout';
import { cvrInUseGuest, getCVRDataASGuest, getRegistrationTokenData, registerAsDealer, registerAsUser } from '../../services/auth';
import { RegisterUser } from '../../types/auth';
import { validatePassword } from '../../utils/string';
import { errorMessage } from '../../utils/toast';

const isExpired = (date: string) => new Date(date).getTime() < new Date().getTime();
export const Route = createFileRoute('/_auth/sign-up/$token')({
    meta: () => [{ title: t("createAccount") }],
    loader: async ({ params }) => {
        try {
            const token = params.token
            const { data } = await getRegistrationTokenData(token)
            if (isExpired(data.data.validUntil)) {
                errorMessage(t("status:error.registrationTokenExpired"))
                throw new Error();
            }

            return data
        } catch (err) {
            throw redirect({
                to: "/sign-up"
            })
        }
    },
    component: Signup
})

function Signup() {
    const [errorMsg, setErrorMsg] = useState<string | null>(null)
    const { mutate: fetchCVR, data: cvrData, isPending: isFetchingCVR, reset: resetCvrData } = useMutation({
        mutationFn: async (data: Parameters<typeof getCVRDataASGuest>[0]) => {
            const cvrInUse = await cvrInUseGuest(data)
            if (cvrInUse.data.data) {
                setErrorMsg(t("status:error.cvrInUse"))
                window.scrollTo({ top: 0, behavior: "smooth" })
                throw new Error(t("status:error.cvrInUse"))
            }
            const cvrData = await getCVRDataASGuest(data)
            return cvrData.data.data;
        },
        onError(error) {
            errorMessage(error)
        },
    })

    const isLoading = useRouterState({ select: (s) => s.isLoading })
    const loaderData = useLoaderData({ from: "/_auth/sign-up/$token" });
    const params = useParams({ from: "/_auth/sign-up/$token" });
    const router = useRouter()

    const [requirements, setRequirements] = useState<ReturnType<typeof validatePassword> | null>(null)
    const [termsAccepted, setTermsAccepted] = useState<boolean>(false)
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const formRef = useRef<HTMLFormElement>(null)

    const signupAsDealer = !loaderData.data.forDealer;
    const userEmail = loaderData.data.email;

    const onFormSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
        setErrorMsg(null)
        evt.preventDefault()
        if (signupAsDealer && cvrData == undefined) {
            return errorMessage(t("status:error.needCvrData"))
        }

        const formData = new FormData(evt.currentTarget)
        if (formData.get("terms")?.toString() !== 'on') {
            return errorMessage(t("status:error.mustAgreeToTerms"))
        }
        try {
            setIsSubmitting(true)

            const data: Record<string, string> = {};
            for (const entry of formData.entries()) {
                // @ts-expect-error
                data[entry[0]] = entry[1];
            }

            const user: RegisterUser = {
                email: userEmail,
                name: data.userName,
                password: data.password,
                passwordConfirmation: data.confirmPassword,
                phone: data.userPhone,
            }
            if (signupAsDealer && cvrData) {
                await registerAsDealer({
                    termsAccepted: data.terms === 'on',
                    token: params.token,
                    user,
                    dealer: {
                        address: cvrData.address,
                        city: cvrData.city,
                        cvr: cvrData.cvr,
                        zip: cvrData.zip,
                        name: cvrData.title,
                        phone: data.dealerPhone,
                        email: data.dealerEmail,
                    }
                })
            } else {
                await registerAsUser({
                    termsAccepted: data.terms === 'on',
                    token: params.token,
                    user
                })
            }
            await router.navigate({ to: "/login", replace: true })
            await router.invalidate()
        } catch (error) {
            if (isAxiosError(error) && error.response?.data?.errors && 'dealer.cvr' in error.response.data.errors) {
                setErrorMsg(t("status:error.cvrInUse"))
                window.scrollTo({ behavior: "smooth", top: 0 })
            }
            errorMessage(error)
            setIsSubmitting(false)
        }
    }

    function onPasswordChange() {
        const confirmPassword = formRef.current?.elements.namedItem("confirmPassword") as HTMLInputElement
        const password = formRef.current?.elements.namedItem("password") as HTMLInputElement
        const validation = validatePassword({
            confirmPassword: confirmPassword.value,
            currentPassword: '',
            password: password.value
        })
        setRequirements(validation)
    }

    async function onFetchCVRData() {
        const cvrInput = formRef.current?.elements.namedItem('cvr') as HTMLInputElement;
        try {
            if (!cvrInput.value) {
                throw new Error(t("status:error.needCvrData"));
            }
            fetchCVR({ cvr: cvrInput.value, registrationToken: params.token })
        } catch (err) {
            errorMessage(err)
        }
    }

    function onTermsChange(checked: boolean) {
        setTermsAccepted(checked)
    }

    const onCvrChange = () => { resetCvrData(); setErrorMsg(null); }

    return (
        <UnAuthenticatedLayout>
            <img
                className="mx-auto w-64 z-10 relative"
                src={DMFLogo}
                alt="DMF Pro"
            />

            <div className="mt-20 sm:mx-auto sm:w-full sm:max-w-4xl sm:px-12 sm:py-8 p-4 bg-white rounded-sm ">
                <h2 className="mb-10 text-center text-2xl font-bold leading-9 tracking-tight text-dark-blue">
                    Opret din konto
                </h2>
                <Spinner loading={false}>
                    {errorMsg && (
                        <div className="p-4 mb-4 rounded-lg border border-dashed bg-rose-100 border-rose-400 text-rose-600">
                            {errorMsg}
                        </div>
                    )}
                    <form ref={formRef} className="" onSubmit={onFormSubmit}>
                        {signupAsDealer && (

                            <div className="w-full grid md:grid-cols-10 gap-4 border-b border-text-primary/30 pb-8">
                                <div className='col-span-full'>
                                    <h2 className="text-lg font-semibold leading-7 text-gray-900">{t("companyInformation")}</h2>
                                    <p className="mt-1 text-sm leading-6 text-text-secondary">{t("content:This_is_the_account_associated_with_your_dealership")}</p>
                                </div>

                                <Fieldset disabled={isLoading || isFetchingCVR || isSubmitting} className='md:col-span-6 grid gap-4'>
                                    <div className='flex gap-3'>
                                        <Field className="flex-1">
                                            <Label className="input-label">
                                                {t("cvr") + " " + t('number').toLowerCase()}
                                            </Label>
                                            <Input
                                                type="text"
                                                name="cvr"
                                                autoComplete='billing work email'
                                                className="input mt-1 shadow"
                                                onChange={onCvrChange}
                                                required
                                            />
                                        </Field>
                                        <Button
                                            onClick={onFetchCVRData}
                                            className={clsx(
                                                "btn btn-outline flex-none flex gap-2 items-center self-end",
                                                isFetchingCVR && "opacity-50"
                                            )}
                                        >
                                            <ArrowPathIcon className={clsx("max-sm:hidden h-5 w-5", isFetchingCVR && "animate-spin")} aria-hidden="true" />
                                            {t("updateFromVirk")}
                                        </Button>
                                    </div>

                                    <Field>
                                        <Label className="input-label">
                                            {t("companyEmail")}
                                        </Label>
                                        <Input
                                            type="email"
                                            name="dealerEmail"
                                            className="input mt-1 shadow"
                                            autoComplete='work email'
                                            defaultValue={cvrData?.email}
                                            required
                                        />
                                    </Field>
                                    <Field>
                                        <Label className="input-label">
                                            {t("invoicingEmail")}
                                        </Label>
                                        <Input
                                            type="email"
                                            className="input mt-1 shadow"
                                            name="dealerBillingEmail"
                                            autoComplete='billing work email'
                                            required
                                        />
                                    </Field>
                                    <Field>
                                        <Label className="input-label">
                                            {t("companyMainPhoneNumber")}
                                        </Label>
                                        <Input
                                            type="tel"
                                            className="input mt-1 shadow"
                                            name="dealerPhone"
                                            autoComplete='work tel'
                                            defaultValue={cvrData?.phone}
                                            required
                                        />
                                    </Field>
                                </Fieldset>
                                <div className="md:col-span-4 p-4 bg-gradient-to-b from-slate-50 to-gray-100 shadow-lg rounded-lg">
                                    <h3 className="font-semibold mb-2">{t("companyData")}</h3>
                                    <ul className='text-sm font-medium'>
                                        <li>{cvrData?.title}</li>
                                        <li>{cvrData?.address}</li>
                                        <li>{cvrData?.zip}</li>
                                        <li>{cvrData?.city}</li>
                                    </ul>
                                </div>
                            </div>
                        )}


                        <Fieldset disabled={isLoading || isSubmitting} className='mt-10 grid sm:grid-cols-2 gap-4'>
                            <div>
                                <h2 className="text-lg font-semibold leading-7 text-gray-900">{t("personalInformation")}</h2>
                                <p className="mt-1 text-sm leading-6 text-text-secondary">{t("content:This_is_the_account_you_will_log_in_to_and_work_from")}</p>
                            </div>

                            <Field className='sm:col-span-2' disabled>
                                <Label className="input-label">
                                    {t("email")}
                                </Label>
                                <Input
                                    type="email"
                                    name="userEmail"
                                    className="input mt-1 shadow-md"
                                    autoComplete='work email'
                                    value={userEmail}
                                />
                            </Field>
                            <Field className='col-span-1'>
                                <Label className="input-label">
                                    {t("name")}
                                </Label>
                                <Input
                                    type="text"
                                    className="input mt-1 shadow"
                                    name="userName"
                                    autoComplete='name'
                                    required
                                />
                            </Field>
                            <Field className='col-span-1'>
                                <Label className="input-label">
                                    {t("phoneNumber")}
                                </Label>
                                <Input
                                    type="tel"
                                    className="input mt-1 shadow"
                                    name="userPhone"
                                    autoComplete='work tel'
                                    required
                                />
                            </Field>

                            <Field className='col-span-1'>
                                <Label className="input-label">
                                    {t("password")}
                                </Label>
                                <Input
                                    type="password"
                                    className="input mt-1 shadow"
                                    name="password"
                                    autoComplete='off'
                                    onChange={onPasswordChange}
                                    required
                                />
                                {!!requirements && (
                                    <div className='col-span-2'>
                                        <ul className='grid gap-2 max-w-xl mt-3'>
                                            <PasswordRequirement label={t("password8CharsLong")} valid={requirements.length} />
                                            <PasswordRequirement label={t("includeSpecialCharacters")} valid={requirements.specialChars} />
                                            <PasswordRequirement label={t("mustIncludeLowerCase")} valid={requirements.lower} />
                                            <PasswordRequirement label={t("mustIncludeUppercase")} valid={requirements.upper} />
                                            <PasswordRequirement label={t("mustIncludeNumber")} valid={requirements.number} />
                                        </ul>
                                    </div>
                                )}
                            </Field>
                            <Field className='col-span-1'>
                                <Label className="input-label">
                                    {t("confirmPassword")}
                                </Label>
                                <Input
                                    type="password"
                                    className="input mt-1"
                                    name="confirmPassword"
                                    autoComplete='off'
                                    onChange={onPasswordChange}
                                />
                                {!!requirements && (
                                    <div className='col-span-2'>
                                        <ul className='grid gap-2 max-w-xl mt-3'>
                                            <PasswordRequirement label={t("passwordMustMatch")} valid={requirements.equal} />
                                        </ul>
                                    </div>
                                )}
                            </Field>
                        </Fieldset>


                        <div className='mt-12'>
                            <Field className="flex items-center gap-2 mb-4 flex-wrap">
                                <Checkbox
                                    name="terms"
                                    className="group flex-none size-6 rounded-md bg-gray-200 p-1 ring-1 ring-gray-300 ring-inset data-[checked]:bg-white"
                                    aria-required="true"
                                    checked={termsAccepted}
                                    onChange={onTermsChange}
                                >
                                    <CheckIcon className="hidden flex-none size-4 fill-black group-data-[checked]:block" />
                                </Checkbox>
                                <Label className="flex-1 min-w-0 text-sm break-words">
                                    Jeg bekræfter at have læst og accepteret {' '}
                                    <a onClick={e => e.stopPropagation()} className="link" target="_blank" href={companyConfig.links.subscriptionTerms}>abonnementsbetingelerne</a>
                                    {' '} og de generelle forretningsbetingelser
                                </Label>
                            </Field>
                            <div className='flex justify-center items-center'>
                                <Button
                                    type="submit"
                                    className="btn btn-primary px-12 h-12 text-lg"
                                    disabled={isSubmitting || !termsAccepted || !requirements?.valid || !requirements?.equal}
                                >
                                    {isLoading ? t('loading') : t("createAccount")}
                                </Button>
                            </div>
                        </div>
                    </form>
                </Spinner>
            </div>

        </UnAuthenticatedLayout>

    )
}
