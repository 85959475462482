import { PlusCircleIcon } from '@heroicons/react/24/outline';
import { Link } from '@tanstack/react-router';
import { t } from 'i18next';
import { Spinner } from '../../components/Spinner';



export default function ViewSavedPaymentMethods() {
    return (
        <Spinner loading={false}>
            <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-10">
                <div className="px-4 sm:px-0 md:col-span-4">
                    <h2 className="text-lg font-semibold leading-7 text-gray-900">{t("paymentCards")}</h2>
                    <p className="mt-1 text-sm leading-6 text-gray-600">
                        {t("content:dealer.Payment cards are used for subscription payments and case creation (if you do not have a paid subscription). Here you can manage your saved and preferred payment cards (opens in a new window).")}
                    </p>
                </div>

                <div className="md:col-span-6 flex items-start justify-end">
                    <Link to='/redirect-to-customer-portal' className='btn btn-primary flex-none'>
                        <PlusCircleIcon className='w-5 h-5' aria-hidden="true" />
                        {t("administerPaymentCards")}
                    </Link>
                </div>
            </div>
        </Spinner>

    )
}
