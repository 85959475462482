import { Button, Field, Input, Label } from '@headlessui/react';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import { t } from 'i18next';
import { useRef } from 'react';
import ContentLoading from '../../components/ContentLoading';
import { Spinner } from '../../components/Spinner';
import { useDealerQuery, useMutateDealerFromSource } from '../../hooks/useDealer';
import { ReactFormSubmit } from '../../types/helperTypes';


export default function UpdateDealerLegalData() {
    const { data } = useDealerQuery()
    const { mutate, isPending: mutationPending, data: updatedDealer } = useMutateDealerFromSource()
    const formRef = useRef<HTMLFormElement>(null)

    async function handleSubmit(e: ReactFormSubmit) {
        e.preventDefault();
        mutate(undefined, { onSuccess: () => formRef.current?.reset() })
    }

    if (!data) {
        return <ContentLoading />
    }

    const dealer = data.data
    return (

        <Spinner loading={mutationPending}>
            <div className="grid grid-cols-1 gap-8 md:grid-cols-10">
                <div className="md:col-span-4">
                    <h2 className="text-lg font-semibold text-gray-700">{t("baseData")}</h2>
                    <p className="text-sm text-text-secondary">
                        {t("content:dealer.This is your company's master data. These are updated from the central business register (CVR).")}
                    </p>
                </div>

                <form ref={formRef} className="md:col-span-6" onSubmit={handleSubmit}>
                    <div className="grid grid-cols-2 gap-4">

                        <div className='col-span-2 flex gap-3'>
                            <Field disabled className="flex-1">
                                <Label className="input-label">
                                    {t("cvr") + " " + t('number').toLowerCase()}
                                </Label>
                                <Input
                                    type="text"
                                    name="name"
                                    className="input mt-1"
                                    defaultValue={updatedDealer?.data.cvr ?? dealer.cvr}
                                />
                            </Field>
                            <Button
                                type='submit'
                                className="btn btn-outline flex-none flex gap-2 items-center self-end"
                            >
                                <ArrowPathIcon className="h-5 w-5 max-sm:hidden" aria-hidden="true" />
                                {t("updateFromVirk")}
                            </Button>
                        </div>


                        <Field disabled className='col-span-2'>
                            <Label className="input-label">
                                {t("companyName")}
                            </Label>
                            <Input
                                type="text"
                                name="name"
                                className="input mt-1"
                                defaultValue={updatedDealer?.data.name ?? dealer.name}
                            />
                        </Field>

                        <Field disabled className='col-span-2'>
                            <Label className="input-label">
                                {t("address")}
                            </Label>
                            <Input
                                type="tel"
                                name="address"
                                className="input mt-1"
                                defaultValue={updatedDealer?.data.address ?? dealer.address}
                            />
                        </Field>

                        <Field disabled>
                            <Label className="input-label">
                                {t("zip")}
                            </Label>
                            <Input
                                type="text"
                                name="zip"
                                className="input mt-1"
                                defaultValue={updatedDealer?.data.zip ?? dealer.zip}
                            />
                        </Field>

                        <Field disabled>
                            <Label className="input-label">
                                {t("city")}
                            </Label>
                            <Input
                                type="text"
                                name="city"
                                className="input mt-1"
                                defaultValue={updatedDealer?.data.city ?? dealer.city}
                            />
                        </Field>

                    </div>
                </form>
            </div>

        </Spinner>

    )
}
