import { DashboardData, DealerStatistics, DMRVinDataLookup, GlobalSearchResults, InterimFileResource, PaginatedData } from "../types/appTypes";
import { ChangePassword, PatchProfile, UserResource } from "../types/auth";
import { CVRData, DealerResource, PatchDealer } from "../types/dealer";
import { BillingDestinationResource, PaymentResource } from "../types/paymentTypes";
import axios from "../utils/axios";

const API_BASE_URL = import.meta.env.VITE_API_URL;
const API_PATH = import.meta.env.VITE_API_PATH;
const API_URL = API_BASE_URL + API_PATH;


/**
 * Retrieve information about the vehicle by VIN
 * GET http://localhost:8007/api/v2/vehicleData/byVin/{vin}
 */
export const getVehicleDataByVin = (vin: string) => {
    return axios.get<{ data: DMRVinDataLookup }>(`${API_URL}/vehicleData/byVin/${vin}`);
}


/**
 * Get profile of the authenticated user
 * http://localhost:8007/api/v2/hub/profile
 */
export const getProfile = () => {
    return axios.get<UserResource>(`${API_URL}/hub/profile`);
}


/**
 * Update the values of the authenticated user
 * http://localhost:8007/api/v2/hub/profile
 */
export const patchProfile = (data: PatchProfile) => {
    return axios.patch<UserResource>(`${API_URL}/hub/profile`, data);
}

/**
 * Change the password of the authenticated user
 * http://localhost:8007/api/v2/hub/profile/changePassword
 */
export const changePassword = (data: ChangePassword) => {
    return axios.post(`${API_URL}/hub/profile/changePassword`, data);
}

/**
 * Get information about the dealership
 * GET http://localhost:8007/api/v2/hub/dealer
 * Limits itself to the dealership that authenticated user belongs to.
 */
export const getDealer = () => {
    return axios.get<DealerResource>(`${API_URL}/hub/dealer`);
}

/**
 * Get information about the dealership
 * POST http://localhost:8007/api/v2/hub/dealer/updateFromSource
 * Limits itself to the dealership that authenticated user belongs to.
 */
export const updateDealerFromSource = () => {
    return axios.post<DealerResource>(`${API_URL}/hub/dealer/updateFromSource`, {});
}

/**
 * Update the values of the dealership
 * PATCH http://localhost:8007/api/v2/hub/dealer
 * Limits itself to the dealership that authenticated user belongs to.
 */
export const updateDealer = (data: PatchDealer) => {
    return axios.patch<DealerResource>(`${API_URL}/hub/dealer`, data);
}


/**
 * Retrieve company data by CVR number
 * POST http://localhost:8007/api/v2/hub/getCvrData
 * This endpoint enables fetching company information using the CVR number from official sources. User must be authenticated to be able to use this endpoint.
 */
export const getCVRData = (data: {
    cvr: string;
}) => {
    return axios.post<{ data: CVRData }>(`${API_URL}/hub/getCvrData`, data);
}



/**
 * Retrieve a paginated list of users
 * GET http://localhost:8007/api/v2/hub/dealer/user
 * Limits itself to the dealership that the authenticated user belongs to.
 */
export const getUsers = (page: number, perPage?: number) => {
    const search = `?per_page=${perPage || 10}&page=${page || 1}`
    return axios.get<PaginatedData<UserResource['data']>>(`${API_URL}/hub/dealer/user${search}`);
}


/**
 * Invite a user to the dealership
 * POST http://localhost:8007/api/v2/hub/dealer/user
 * This endpoint enables fetching company information using the CVR number from official sources. Requires a valid registration token.
 */
export const inviteNewUser = (data: {
    email: string;
    role: { id: "dealer_sales" | "dealer_admin" };
}) => {
    return axios.post(`${API_URL}/hub/dealer/user`, data);
}

/**
 * Update user values
 * PATCH http://localhost:8007/api/v2//hub/dealer/user/{user}
 * Allows modification of user information
 */
// TODO --> Update so that name, initials & phone can also be patched
export const updateUserValues = (
    data: { id: string | number, role: { id: string }, initials: string, name: string, phone: string }
) => {
    return axios.patch(`${API_URL}/hub/dealer/user/${data.id}`, data);
}

/**
 * Archive a user
 * DELETE http://localhost:8007/api/v2//hub/dealer/user/{user}
 * Marks the user as archived, effectively disabling their access and removing them from active user lists.
 */
export const archiveUser = (userId: string | number) => {
    return axios.delete(`${API_URL}/hub/dealer/user/${userId}`);
}



/**
 * Get the list of billing destinations
 * GET http://localhost:8007/api/v2/hub/dealer/billingDestination
 * Limits itself to the dealership that the authenticated user belongs to.
 */
export const getBillingDestination = () => {
    return axios.get<{ data: BillingDestinationResource[] }>(`${API_URL}/hub/dealer/billingDestination`);
}

/**
 * Create a new billing destination
 * POST http://localhost:8007/api/v2/hub/dealer/billingDestination
 * Limits itself to the dealership that the authenticated user belongs to.
 */
export const createBillingDestination = (data: CVRData) => {
    return axios.post<{ data: BillingDestinationResource }>(`${API_URL}/hub/dealer/billingDestination`, data);
}

/**
 * Update the billing destination
 * PUT http://localhost:8007/api/v2/hub/dealer/billingDestination/{billingDestination}
 * Limits itself to the dealership that the authenticated user belongs to.
 * @param {number} billingDestination - The billing destination ID
 */
export const updateBillingDestination = (billingDestination: number, data: CVRData) => {
    return axios.put<{ data: BillingDestinationResource }>(`${API_URL}/hub/dealer/billingDestination/${billingDestination}`, data);
}

/**
 * Delete the billing destination
 * DELETE http://localhost:8007/api/v2/hub/dealer/billingDestination/{billingDestination}
 * Limits itself to the dealership that the authenticated user belongs to.
 * @param billingDestinationId - The billing destination ID - Integer
 */
export const deleteBillingDestination = (billingDestinationId: string | number) => {
    return axios.delete<string>(`${API_URL}/hub/dealer/billingDestination/${billingDestinationId}`);
}



/**
 * Retrieve dealership payments for a specified time period
 * GET http://localhost:8007/api/v2/hub/dealer/payment
 * Retrieves all payments made on the dealership within the specified time range.
 * Both from and to must be a valid ISO 8601 string
 * @param from - From timestamp
 * @param to - To timestamp
 */
export const getDealershipPayment = (from: string, to: string) => {
    let fromDate = new Date(from);
    let toDate = new Date(to);
    let query = '';

    if (!(isNaN(fromDate.getTime()) || isNaN(toDate.getTime()))) {
        from = fromDate.toISOString();
        to = toDate.toISOString()
        query = `from=${from}&to=${to}`
    }
    return axios.get<{ data: PaymentResource[], from: string, to: string }>(`${API_URL}/hub/dealer/payment?${query}`);
}


/**
 * Retrieve dashboard data
 * GET http://localhost:8007/api/v2/hub/dashboard
 */
export const getDashboardData = () => {
    return axios.get<DashboardData>(`${API_URL}/hub/dashboard`);
}

/**
 * Retrieve some statistics for the dealership
 * GET http://localhost:8007/api/v2/stats
 * query param: period: string<7 | 30 | 365 | all>
 */
export const getDealerStatistics = (period: 7 | 30 | 365 | "all") => {
    return axios.get<DealerStatistics>(`${API_URL}/stats?period=${encodeURIComponent(period)}`);
}

/**
 * Search globally in the system
 * GET http://localhost:8007/api/v2/search
 * query param: q: string
 */
export const searchSystem = (query?: string) => {
    const q = encodeURIComponent(decodeURIComponent(query || ''))
    return axios.get<GlobalSearchResults>(`${API_URL}/search?q=${q}`);
}

/**
 * Contact support with a message
 * POST http://localhost:8007/api/v2/hub/contactSupport
 */
export const contactSupport = (data: {
    message: string,
    type: "general" | "tax" | "import" | "leasing",
}) => {
    return axios.post<GlobalSearchResults>(`${API_URL}/hub/contactSupport`, { ...data, url: window.location.href });
}

/**
 * Retrieve users DMFNET credentials
 * POST http://localhost:8007/api/v2/hub/getDmfNetCredentials
 */
export const getDMFNetCredentials = () => {
    return axios.post<unknown>(`${API_URL}/hub/getDmfNetCredentials`);
}

/**
 * Upload a temporary file to the system
 * POST http://localhost:8007/api/v2/hub/interimFile \
 * NOTE: \<form method="post" action='http://localhost:8007/api/v2/tax/case/{taxCase}/media' encType='multipart/form-data'> \
 * NOTE: exptects this shape: formData.append("items[]", file)
 */
export const uploadTemporaryFile = (formData: FormData) => {
    return axios.post<InterimFileResource>(`${API_URL}/hub/interimFile`, formData);
}
export const uploadTemporaryFileURL = () => {
    return `${API_URL}/hub/interimFile`;
}

/**
 * Destroy a temporary upload file
 * DELETE http://localhost:8007/api/v2/hub/interimFile/{id}
 */
export const deleteTemporaryFile = (id: string) => {
    return axios.delete(`${API_URL}/hub/interimFile/${id}`);
}
