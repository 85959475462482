import { useArchiveUser } from "@/hooks/useDealer";
import { errorMessage, successMessage } from "@/utils/toast";
import { Button } from "@headlessui/react";
import { createFileRoute, useLoaderData } from '@tanstack/react-router';
import { t } from 'i18next';
import UpdatePassword from '../../../features/profile/UpdatePassword';
import UpdateUserProfile from '../../../features/profile/UpdateUserProfile';

export const Route = createFileRoute('/_protected/_dealership/dealership/profile')({
    meta: () => [{ title: t("profile") }],
    component: ManageProfile
})





export default function ManageProfile() {
    const { user } = useLoaderData({ from: "/_protected" })
    const archive = useArchiveUser()
    // TODO --> Make Modal view for archiving user
    function handleDeleteUser() {
        if (user?.id) {
            if (window.confirm(t("status:confirm.deleteUser"))) {
                if (window.confirm(t("status:confirm.deleteUserReallySure"))) {
                    archive.mutate(user.id,
                        {
                            onSuccess: () => {
                                successMessage(t("status:success.userUpdated"))
                                Route.router?.invalidate()
                                Route.router?.navigate({ to: "/login" })
                            },
                            onError(error) {
                                errorMessage(error);
                            },
                        }
                    )
                }
            }

        }
    }
    return (
        <section>
            <div className="p-8 md:flex md:items-center md:justify-between border-b">
                <div className="min-w-0 flex-1">
                    <h2 className="text-2xl font-semibold text-gray-700 sm:truncate">
                        {t("your")} {t("user").toLowerCase()}
                    </h2>
                </div>
                <div className="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0">
                    <Button
                        type="button"
                        className="btn btn-outline danger"
                        onClick={handleDeleteUser}
                    >
                        {t("close")} {t("user").toLowerCase()}
                    </Button>
                </div>
            </div>
            <div className="divide-y px-8">
                <div className="py-12">
                    <UpdateUserProfile />
                </div>
                <div className="py-12">
                    <UpdatePassword />
                </div>
            </div>
        </section>
    )
}
