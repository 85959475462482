import { Button, Select } from '@headlessui/react';
import { t } from 'i18next';
import ContentLoading from '../../components/ContentLoading';
import { Spinner } from '../../components/Spinner';
import { checkIsAdmin } from '../../enums/enums';
import { useProfile } from '../../hooks/useProfile';

import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { Link, useRouter, useSearch } from '@tanstack/react-router';
import { ChangeEvent, lazy, Suspense, useState } from 'react';
import StatusTag from '../../components/StatusTag';
import { getDealershipPayment } from '../../services/hub';
import axios from '../../utils/axios';
import { formatDate, formatPrice, getDaysInMonth } from '../../utils/string';
import { errorMessage } from '../../utils/toast';


function getTimeRangeOfMonth(year: number, month: number) {
    // select month of year, and make the range that entire month (feb 1. - feb 28.)
    const paddedMonth = String(month).padStart(2, '0')
    const daysInMonth = getDaysInMonth(year, month)
    const from = `${year}-${paddedMonth}-01` // start of month
    const to = `${year}-${paddedMonth}-${daysInMonth}` // end of month
    return { from, to }
}

const usePaymentHistory = () => {
    const search = useSearch({ from: "/_protected/_dealership/dealership/payment-history" })
    const { from, to } = getTimeRangeOfMonth(search.year, search.month);
    return useQuery({
        queryKey: ['paymentHistory', from, to],
        queryFn: async () => {
            const data = await getDealershipPayment(from, to)
            return data.data;
        },
        placeholderData: keepPreviousData,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        staleTime: Infinity,
        gcTime: 1000 * 60 * 10
    })
}

// This file is loaded lazily due to the size that the excel-library makes the bundle into.
const DownloadPaymentHistory = lazy(() => import('./DownloadPaymentHistory.tsx'));

export default function ViewPaymentHistory() {
    const paymentsQuery = usePaymentHistory()

    if (paymentsQuery.status === 'pending' || !paymentsQuery.data) {
        return <ContentLoading />
    }

    return (<>
        <div className="sm:p-8 p-4 border-b flex justify-between flex-wrap gap-6">
            <div>
                <h2 className="text-2xl font-semibold text-dark-blue-hover">
                    {t("paymentHistory")}
                </h2>
                <p className="mt-1 font-medium text-[14px]">{t("content:dealer.A list of your payments made across the platform.")}</p>
            </div>
            <Suspense>
                <DownloadPaymentHistory paymentItems={paymentsQuery.data.data} />
            </Suspense>

        </div>
        <PaymentsTable query={paymentsQuery} />
    </>)
}

type Props = {
    query: ReturnType<typeof usePaymentHistory>;
}
function PaymentsTable({ query }: Props) {
    const { data: profile } = useProfile();
    const isAdmin = checkIsAdmin(profile?.data.role.value || '');
    const { data, isFetching, isPending } = query
    const payments = data!.data;
    const { month, year } = useSearch({ from: "/_protected/_dealership/dealership/payment-history" })

    const [fetchingLink, setFetchingLink] = useState(false)

    async function downloadSinglePayment(linkURI: string) {
        setFetchingLink(true)
        try {
            const res = await axios.get<{ data: { result: string } }>(linkURI);
            if (res.data.data.result) {
                const paymentLink = res.data.data.result
                window.open(paymentLink, "_blank")
            }
        } catch (error) {
            errorMessage(error)
        } finally {
            setFetchingLink(false)
        }
    }

    const route = useRouter()

    function onYearChange(e: ChangeEvent<HTMLSelectElement>) {
        route.navigate({
            to: ".",
            search: (prev) => ({ month: prev.month, year: Number(e.target.value) })
        })
    }
    function onMonthChange(e: ChangeEvent<HTMLSelectElement>) {
        route.navigate({
            to: ".",
            search: (prev) => ({ month: Number(e.target.value), year: prev.year })
        })
    }

    return (
        <Spinner loading={isFetching || isPending}>
            <div className="flow-root sm:px-8 px-3 sm:py-8 py-6">
                <div className="overflow-x-auto pb-4 max-sm:-mx-3">
                    <div className="inline-block min-w-full align-middle">
                        <table className="min-w-full divide-y divide-gray-300">
                            <thead>
                                <tr>
                                    <th scope="col" className="dmf-table-cell first">
                                        #
                                    </th>
                                    <th scope="col" className="dmf-table-cell hidden sm:table-cell">
                                        {t("status")}
                                    </th>
                                    <th scope="col" className="dmf-table-cell hidden sm:table-cell">
                                        {t("date")}
                                    </th>
                                    <th scope="col" className="dmf-table-cell">
                                        {t("product")}
                                    </th>
                                    <th scope="col" className="dmf-table-cell ">
                                        {t("amount")}
                                    </th>
                                    <th scope="col" className="dmf-table-cell hidden md:table-cell">
                                        {t("type")}
                                    </th>
                                    <th scope="col" className="dmf-table-cell hidden md:table-cell">
                                        {t("externalId")}
                                    </th>
                                    <th scope="col" className="dmf-table-cell text-right">
                                        {t("viewBill")}
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                                {payments?.map((payment) => (
                                    <tr key={payment.id}>

                                        <td className="dmf-table-cell first max-sm:text-xs">
                                            {payment.relatedCase ? (
                                                <div title={payment.relatedCase.id} >
                                                    <Link to={(() => {
                                                        if (payment.relatedCase.type.value === 'import') {
                                                            return "/import/$id"
                                                        }
                                                        if (payment.relatedCase.type.value === 'leasing') {
                                                            return "/leasing/$id"
                                                        }
                                                        return "/tax/$id"
                                                    })()} className="link" params={{ id: payment.relatedCase.id }}>
                                                        {payment.relatedCase.type.name} - {' '} {payment.relatedCase.id}
                                                    </Link>
                                                </div>
                                            ) : (
                                                <p title={payment.id} className='truncate max-sm:max-w-20'>
                                                    <StatusTag value='-1' name={payment.id} />
                                                </p>
                                            )}
                                        </td>
                                        <td title={payment.status.name} className="dmf-table-cell hidden md:table-cell"><StatusTag {...payment.status} /></td>
                                        <td title={formatDate(payment.createdAt)} className="dmf-table-cell hidden sm:table-cell">{formatDate(payment.createdAt)}</td>
                                        <td title={payment.description} className="dmf-table-cell max-sm:text-xs md:max-w-64 max-w-40 truncate">{payment.description}</td>
                                        <td title={formatPrice(payment.total)} className="dmf-table-cell max-sm:text-xs">{formatPrice(payment.total)}</td>
                                        <td title={payment.paymentGateway.name} className="dmf-table-cell hidden md:table-cell"><StatusTag {...payment.paymentGateway} /></td>
                                        <td title={payment.externalId} className="dmf-table-cell hidden md:table-cell"><StatusTag value='-1' name={payment.externalId} /></td>
                                        <td title={t("get")} className="dmf-table-cell pl-3 pr-4 text-right">
                                            <Button onClick={() => downloadSinglePayment(payment.link.uri)}
                                                disabled={!isAdmin || fetchingLink}
                                                className="btn btn-text px-3"
                                            >
                                                <ArrowDownTrayIcon className='w-5 h-5' aria-label='Download' />
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>

                        </table>
                    </div>
                </div>
                <div className='mt-6 flex justify-between max-sm:flex-col gap-4'>
                    <div className='flex gap-4 text-sm'>
                        <span>
                            {t("from")}:{' '}
                            {formatDate(data?.from)}
                        </span>
                        <span>
                            {t("to")}:{' '}
                            {formatDate(data?.to, true)}
                        </span>
                    </div>
                    <div className='flex gap-4 flex-nowrap'>
                        <Select
                            className={"input pr-8 flex-auto"}
                            onChange={onYearChange}
                            value={year}
                        >
                            {dmfLifetimeInYears.map(year => (
                                <option key={year.value} value={year.value}>{year.label}</option>
                            ))}
                        </Select>
                        <Select
                            onChange={onMonthChange}
                            className={"input pr-8 flex-auto min-w-36"}
                            value={month}
                        >
                            {months.map(month => (
                                <option key={month.value} value={month.value}>{month.label}</option>
                            ))}
                        </Select>

                    </div>

                </div>
            </div>
        </Spinner>
    )
}

const months = [
    { value: 1, label: '1 - Januar' },
    { value: 2, label: '2 - Februar' },
    { value: 3, label: '3 - Marts' },
    { value: 4, label: '4 - April' },
    { value: 5, label: '5 - Maj' },
    { value: 6, label: '6 - Juni' },
    { value: 7, label: '7 - Juli' },
    { value: 8, label: '8 - August' },
    { value: 9, label: '9 - September' },
    { value: 10, label: '10 - Oktober' },
    { value: 11, label: '11 - November' },
    { value: 12, label: '12 - December' },
]

const CURRENT_YEAR = new Date().getFullYear()
const dmfLifetimeInYears: { value: number, label: number }[] = []
for (let year = 2018; year <= CURRENT_YEAR; year++) {
    dmfLifetimeInYears.push({ value: year, label: year })
}
