import { getDMFNetCredentials } from '@/services/hub';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate, useRouter } from '@tanstack/react-router';
import { useCallback } from 'react';
import { csrf, postLogin, postLogout } from '../services/auth';
import { Credentials } from '../types/auth';
import { errorMessage } from '../utils/toast';

export function useLogin() {
    const navigate = useNavigate()
    const router = useRouter()
    const login = useCallback(async (credentials: Credentials, redirect?: string) => {
        try {
            await csrf()
            await postLogin(credentials)
            loginOldApplicationInsideIframe()
            await navigate({ to: redirect || "/dashboard" })
            await router.invalidate()
        } catch (error) {
            errorMessage(error)
        }
    }, [])
    return login
}

export function useLogout() {
    const navigate = useNavigate()
    const router = useRouter()
    const queryClient = useQueryClient()
    const logout = useCallback(async () => {
        try {
            await postLogout();
            await router.invalidate()
            await navigate({ to: "/login" })
            logoutOldApplicationInsideIframe()
            queryClient.clear()
        } catch (error) {
            errorMessage(error)
        }
    }, [])
    return logout
}

export function logoutOldApplicationInsideIframe() {
    const iframe = document.createElement('iframe');
    iframe.className = "sr-only"
    iframe.onload = function () {
        setTimeout(() => iframe.remove(), 500)
    }
    iframe.src = import.meta.env.VITE_OLD_APP_URL + '/logout.html'
    document.body.appendChild(iframe)
}

async function loginOldApplicationInsideIframe() {
    const iframe = document.createElement('iframe');
    function closeIframeOnMessage(e: MessageEvent<{ completed: boolean }>) {
        if (e.origin !== import.meta.env.VITE_OLD_APP_URL) {
            return;
        }
        if (e.data.completed) {
            iframe.remove()
            window.removeEventListener('message', closeIframeOnMessage)
            document.querySelectorAll('iframe').forEach((el: HTMLIFrameElement) => { el.src = el.src })
        }
        window.isLoggingIntoDMFNet = false;
    }
    try {
        window.isLoggingIntoDMFNet = true;
        const dmfnetCredentials = getDMFNetCredentials()
        iframe.className = "sr-only"
        iframe.onload = async function () {
            /**
             * I'm doing this because i don't want to wait for the getDMFNetCredentials() Promise to resolve before loading the iframe,
             * as the iframe can sometimes take a long time to load
             */
            dmfnetCredentials.then(data => { // If promise is fulfilled, it will wait for us to run .then() on it.
                if (iframe.contentWindow) {
                    iframe.contentWindow.postMessage({ data: data.data, headers: data.headers }, "*")
                }
            })
        }

        iframe.src = import.meta.env.VITE_OLD_APP_URL + '/login-from-dmf-pro'
        document.body.appendChild(iframe)
        window.addEventListener("message", closeIframeOnMessage)

    } catch (err) {
        iframe.remove()
        window.isLoggingIntoDMFNet = false;
    }


}
