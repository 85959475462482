import Calculator from '@assets/images/calculator.svg'
import { t } from 'i18next'

export default function EmptyCalculationResult() {
    return (
        <div className="flex flex-col justify-center items-center text-center">
            <img src={Calculator} className="h-[72px] w-16 mb-8 mt-4" alt={t("calculator")} />
            <h3 className="text-gray-700 font-medium mb-1">{t("content:We_are_working_on_the_case")}</h3>
            <p className='text-[13px]'>{t("expectedWaitingTime", { timeString: "30 min" })}</p>
        </div>
    )
}
