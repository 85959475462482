import { formatDate } from "@/utils/string";
import { Button, Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/16/solid";
import clsx from "clsx";
import { t } from "i18next";
import { ChangeEvent, useEffect, useState } from "react";



type DatePickerProps = {
    selectedDate: Date | null;
    setSelectedDate: (date: Date | null) => void;
    disabled?: boolean;
    inputName?: string;
    manualInput?: boolean;
}

export default function DatePicker({ selectedDate, setSelectedDate, disabled, inputName, manualInput }: DatePickerProps) {
    const clearValue = () => setSelectedDate(null)
    const [manualValue, setManualValue] = useState(formatDate(selectedDate));
    const today = new Date();
    const [year, setYear] = useState((selectedDate && selectedDate.getFullYear()) || today.getFullYear())
    const [month, setMonth] = useState((selectedDate && selectedDate.getMonth()) || today.getMonth())
    const [view, setView] = useState<'month' | 'year' | 'decade' | 'century'>("month")

    function onValChange(e: ChangeEvent<HTMLInputElement>) {
        const parts = e.target.value.split("-");
        const manualDay = parts[0];
        const manualMonth = parts[1];
        const manualYear = parts[2];
        if (/--/.test(e.target.value)) return;
        if (/^(?:[^-]*-){3}$/.test(e.target.value)) return;
        if (manualDay && manualDay.length > 2 || parseInt(manualDay) > 31) return;
        if (manualMonth && manualMonth.length > 2 || parseInt(manualMonth) > 12) return;
        if (manualYear && manualYear.length > 4) return;
        if (/^[\d-]*$/.test(e.target.value)) {
            setManualValue(e.target.value) // make sure its empty or digits or dash (-)
        }

        const dateRegex = /^\d{1,2}(?:-\d{1,2}(?:-\d{4})?)?$/     // MATCHES: 24 OR 24-12 OR 24-12-2024
        if (!dateRegex.test(e.target.value)) return;

        if (manualMonth) setMonth(parseInt(manualMonth) - 1)
        if (manualYear) setYear(parseInt(manualYear))
        if (manualDay && manualMonth && manualYear) {
            setSelectedDate(new Date(parseInt(manualYear), parseInt(manualMonth) - 1, parseInt(manualDay), 13))
        }
    }

    useEffect(() => {
        if (selectedDate) {
            setManualValue(formatDate(selectedDate))
            setYear(selectedDate.getFullYear())
            setMonth(selectedDate.getMonth())
        } else {
            setManualValue('');
            setYear(today.getFullYear())
            setMonth(today.getMonth())
        }
    }, [selectedDate])

    return (
        <Popover className="relative">
            {({ close }) => (<>
                <div className="w-full relative">
                    {(inputName && !manualInput) && (
                        <input type="text" name="date" readOnly value={selectedDate ? selectedDate.toISOString() : ""} className="sr-only" />
                    )}
                    <span className="input-label-inset">{t("firstRegDate")} *</span>

                    {manualInput ? (
                        <PopoverButton
                            className="input-inset focus:bg-primary/5 "
                            disabled={disabled}
                            as={"input"}
                            type={"text"}
                            placeholder="dd-mm-yyyy"
                            value={manualValue}
                            onChange={onValChange}
                        />
                    ) : (
                        <PopoverButton
                            className="px-3 pb-2 pt-5 min-w-40 text-left rounded-sm border border-gray-300 w-full text-sm font-medium text-dark-blue input-hide-arrows bg-white h-12 data-[disabled]:opacity-50 data-[disabled]:bg-transparent "
                            disabled={disabled}
                        >
                            {selectedDate ? formatDate(selectedDate) : "Vælg dato"}
                        </PopoverButton>

                    )}
                    <Button disabled={disabled} className="absolute btn btn-text top-0 h-full right-0" onClick={clearValue}>
                        <XMarkIcon className="size-4" />
                    </Button>
                </div>
                <PopoverPanel
                    anchor={{
                        gap: 6,
                        offset: 0,
                        padding: 14,
                        to: "bottom start"
                    }}

                    transition
                    className="shadow-md p-1 rounded-sm bg-white transition ease-out data-[closed]:scale-90 data-[closed]:opacity-0 overflow-visible"
                >
                    <CalendarView close={() => { close(); }}
                        selectedDate={selectedDate}
                        setSelectedDate={setSelectedDate}
                        year={year} setYear={setYear}
                        month={month} setMonth={setMonth}
                        view={view} setView={setView}
                    />
                </PopoverPanel>
            </>)}
        </Popover>
    );
}

type CalendarViewProps = {
    selectedDate: Date | null;
    setSelectedDate: (date: Date | null) => void;
    close: () => void;
    year: number;
    month: number;
    view: "month" | "year" | "decade" | "century"
    setYear: React.Dispatch<React.SetStateAction<number>>
    setMonth: React.Dispatch<React.SetStateAction<number>>;
    setView: React.Dispatch<React.SetStateAction<"month" | "year" | "decade" | "century">>
}
function CalendarView({ selectedDate, setSelectedDate, close, month, setMonth, setYear, year, setView, view }: CalendarViewProps) {

    function drillUp() {
        if (view === 'month') {
            setView('year')
        } else if (view === 'year') {
            setView('decade')
        } else if (view === 'decade') {
            setView('century')
        }
    }
    function drillDown() {
        if (view === 'century') {
            setView('decade')
        } else if (view === 'decade') {
            setView('year')
        } else if (view === 'year') {
            setView('month')
        }
    }
    if (view === 'month') {
        return (
            <MonthView close={close} selectedDate={selectedDate} setSelectedDate={setSelectedDate} drillUp={drillUp} month={month} setMonth={setMonth} year={year} setYear={setYear} />
        )
    }
    if (view === 'year') {
        return <YearView selectedDate={selectedDate} drillUp={drillUp} drillDrown={drillDown} year={year} setYear={setYear} month={month} setMonth={setMonth} />
    }
    if (view === 'decade') {
        return <DecadeView selectedDate={selectedDate} drillUp={drillUp} drillDrown={drillDown} year={year} setYear={setYear} />
    }
    if (view === 'century') {
        return <CenturyView selectedDate={selectedDate} drillDrown={drillDown} year={year} setYear={setYear} />
    }
    return null;
}

function isSameDate(d1: Date, d2: Date) {
    if (d1.getDate() !== d2.getDate()) return false;
    if (d1.getMonth() !== d2.getMonth()) return false;
    if (d1.getFullYear() !== d2.getFullYear()) return false;
    return true;
}

const MONTHS = [
    "Januar",
    "Februar",
    "Marts",
    "April",
    "Maj",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "December"
]

type MonthViewProps = {
    selectedDate: Date | null;
    setSelectedDate: (date: Date | null) => void;
    year: number;
    month: number;
    setYear: React.Dispatch<React.SetStateAction<number>>
    setMonth: React.Dispatch<React.SetStateAction<number>>
    close: () => void;
    drillUp: () => void;
}
function MonthView({ close, selectedDate, setSelectedDate, drillUp, month, setMonth, setYear, year }: MonthViewProps) {
    const today = new Date();
    function goToPrevMonth() {
        let nextMonth = month - 1;
        if (nextMonth < 0) {
            nextMonth = 11;
            goToPrevYear()
        }
        setMonth(nextMonth)
    }
    function goToNextMonth() {
        let nextMonth = month + 1;
        if (nextMonth > 11) {
            nextMonth = 0;
            goToNextYear()
        }
        setMonth(nextMonth)

    }
    function goToNextYear() {
        setYear(y => y + 1)
    }
    function goToPrevYear() {
        setYear(y => y - 1)
    }
    function handleDateClick(m: number, day: number) {
        setSelectedDate(new Date(year, m, day, 13))
        setMonth(m);
        close()
    }

    const daysOfWeek = ["Søndag", "Mandag", "Tirsdag", "Onsdag", "Tordag", "Fredag", "Lørdag"];
    // Get the first day of the month (0 = Sunday, 1 = Monday, etc.)
    const firstDayOfMonth = new Date(year, month, 1).getDay();
    const lastDayOfMonth = new Date(year, month + 1, 0).getDay();

    // Get the total days in the current month
    const _daysInMonth = new Date(year, month + 1, 0).getDate(); // next month - 1 day
    const _daysInPrevMonth = new Date(year, month, 0).getDate()

    // Generate an array of days in the current month
    const days = Array.from({ length: _daysInMonth }, (_, i) => i + 1);

    // Add placeholders for the empty days before the first day of the month
    const leadingEmptyDays = Array.from({ length: firstDayOfMonth }, (_, i) => _daysInPrevMonth - i).reverse();
    const trailingEmptyDays = Array.from({ length: 6 - lastDayOfMonth }, (_, i) => i + 1);

    return (
        <>
            <div className="flex gap-1">
                <button className="btn size-9 p-0 btn-gray flex-0" onClick={goToPrevYear}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="size-4">
                        <path fillRule="evenodd" d="M4.72 9.47a.75.75 0 0 0 0 1.06l4.25 4.25a.75.75 0 1 0 1.06-1.06L6.31 10l3.72-3.72a.75.75 0 1 0-1.06-1.06L4.72 9.47Zm9.25-4.25L9.72 9.47a.75.75 0 0 0 0 1.06l4.25 4.25a.75.75 0 1 0 1.06-1.06L11.31 10l3.72-3.72a.75.75 0 0 0-1.06-1.06Z" clipRule="evenodd" />
                    </svg>
                </button>
                <button className="btn size-9 p-0 btn-gray flex-0" onClick={goToPrevMonth}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="size-4">
                        <path fillRule="evenodd" d="M11.78 5.22a.75.75 0 0 1 0 1.06L8.06 10l3.72 3.72a.75.75 0 1 1-1.06 1.06l-4.25-4.25a.75.75 0 0 1 0-1.06l4.25-4.25a.75.75 0 0 1 1.06 0Z" clipRule="evenodd" />
                    </svg>
                </button>
                <button className="btn btn-gray flex-1" onClick={drillUp}>
                    {MONTHS[month]?.substring(0, 3)} {' '} {year}
                </button>
                <button className="btn size-9 p-0 btn-gray flex-0" onClick={goToNextMonth}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="size-4">
                        <path fillRule="evenodd" d="M8.22 5.22a.75.75 0 0 1 1.06 0l4.25 4.25a.75.75 0 0 1 0 1.06l-4.25 4.25a.75.75 0 0 1-1.06-1.06L11.94 10 8.22 6.28a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" />
                    </svg>
                </button>
                <button className="btn size-9 p-0 btn-gray flex-0" onClick={goToNextYear}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="size-4">
                        <path fillRule="evenodd" d="M15.28 9.47a.75.75 0 0 1 0 1.06l-4.25 4.25a.75.75 0 1 1-1.06-1.06L13.69 10 9.97 6.28a.75.75 0 0 1 1.06-1.06l4.25 4.25ZM6.03 5.22l4.25 4.25a.75.75 0 0 1 0 1.06l-4.25 4.25a.75.75 0 0 1-1.06-1.06L8.69 10 4.97 6.28a.75.75 0 0 1 1.06-1.06Z" clipRule="evenodd" />
                    </svg>
                </button>
            </div>
            <div className="grid grid-cols-7 mt-3">
                {daysOfWeek.map((day, index) => (
                    <span key={day + "_" + index} title={day} className="text-sm font-medium text-center underline decoration-dashed underline-offset-2">
                        {day.substring(0, 3)}
                    </span>
                ))}
            </div>
            <div className="grid grid-cols-7 gap-1 mt-2">
                {leadingEmptyDays.map((day, idx) => (
                    <button
                        key={idx}
                        className={clsx(
                            "btn size-9 p-0 text-sm font-medium text-text-secondary rounded-md hover:bg-primary hover:text-white",
                            selectedDate && isSameDate(new Date(year, month - 1, day), selectedDate) && "bg-primary-hover/50 text-white"
                        )}
                        onClick={() => handleDateClick(month - 1, day)}
                    >
                        {day}
                    </button>
                ))}
                {days.map((day, idx) => (
                    <button
                        key={idx}
                        className={clsx(
                            "btn size-9 p-0 text-sm font-medium bg-gray-100 rounded-md hover:bg-primary hover:text-white",
                            day && isSameDate(new Date(year, month, day), today) && "bg-gray-500 text-white",
                            selectedDate && isSameDate(new Date(year, month, day), selectedDate) && "bg-primary-hover text-white"
                        )}
                        onClick={() => handleDateClick(month, day)}
                    >
                        {day}
                    </button>
                ))}
                {trailingEmptyDays.map((day, idx) => (
                    <button
                        key={idx}
                        className={clsx(
                            "btn size-9 p-0 text-sm font-medium text-text-secondary rounded-md hover:bg-primary hover:text-white",
                            selectedDate && isSameDate(new Date(year, month + 1, day), selectedDate) && "bg-primary-hover/50 text-white"
                        )}
                        onClick={() => handleDateClick(month + 1, day)}
                    >
                        {day}
                    </button>
                ))}
            </div>
        </>
    )
}


type YearViewProps = {
    selectedDate: Date | null;
    drillUp: () => void;
    drillDrown: () => void;
    year: number;
    setYear: React.Dispatch<React.SetStateAction<number>>
    month: number;
    setMonth: React.Dispatch<React.SetStateAction<number>>
}
function YearView({ selectedDate, drillUp, drillDrown, setYear, year, setMonth }: YearViewProps) {
    const today = new Date();
    function goToNextYear() {
        setYear(y => y + 1)
    }
    function goToPrevYear() {
        setYear(y => y - 1)
    }
    function goToNext10Year() {
        setYear(y => y + 10)
    }
    function goToPrev10Year() {
        setYear(y => y - 10)
    }

    function handleMonthClick(m: number) {
        setMonth(m)
        drillDrown()
    }

    return (
        <>
            <div className="flex gap-1">
                <button className="btn size-9 p-0 btn-gray flex-0" onClick={goToPrev10Year}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="size-4">
                        <path fillRule="evenodd" d="M4.72 9.47a.75.75 0 0 0 0 1.06l4.25 4.25a.75.75 0 1 0 1.06-1.06L6.31 10l3.72-3.72a.75.75 0 1 0-1.06-1.06L4.72 9.47Zm9.25-4.25L9.72 9.47a.75.75 0 0 0 0 1.06l4.25 4.25a.75.75 0 1 0 1.06-1.06L11.31 10l3.72-3.72a.75.75 0 0 0-1.06-1.06Z" clipRule="evenodd" />
                    </svg>
                </button>
                <button className="btn size-9 p-0 btn-gray flex-0" onClick={goToPrevYear}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="size-4">
                        <path fillRule="evenodd" d="M11.78 5.22a.75.75 0 0 1 0 1.06L8.06 10l3.72 3.72a.75.75 0 1 1-1.06 1.06l-4.25-4.25a.75.75 0 0 1 0-1.06l4.25-4.25a.75.75 0 0 1 1.06 0Z" clipRule="evenodd" />
                    </svg>
                </button>
                <button className="btn btn-gray flex-1" onClick={drillUp}>
                    {year}
                </button>
                <button className="btn size-9 p-0 btn-gray flex-0" onClick={goToNextYear}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="size-4">
                        <path fillRule="evenodd" d="M8.22 5.22a.75.75 0 0 1 1.06 0l4.25 4.25a.75.75 0 0 1 0 1.06l-4.25 4.25a.75.75 0 0 1-1.06-1.06L11.94 10 8.22 6.28a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" />
                    </svg>
                </button>
                <button className="btn size-9 p-0 btn-gray flex-0" onClick={goToNext10Year}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="size-4">
                        <path fillRule="evenodd" d="M15.28 9.47a.75.75 0 0 1 0 1.06l-4.25 4.25a.75.75 0 1 1-1.06-1.06L13.69 10 9.97 6.28a.75.75 0 0 1 1.06-1.06l4.25 4.25ZM6.03 5.22l4.25 4.25a.75.75 0 0 1 0 1.06l-4.25 4.25a.75.75 0 0 1-1.06-1.06L8.69 10 4.97 6.28a.75.75 0 0 1 1.06-1.06Z" clipRule="evenodd" />
                    </svg>
                </button>
            </div>
            <div className="grid grid-cols-3 gap-1 mt-2">
                {MONTHS.map((day, idx) => (
                    <button
                        key={idx}
                        className={clsx(
                            "btn text-sm font-medium text-gray-700 bg-gray-200 rounded-md hover:bg-primary hover:text-white",
                            (idx === today.getMonth() && year === today.getFullYear()) && "bg-gray-500 text-white",
                            selectedDate && (selectedDate.getMonth() === idx && year === selectedDate.getFullYear()) && "bg-primary-hover text-white"
                        )}
                        onClick={() => handleMonthClick(idx)}
                    >
                        {day}
                    </button>
                ))}
            </div>
        </>
    )
}


type DecadeViewProps = {
    selectedDate: Date | null;
    drillUp: () => void;
    drillDrown: () => void;
    year: number;
    setYear: React.Dispatch<React.SetStateAction<number>>
}
function DecadeView({ selectedDate, drillUp, drillDrown, setYear, year }: DecadeViewProps) {
    const today = new Date();
    function goToNextDecade() {
        setYear(y => y + 10)
    }
    function goToPrevDecade() {
        setYear(y => y - 10)
    }
    function goToNextCentury() {
        setYear(y => y + 100)
    }
    function goToPrevCentury() {
        setYear(y => y - 100)
    }

    function handleYearClick(y: number) {
        setYear(y)
        drillDrown()
    }

    function getYearRange() {
        const range: number[] = [];
        const upperRange = Math.ceil(year / 10) * 10
        for (let i = 0; i < 10; i++) {
            range.push(upperRange - i)
        }
        return range.reverse()
    }
    const yearRange = getYearRange()
    const yearStart = yearRange[0]; // 1941
    const yearEnd = yearRange[yearRange.length - 1]; // 1950


    return (
        <>
            <div className="flex gap-1">
                <button className="btn size-9 p-0 btn-gray flex-0" onClick={goToPrevCentury}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="size-4">
                        <path fillRule="evenodd" d="M4.72 9.47a.75.75 0 0 0 0 1.06l4.25 4.25a.75.75 0 1 0 1.06-1.06L6.31 10l3.72-3.72a.75.75 0 1 0-1.06-1.06L4.72 9.47Zm9.25-4.25L9.72 9.47a.75.75 0 0 0 0 1.06l4.25 4.25a.75.75 0 1 0 1.06-1.06L11.31 10l3.72-3.72a.75.75 0 0 0-1.06-1.06Z" clipRule="evenodd" />
                    </svg>
                </button>
                <button className="btn size-9 p-0 btn-gray flex-0" onClick={goToPrevDecade}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="size-4">
                        <path fillRule="evenodd" d="M11.78 5.22a.75.75 0 0 1 0 1.06L8.06 10l3.72 3.72a.75.75 0 1 1-1.06 1.06l-4.25-4.25a.75.75 0 0 1 0-1.06l4.25-4.25a.75.75 0 0 1 1.06 0Z" clipRule="evenodd" />
                    </svg>
                </button>
                <button className="btn btn-gray flex-1" onClick={drillUp}>
                    {yearStart}{' - '}{yearEnd}
                </button>
                <button className="btn size-9 p-0 btn-gray flex-0" onClick={goToNextDecade}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="size-4">
                        <path fillRule="evenodd" d="M8.22 5.22a.75.75 0 0 1 1.06 0l4.25 4.25a.75.75 0 0 1 0 1.06l-4.25 4.25a.75.75 0 0 1-1.06-1.06L11.94 10 8.22 6.28a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" />
                    </svg>
                </button>
                <button className="btn size-9 p-0 btn-gray flex-0" onClick={goToNextCentury}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="size-4">
                        <path fillRule="evenodd" d="M15.28 9.47a.75.75 0 0 1 0 1.06l-4.25 4.25a.75.75 0 1 1-1.06-1.06L13.69 10 9.97 6.28a.75.75 0 0 1 1.06-1.06l4.25 4.25ZM6.03 5.22l4.25 4.25a.75.75 0 0 1 0 1.06l-4.25 4.25a.75.75 0 0 1-1.06-1.06L8.69 10 4.97 6.28a.75.75 0 0 1 1.06-1.06Z" clipRule="evenodd" />
                    </svg>
                </button>
            </div>
            <div className="grid grid-cols-3 gap-1 mt-2">
                {yearRange.map((yearTile, idx) => (
                    <button
                        key={idx}
                        className={clsx(
                            "btn text-sm font-medium text-gray-700 bg-gray-200 rounded-md hover:bg-primary hover:text-white",
                            today.getFullYear() === yearTile && "bg-gray-400",
                            selectedDate && selectedDate.getFullYear() === yearTile && "bg-primary-hover text-white"
                        )}
                        onClick={() => handleYearClick(yearTile)}
                    >
                        {yearTile}
                    </button>
                ))}
            </div>
        </>
    )
}

type CenturyViewProps = {
    selectedDate: Date | null;
    drillDrown: () => void;
    year: number;
    setYear: React.Dispatch<React.SetStateAction<number>>
}
function CenturyView({ selectedDate, drillDrown, setYear, year }: CenturyViewProps) {
    const today = new Date();
    function goToNextCentury() {
        setYear(y => y + 100)
    }
    function goToPrevCentury() {
        setYear(y => y - 100)
    }

    function handleYearClick(y: number) {
        setYear(y)
        drillDrown()
    }

    function getYearRange() {
        const range = [];
        const upperRange = Math.ceil(year / 100) * 100
        for (let i = 0; i < 100; i += 10) {
            range.unshift({
                value: upperRange - i,
                label: `${upperRange - i - 9} - ${upperRange - i}`
            })
        }
        return range
    }
    const yearRange = getYearRange()
    const yearStart = (Math.ceil((year - 100) / 100) * 100) + 1; // 1901
    const yearEnd = Math.ceil(year / 100) * 100; // 2000
    function isSameDecade(y1: number, y2: number) {
        return (Math.ceil(y1 / 10) * 10) === (Math.ceil(y2 / 10) * 10);
    }
    return (
        <>
            <div className="flex gap-1">
                <button className="btn size-9 p-0 btn-gray flex-0" onClick={goToPrevCentury}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="size-4">
                        <path fillRule="evenodd" d="M11.78 5.22a.75.75 0 0 1 0 1.06L8.06 10l3.72 3.72a.75.75 0 1 1-1.06 1.06l-4.25-4.25a.75.75 0 0 1 0-1.06l4.25-4.25a.75.75 0 0 1 1.06 0Z" clipRule="evenodd" />
                    </svg>
                </button>
                <button className="btn btn-gray flex-1 " disabled>
                    {yearStart}{' - '}{yearEnd}
                </button>
                <button className="btn size-9 p-0 btn-gray flex-0" onClick={goToNextCentury}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="size-4">
                        <path fillRule="evenodd" d="M8.22 5.22a.75.75 0 0 1 1.06 0l4.25 4.25a.75.75 0 0 1 0 1.06l-4.25 4.25a.75.75 0 0 1-1.06-1.06L11.94 10 8.22 6.28a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" />
                    </svg>
                </button>
            </div>
            <div className="grid grid-cols-3 gap-1 mt-2">
                {yearRange.map((yearTile, idx) => (
                    <button
                        key={idx}
                        className={clsx(
                            "btn text-sm font-medium text-gray-700 bg-gray-200 rounded-md hover:bg-primary hover:text-white",
                            isSameDecade(today.getFullYear(), yearTile.value) && "bg-gray-400",
                            selectedDate && isSameDecade(selectedDate.getFullYear(), yearTile.value) && "bg-primary-hover text-white"
                        )}
                        onClick={() => handleYearClick(yearTile.value)}
                    >
                        {yearTile.label}
                    </button>
                ))}
            </div>
        </>
    )
}

