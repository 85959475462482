import { queryOptions, useQuery } from "@tanstack/react-query";
import { getStripeCustomerPortalUrl, getStripeSessionId, getSubscriptionPortalPackages } from "../services/stripe";

export const customerPortalUrlQueryOptions = (...args: Parameters<typeof getStripeCustomerPortalUrl>) =>
    queryOptions({
        queryKey: ['customerPortalURL'],
        queryFn: async () => {
            const data = await getStripeCustomerPortalUrl(...args);
            return data.data;
        },
    })

export const useStripeSessionIdQuery = () => {
    return useQuery({
        queryKey: ['stripeSessionId'],
        queryFn: async () => {
            const data = await getStripeSessionId();
            return data.data;
        },
    })
}

export const useSubscriptionPackages = () => {
    return useQuery({
        queryKey: ['stripeSessionId'],
        queryFn: async () => {
            const data = await getSubscriptionPortalPackages();
            return data.data.data;
        },
    })
}

