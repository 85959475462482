
type Props = React.SVGProps<SVGSVGElement>
export default function IMPORTIcon(props: Props) {
    return (
        <svg width="32" height="33" viewBox="0 0 32 33" {...props} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M22.9333 13.8333H9.06663C6.82642 13.8333 5.70631 13.8333 4.85066 14.2692C4.09802 14.6527 3.48609 15.2646 3.1026 16.0173C2.66663 16.8729 2.66663 17.993 2.66663 20.2333V23.8333C2.66663 24.4528 2.66663 24.7626 2.71787 25.0202C2.92828 26.078 3.75521 26.9049 4.81305 27.1153C5.07065 27.1666 6.04709 27.1666 6.66663 27.1666H7.33329C8.80605 27.1666 9.99996 25.9727 9.99996 24.4999C9.99996 24.1317 10.2984 23.8333 10.6666 23.8333H21.3333C21.7015 23.8333 22 24.1317 22 24.4999C22 25.9727 23.1939 27.1666 24.6666 27.1666H25.3333C25.9528 27.1666 26.9293 27.1666 27.1869 27.1153C28.2447 26.9049 29.0716 26.078 29.2821 25.0202C29.3333 24.7626 29.3333 24.4528 29.3333 23.8333V20.2333C29.3333 17.993 29.3333 16.8729 28.8973 16.0173C28.5138 15.2646 27.9019 14.6527 27.1493 14.2692C26.2936 13.8333 25.1735 13.8333 22.9333 13.8333Z"
                fill="currentColor"
                className="fill-opacity-duotone"
                fillOpacity="0.2"
            />
            <path
                d="M6.66666 17.8333H9.33332M3.99996 11.8333L5.99996 13.8333L7.69415 8.75068C8.04385 7.70159 8.2187 7.17704 8.54301 6.78923C8.8294 6.44676 9.19718 6.18168 9.61263 6.01829C10.0831 5.83325 10.636 5.83325 11.7419 5.83325H20.2581C21.3639 5.83325 21.9168 5.83325 22.3873 6.01829C22.8027 6.18168 23.1705 6.44676 23.4569 6.78923C23.7812 7.17704 23.9561 7.70159 24.3058 8.75068L26 13.8333L28.6667 11.8333M22.6667 17.8333H25.3333M9.06663 13.8333H22.9333C25.1735 13.8333 26.2936 13.8333 27.1493 14.2692C27.9019 14.6527 28.5138 15.2646 28.8973 16.0173C29.3333 16.8729 29.3333 17.993 29.3333 20.2333V23.8333C29.3333 24.4528 29.3333 24.7626 29.2821 25.0202C29.0716 26.078 28.2447 26.9049 27.1869 27.1153C26.9293 27.1666 25.9528 27.1666 25.3333 27.1666H24.6666C23.1939 27.1666 22 25.9727 22 24.4999C22 24.1317 21.7015 23.8333 21.3333 23.8333H10.6666C10.2984 23.8333 9.99996 24.1317 9.99996 24.4999C9.99996 25.9727 8.80605 27.1666 7.33329 27.1666H6.66663C6.04709 27.1666 5.07065 27.1666 4.81305 27.1153C3.75521 26.9049 2.92828 26.078 2.71787 25.0202C2.66663 24.7626 2.66663 24.4528 2.66663 23.8333V20.2333C2.66663 17.993 2.66663 16.8729 3.1026 16.0173C3.48609 15.2646 4.09802 14.6527 4.85066 14.2692C5.70631 13.8333 6.82642 13.8333 9.06663 13.8333Z"
                stroke="currentColor"
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
