import { useEffect, useState } from "react"

export function useLocalStorage<T>(key: string, initialValue: T) {
    const state = useState<T>(() => {
        const stored = window.localStorage.getItem(key)
        return stored ? JSON.parse(stored) : initialValue
    })

    useEffect(() => {
        window.localStorage.setItem(key, JSON.stringify(state[0]))
    }, [state[0]])

    return state
}
