import { Dialog, DialogBackdrop, DialogPanel, TransitionChild } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { ReactNode } from '@tanstack/react-router';
import clsx from 'clsx';

type Props = {
    open: boolean;
    setOpen: (open: boolean) => void;
    children: ReactNode;
    size?: "sm" | "md" | "lg";
    transitionDuration?: "300" | "500";
    unmount?: boolean;
}
export default function BasicSlideOver({ open, setOpen, children, size, transitionDuration, unmount }: Props) {

    return (
        <Dialog className="relative z-40" open={open} onClose={setOpen} unmount={unmount}>
            <DialogBackdrop
                transition
                className={clsx("fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity ease-in-out data-[closed]:opacity-0", `duration-${transitionDuration ?? "500"}`)}
            />

            <div className="fixed inset-0 overflow-hidden">
                <div className="absolute inset-0 overflow-hidden">
                    <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                        <DialogPanel transition
                            className={clsx("pointer-events-auto relative w-screen flex",
                                `duration-${transitionDuration ?? "500"}`,
                                size === 'lg' && "max-w-3xl",
                                size === 'md' && "max-w-xl",
                                (size === 'sm' || !size) && "max-w-md",
                                'data-[closed]:translate-x-full'
                            )}
                        >
                            <TransitionChild unmount={unmount}>
                                <div className={clsx("absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4 ease-in-out data-[closed]:opacity-0",
                                    `duration-${transitionDuration ?? "500"}`
                                )}>
                                    <button
                                        type="button"
                                        className="relative rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                        onClick={() => setOpen(false)}
                                    >
                                        <span className="absolute -inset-2.5" />
                                        <span className="sr-only">Luk panel</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                            </TransitionChild>

                            {children}

                        </DialogPanel>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}
