import { Link } from "@tanstack/react-router";
import { t } from "i18next";

export default function NotFound() {
    return (
        <>
            <section className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
                <div className="text-center">
                    <p className="text-base font-semibold text-primary">404</p>
                    <h1 className="mt-4 text-3xl font-bold tracking-tight text-dark-blue-hover sm:text-5xl">{t("status:404.Page not found")}</h1>
                    <p className="mt-6 text-base leading-7">{t("status:404.Sorry, we couldn't find the page you're looking for.")}</p>
                    <div className="mt-10 flex items-center justify-center gap-x-6">
                        <Link
                            to="/dashboard"
                            className="btn btn-primary"
                        >
                            Dashboard
                        </Link>
                        <Link to="/dealership" className="text-sm font-semibold text-gray-900">
                            Forhandler <span aria-hidden="true">&rarr;</span>
                        </Link>
                        <Link to="/tax" search={{ page: 1 }} className="text-sm font-semibold text-gray-900">
                            TAX <span aria-hidden="true">&rarr;</span>
                        </Link>
                    </div>
                </div>
            </section>
        </>
    )
}
