import IFrameOfOldEmbeddedFrontend from '@/features/IFrameOfOldEmbeddedFrontend';
import { createFileRoute, useParams } from '@tanstack/react-router';

export const Route = createFileRoute('/_protected/_import/import/$id')({
    meta: ({ params }) => [{ title: `IMPORT · ${params.id}` }],
    component: ImportComponentOuter
})

const url = import.meta.env.VITE_OLD_APP_URL + '/bilimport';

function ImportComponentOuter() {
    const params = useParams({ from: "/_protected/_import/import/$id" })
    return <IFrameOfOldEmbeddedFrontend src={`${url}/${params.id}`} />
}
