import { Button } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { isAxiosError } from "axios";
import { t } from "i18next";
import toast from "react-hot-toast";

export const successMessage = (message: string) => {
    toast.success((t) => <Message message={message} toastId={t.id} />)
}

export const loadingMessage = (message: string) => {
    toast.loading((t) => <Message message={message} toastId={t.id} />)
}

export const errorMessage = (error: unknown) => {
    let message = t("status:error.unknownError");

    if (isAxiosError(error)) {
        message = error.response?.data?.message
    } else if (error instanceof Error) {
        message = error.message;
    } else if (typeof error === 'string') {
        message = error;
    }

    toast.error((t) => <Message message={message} toastId={t.id} />)
}

type MessageProps = {
    message: string;
    toastId: string;
}

function Message({ message, toastId }: MessageProps) {
    return (
        <div className="flex items-center gap-2 justify-between w-full">
            <span>
                {message}
            </span>
            <Button onClick={() => toast.dismiss(toastId)}>
                <XMarkIcon className="size-5" aria-label={t("close")} />
            </Button>
        </div>
    )
}
