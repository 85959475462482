import EquipmentIcon from '@/assets/icons/CarDataIcon';
import DashboardIcon from '@/assets/icons/DashboardIcon';
import IMPORTIcon from '@/assets/icons/IMPORTIcon';
import LEASINGIcon from '@/assets/icons/LEASINGIcon';
import TAXIcon from '@/assets/icons/TAXIcon';
import { Spinner } from '@/components/Spinner';
import SubLogo from '@/components/SubLogo';
import { companyConfig } from '@/config';
import CookieDeclarationButton from '@/features/CookieDeclaration';
import FloatingShortcutButtons from '@/features/FloatingShortcuts';
import MSSurveyForm from '@/features/MSSurveyForm';
import { useLogout } from '@/hooks/useAuth';
import { getUser } from '@/services/auth';
import { contactSupport, searchSystem } from '@/services/hub';
import { ReactFormSubmit } from '@/types/helperTypes';
import { formatPhone } from '@/utils/string';
import { debounce, raise } from '@/utils/utils';
import DMFPROLogoWhite from '@assets/DMF-Logo/DMFPRO/Horizontal/dmf-white2x.png';
import DMFPROIconLight from '@assets/DMF-Logo/DMFPRO/Vertical/DMF-V-Color.png';
import Avatar from '@components/Avatar';
import ContentLoading from '@components/ContentLoading';
import NotFound from '@components/NotFound';
import { Button, Dialog, DialogPanel, Listbox, ListboxButton, ListboxOption, ListboxOptions, Menu, MenuButton, MenuHeading, MenuItem, MenuItems, MenuSection, MenuSeparator, Textarea, Transition, TransitionChild } from '@headlessui/react';
import {
    ArrowPathIcon,
    ArrowRightStartOnRectangleIcon,
    AtSymbolIcon,
    Bars3Icon,
    BuildingOfficeIcon,
    CheckIcon,
    ChevronDownIcon,
    EnvelopeIcon,
    MagnifyingGlassIcon,
    PhoneIcon,
    TicketIcon,
    UserIcon,
    XMarkIcon
} from '@heroicons/react/24/outline';
import { useProfile } from '@hooks/useProfile';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Link, Outlet, createFileRoute, redirect, useLoaderData, useLocation, useRouterState } from '@tanstack/react-router';
import { errorMessage, successMessage } from '@utils/toast';
import clsx from 'clsx';
import { t } from 'i18next';
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';

// This "cachedUser" provides a poor-mans debouncing solution to make sure that the application
// does not spam the backend with constant "whoAmI" requests.
// Also, there is a "useUser" hook that is not used anywhere?
let cachedUser: number | null = null;
const cacheTimeoutMs = 60 * 1000;

export const Route = createFileRoute('/_protected')({
    pendingComponent: ContentLoading,
    notFoundComponent: NotFound,
    loader: async ({ location }) => {
        try {
            const data = await getUser()
            if (!data) {
                throw "redirect to login"
            }

            cachedUser = window.setTimeout(() => cachedUser = null, cacheTimeoutMs);

            return {
                // Do the Data Dance
                user: data.data.data
            }
        } catch (error) {
            throw redirect({
                to: '/login',
                search: {
                    redirect: location.href,
                },
            })
        }
    },
    shouldReload: () => cachedUser === null,
    component: AuthLayout,
})


function AuthLayout() {
    let isPageWithEmbeddedIframe = false;
    // const { data: profile } = useProfile()

    const path = useLocation().pathname

    if (path.includes('/import') || path.includes('/leasing') || path.includes('/equipment')) {
        isPageWithEmbeddedIframe = true;
    }

    // sep 30 2024: Disabled Sentry feedback. Large overlap with similar feature 'Send en besked'
    // useEffect(() => {
    //     if (profile) {
    //         Sentry.setUser({
    //             fullName: profile?.data.name,
    //             email: profile?.data.email,
    //         });
    //     }
    // }, [profile])
    // useEffect(() => {
    //     Sentry.addIntegration(
    //         Sentry.feedbackIntegration({
    //             // Additional SDK configuration goes in here, for example:
    //             showBranding: true,
    //             triggerLabel: "Feedback",
    //             triggerAriaLabel: "Giv os feedback",
    //             formTitle: "Giv os feedback",
    //             nameLabel: "Navn",
    //             namePlaceholder: "Dit navn",
    //             emailLabel: "Din email",
    //             messagePlaceholder: "Fortæl os din mening",
    //             messageLabel: "Din mening",
    //             successMessageText: "Tak for din feedback",
    //             addScreenshotButtonLabel: "Tilføj screenshot",
    //             removeScreenshotButtonLabel: "Fjern screenshot",
    //             submitButtonLabel: "Send feedback",
    //             isRequiredLabel: "*",
    //             cancelButtonLabel: "Annuler",
    //             confirmButtonLabel: "Bekræft",

    //             colorScheme: "system",
    //             useSentryUser: {
    //                 name: "fullName",
    //                 email: "email",
    //             },
    //             themeLight: {
    //                 background: "#f5f7f9",
    //             },
    //             themeDark: {
    //                 background: "#082034",
    //             },
    //         })
    //     )
    // }, [])

    return (
        <>
            <div className='min-h-full flex-1 flex flex-col'>
                <AuthHeader />
                <MobileSidebar />

                <DesktopSidebar />

                <div className='lg:pl-20 flex-1 flex flex-col'>
                    <Outlet />
                    {!isPageWithEmbeddedIframe && (
                        <Footer />
                    )}
                    <FloatingShortcutButtons />

                    <MSSurveyForm />
                </div>

            </div>
        </>
    )
}


const contactContexts = [
    { id: 'tax', name: t("tax") },
    { id: 'import', name: t("import") },
    { id: 'leasing', name: t("leasing") },
    { id: 'general', name: t("general") },
] as const
function Footer() {
    const [selectedContactContext, setSelectedContactContexts] = useState(contactContexts[0])
    const mutateContact = useMutation({
        mutationFn: (args: Parameters<typeof contactSupport>) => contactSupport(...args),

    })
    async function onSubmitContact(e: ReactFormSubmit) {
        e.preventDefault()
        try {
            const message = (e.currentTarget.elements.namedItem('message') as HTMLTextAreaElement)?.value
            await mutateContact.mutateAsync([{
                type: selectedContactContext.id,
                message: message || raise(t("status:error.needMessage"))
            }])
            successMessage(t("status:success.messageSent"));
            (e.target as HTMLFormElement).reset()
        } catch (err) {
            errorMessage(err)
        }
    }

    return (
        <footer className="mt-auto p-4 flex gap-2 items-center justify-center flex-col max-sm:text-center relative">

            <ul className="flex text-sm gap-y-2 gap-x-8 max-sm:flex-col">
                <Menu>
                    {({ open, close }) => (
                        <li
                            onMouseLeave={close}
                        >
                            <MenuButton className="link font-medium cursor-pointer"
                                onMouseEnter={({ currentTarget }) => open ? "" : currentTarget.click()}
                            >
                                Kontakt os
                            </MenuButton>
                            <MenuItems modal={false} anchor="bottom" transition
                                className="transition duration-200 data-[closed]:translate-y-2 data-[closed]:opacity-0 bg-white py-1.5 rounded-md shadow-lg border border-gray-100"
                            >
                                <MenuItem>
                                    <a className="block data-[focus]:bg-gray-100 text-sm px-2 py-1" href={`tel:${companyConfig.phone.company}`}>
                                        <PhoneIcon className='size-4 inline mr-1' aria-hidden="true" />
                                        {formatPhone(companyConfig.phone.company)}
                                    </a>
                                </MenuItem>
                                <MenuItem>
                                    <a className="block data-[focus]:bg-gray-100 text-sm px-2 py-1" href={`mailto:${companyConfig.emails.info}`}>
                                        <AtSymbolIcon className='size-4 inline mr-1' aria-hidden="true" />
                                        {companyConfig.emails.info}
                                    </a>
                                </MenuItem>
                            </MenuItems>

                        </li>
                    )}
                </Menu>
                <Menu>
                    {({ open, close }) => (
                        <li
                            onMouseLeave={close}
                        >
                            <MenuButton className="link font-medium cursor-pointer"
                                onMouseEnter={({ currentTarget }) => open ? "" : currentTarget.click()}
                            >
                                Bankinfo
                            </MenuButton>
                            <MenuItems modal={false} anchor="bottom" transition
                                className="transition duration-200 data-[closed]:translate-y-2 data-[closed]:opacity-0 bg-white rounded-md shadow-lg px-2 py-2.5 border border-gray-100"
                            >
                                <MenuItem>
                                    <div className='text-sm'>
                                        <strong>{companyConfig.bankInfo.name}</strong>
                                        <p>Reg. nr. {companyConfig.bankInfo.registrationNumber}</p>
                                        <p>Kontonr. {companyConfig.bankInfo.accountNumber}</p>
                                    </div>
                                </MenuItem>
                            </MenuItems>

                        </li>
                    )}
                </Menu>
                <li><a className='link font-medium' target="_blank" href={companyConfig.links.subscriptionTerms}>Abonnementsbetingelser</a></li>
                <li className='link font-medium cursor-pointer'><CookieDeclarationButton /></li>

            </ul>
            <Menu>
                <MenuButton className="btn btn-primary max-sm:order-2 lg:absolute bottom-4 left-4">
                    <EnvelopeIcon className="size-5" aria-hidden="true" />
                    {t("sendMessage")}
                </MenuButton>
                <MenuItems modal={false} anchor="bottom start" transition
                    className="[--anchor-gap:8px] [--anchor-padding:12px] transition sm:w-[360px] max-sm:inset-x-3 duration-200 data-[closed]:translate-y-2 data-[closed]:opacity-0 bg-white rounded-md shadow-lg border border-gray-100"
                >
                    <MenuSection>
                        <MenuHeading className="text-sm px-3 py-1 font-medium text-dark-blue">{t("messageToSupport")}</MenuHeading>
                        <MenuSeparator className="h-px bg-gray-200" />
                        <div className="p-3">
                            <p className='text-sm'>{t("content:support.Send a message to our support. Your message will be sent directly to our support, with information about you and your case. We look forward to hearing from you.")}</p>
                            <MenuSeparator className="my-4 h-px bg-gray-200" />
                            <form onSubmit={onSubmitContact}>
                                <Spinner loading={mutateContact.isPending}>
                                    <Listbox name='type' value={selectedContactContext} onChange={setSelectedContactContexts}>
                                        <ListboxButton
                                            className={clsx(
                                                'input relative block w-full pr-8 text-left text-sm/6',
                                            )}
                                        >
                                            {selectedContactContext.name}
                                            <ChevronDownIcon
                                                className="pointer-events-none absolute top-2.5 right-2.5 size-4"
                                                aria-hidden="true"
                                            />
                                        </ListboxButton>
                                        <ListboxOptions
                                            anchor="bottom"
                                            transition
                                            className={clsx(
                                                'w-[var(--button-width)] rounded-md border bg-gray-50 p-1 [--anchor-gap:4px] [--anchor-padding:8px]',
                                                'transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0'
                                            )}
                                        >
                                            {contactContexts.map((context) => (
                                                <ListboxOption
                                                    key={context.name}
                                                    value={context}
                                                    className="group flex cursor-default items-center gap-2 rounded-sm px-2 py-1 select-none data-[focus]:bg-gray-100"
                                                >
                                                    <CheckIcon className="invisible size-4 fill-dark-blue group-data-[selected]:visible" />
                                                    <div className="text-sm">{context.name}</div>
                                                </ListboxOption>
                                            ))}
                                        </ListboxOptions>
                                    </Listbox>
                                    <Textarea name="message" required className="input mt-2 min-h-16" onKeyDown={e => e.stopPropagation()}></Textarea>
                                    <div className='text-right'>
                                        <Button className="btn btn-primary btn-sm mt-3" type="submit">
                                            {t("send")}
                                        </Button>
                                    </div>
                                </Spinner>
                            </form>
                        </div>
                    </MenuSection>
                </MenuItems>
            </Menu>
            <small className="whitespace-nowrap max-sm:order-3">Dansk Motor Finans A/S © 2024</small>
        </footer>
    )
}

function DesktopSidebar() {
    return (
        <div className="max-lg:hidden lg:fixed inset-y-0 z-40">
            <div className="flex lg:w-20 w-full h-full flex-col lg:items-center max-lg:px-4 bg-dark-blue-hover shadow-lg shadow-dark-blue">
                <img
                    className="h-auto w-8 mt-4 mb-8 self-center"
                    src={DMFPROIconLight}
                    alt={"DMF logo"}
                />
                <HubMenu />
            </div>
        </div >
    )
}

function MobileSidebar() {
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const location = useLocation()
    useEffect(() => {
        setSidebarOpen(false)
    }, [location.state.key])

    return (<>
        <div className='fixed top-0 z-40 lg:hidden flex items-center gap-4'>
            <Button
                type="button"
                onClick={() => setSidebarOpen(true)}
                className='btn btn-gray rounded-none bg-transparent min-w-16 h-16 px-4 flex items-center justify-center'
            >
                <span className="sr-only">Åbn sidemenu</span>
                <Bars3Icon className='size-8' />
            </Button>
            <SubLogo />
        </div>

        <Transition show={sidebarOpen}>
            <Dialog className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
                <TransitionChild>
                    <div className="fixed inset-0 bg-gray-900/80 transition-opacity duration-300 data-[closed]:opacity-0" />
                </TransitionChild>

                <div className="fixed inset-0 flex">
                    <TransitionChild>
                        <DialogPanel className="relative pr-14 flex w-full max-w-96 flex-1 transition-transform duration-300 data-[closed]:-translate-x-full">
                            <TransitionChild>
                                <div className="absolute right-0 top-0 flex w-14 justify-center pt-5 transition-opacity duration-300 data-[closed]:opacity-0">
                                    <Button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                                        <span className="sr-only">Close sidebar</span>
                                        <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                    </Button>
                                </div>
                            </TransitionChild>

                            <div className="flex w-full h-full flex-col max-lg:px-4 bg-dark-blue-hover overflow-y-auto overflow-x-clip">
                                <img
                                    className="h-8 object-contain object-left my-4"
                                    src={DMFPROLogoWhite}
                                    alt={"DMF logo"}
                                />
                                <div className='pt-4'>
                                    <SearchBar />
                                </div>
                                <HubMenu />
                                <MobileProfileNav />
                            </div>

                        </DialogPanel>
                    </TransitionChild>
                </div>
            </Dialog>
        </Transition>
    </>
    )
}

function AuthHeader() {
    const logout = useLogout()
    const { data: profile } = useProfile()
    const { user } = useLoaderData({ from: "/_protected" })
    const userName = profile?.data.name
    const avatar = profile?.data.avatar?.uri
    const initials = profile?.data.initials

    async function onLogout() {
        try {
            await logout()
        } catch (error) {
            errorMessage(error)
        }
    }
    return (

        <div className="max-lg:hidden pl-20 h-20 z-40 fixed w-full top-0 bg-white border-b flex justify-between items-center">
            <SubLogo className="pl-6" />
            <SearchBar />
            <div className="hidden lg:relative lg:z-50 lg:ml-4 pr-6 lg:flex lg:items-center">

                {/* Profile dropdown */}
                <span className='text-xs bg-primary rounded-full py-0.5 px-1.5 font-semibold text-white flex gap-1'
                    title={`${user?.credits.left} ${t("clipsLeft")}`}
                >
                    <TicketIcon className='size-5' />
                    <span>
                        {(user?.credits.left && user.credits.left > 99) ? '99+' : user?.credits?.left}
                    </span>
                </span>
                <Menu as="div" className="relative ml-2 flex-shrink-0">
                    <MenuButton className="relative flex items-center gap-2 rounded-full">
                        <span className="absolute -inset-1.5" />
                        <span className="sr-only">Open user menu</span>
                        <div className="relative">
                            <Avatar imgSrc={avatar || ''} initials={initials} size='sm' />
                        </div>
                        <span className="text-sm">{userName}</span>
                        <ChevronDownIcon className="size-4" />
                    </MenuButton>
                    <MenuItems modal={false}
                        transition
                        className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none transform-gpu transition duration-75 data-[closed]:opacity-0 data-[closed]:scale-95 data-[closed]:-translate-y-4"
                    >
                        <MenuItem >
                            <Link
                                to="/dealership/profile"
                                className="block px-4 py-2 text-sm font-medium data-[focus]:bg-gray-100 "
                                activeProps={{ className: "bg-gray-50" }}
                            >
                                {t("my") + " " + t("profile").toLowerCase()}
                            </Link>
                        </MenuItem>
                        <MenuItem >
                            <Link
                                to="/dealership"
                                className="block px-4 py-2 text-sm font-medium data-[focus]:bg-gray-100 "
                                activeProps={{ className: "bg-gray-50" }}
                                activeOptions={{ exact: true }}
                            >
                                {t("my") + " " + t("company").toLowerCase()}
                            </Link>
                        </MenuItem>
                        <MenuItem>
                            <Button
                                className="block w-full text-left px-4 py-2 text-sm font-medium data-[focus]:bg-gray-100 "
                                onClick={onLogout}
                            >

                                {t("logout")}
                            </Button>
                        </MenuItem>
                    </MenuItems>
                </Menu>
            </div>
        </div>

    )
}


function MobileProfileNav() {
    const logout = useLogout()
    const { data: profile } = useProfile()
    const { user } = useLoaderData({ from: "/_protected" })
    const userName = profile?.data.name
    const userEmail = profile?.data.email
    const avatar = profile?.data.avatar?.uri

    async function onLogout() {
        try {
            await logout()
        } catch (error) {
            errorMessage(error)
        }
    }

    return (
        <nav className="pb-4 pt-8">

            <div className="flex items-center h-16 gap-2">
                <span className="relative">
                    <Avatar
                        imgSrc={avatar}
                        size='md'
                    />
                    <span className='bg-primary rounded-full font-semibold px-1 py-0.5 text-white inline-flex items-center gap-1 absolute -bottom-2 left-1/2 -translate-x-1/2'
                        title={`${user?.credits.left} ${t("clipsLeft")}`}
                    >
                        <TicketIcon className='size-4' />
                        <span className='text-xxs'>
                            {(user?.credits.left && user.credits.left > 99) ? '99+' : user?.credits.left}
                        </span>
                    </span>
                </span>
                <div className="ml-2 flex-1 truncate mr-2">
                    <div className="text-sm font-medium text-white truncate ">{userName}</div>
                    <div className="text-xs font-medium text-text-primary truncate">{userEmail}</div>
                </div>

            </div>

            <ul role="list" className="flex flex-1 flex-col">
                <li>
                    <Link
                        to="/dealership/profile"
                        className="nav-list-button"
                    >
                        <UserIcon className='nav-list-icon' aria-hidden="true" />
                        <span className='text-white/80 group-[.current]:text-white font-semibold text-center text-sm/3 uppercase'>{t("my") + " " + t("profile").toLowerCase()}</span>
                    </Link>
                </li>
                <li>
                    <Link
                        to="/dealership"
                        className="nav-list-button"
                    >
                        <BuildingOfficeIcon className='nav-list-icon' aria-hidden="true" />
                        <span className='text-white/80 group-[.current]:text-white font-semibold text-center text-sm/3 uppercase'>{t("my") + " " + t("company").toLowerCase()}</span>
                    </Link>
                </li>
                <li>
                    <Button
                        className="nav-list-button"
                        onClick={onLogout}
                    >
                        <ArrowRightStartOnRectangleIcon className='nav-list-icon' aria-hidden="true" />
                        <span className='text-white/80 group-[.current]:text-white font-semibold text-center text-sm/3 uppercase'>{t("logout")}</span>
                    </Button>
                </li>
            </ul>
        </nav>
    )
}


function HubMenu() {
    const path = useRouterState({ select: state => state.location.pathname })
    const { user } = useLoaderData({ from: "/_protected" })
    return (
        <nav className="flex flex-1 flex-col">
            <ul role="list" className="flex flex-1 flex-col">
                <li>
                    <Link
                        to='/dashboard'
                        className={"nav-list-button group"}
                        activeProps={{ className: "current" }}
                        activeOptions={{ exact: true }}
                    >
                        <DashboardIcon />
                        <span className='text-white/80 group-[.current]:text-white font-semibold text-center text-[12px]/3 uppercase'>Dashboard</span>
                    </Link>
                </li>
                <li>
                    <Link
                        to='/tax'
                        search={{ page: 1 }}
                        className={clsx("nav-list-button group", path.includes('/tax') && "current")}
                    >
                        <TAXIcon />
                        <span className='text-white/80 group-[.current]:text-white font-semibold text-center text-sm/3 uppercase'>Tax</span>
                    </Link>
                </li>
                {(user.products.leasing || user.products.import || user.products.vehicleReport) && (
                    <hr className='my-6 border-white/10 border-t-2' />
                )}
                {user.products.leasing && (
                    <li>
                        <Link
                            to='/leasing'
                            className={clsx("nav-list-button mt-0 group", path.includes('/leasing') && "current")}
                        >
                            <LEASINGIcon aria-hidden="true" />
                            <span className='text-white/80 group-[.current]:text-white font-semibold text-center text-[12px]/3 uppercase'>Leasing</span>
                        </Link>
                    </li>
                )}
                {user.products.import && (
                    <li>
                        <Link
                            to='/import'
                            className={clsx("nav-list-button group", path.includes('/import') && "current")}
                        >
                            <IMPORTIcon aria-hidden="true" />
                            <span className='text-white/80 group-[.current]:text-white font-semibold text-center text-[12px]/3 uppercase'>Import</span>
                        </Link>
                    </li>
                )}
                {user.products.vehicleReport && (
                    <li>
                        <Link
                            to='/equipment'
                            className={clsx("nav-list-button group", path.includes('/equipment') && "current")}
                        >
                            <EquipmentIcon aria-hidden="true" />
                            <span className='text-white/80 group-[.current]:text-white font-semibold text-center text-[12px]/3 uppercase'>Udstyr</span>
                        </Link>
                    </li>
                )}

            </ul>
        </nav>
    )
}


function SearchBar() {
    const [query, setQuery] = useState("")
    const [showResults, setShowResults] = useState(false);
    const parentRef = useRef<HTMLDivElement>(null)
    const { data, isFetching } = useQuery({
        queryKey: ['globalSearch', query],
        queryFn: async () => {
            setShowResults(false)
            const data = await searchSystem(query)
            setShowResults(true)
            return data.data
        },
        enabled: !!query,
    })
    const hasData = !!data?.data.tax || !!data?.data.import || !!data?.data.leasing || !!data?.data.user;

    const doDebounceSearch = useCallback(
        debounce((e: ChangeEvent<HTMLInputElement>) =>
            setQuery(() => e.target.value)
            , 650),
        []
    )
    function onSearchClick() {
        if (hasData) {
            setShowResults(true)
        }
    }
    function onClose() {
        setShowResults(false)
    }

    useEffect(() => {
        function listenForClicksAway(e: MouseEvent | TouchEvent) {
            if (parentRef.current && parentRef.current.contains(e.target as Node)) {
                return
            }
            setShowResults(false)
        }
        window.addEventListener('mousedown', listenForClicksAway)
        window.addEventListener('touchstart', listenForClicksAway)

        return () => {
            window.removeEventListener('mousedown', listenForClicksAway)
            window.removeEventListener('touchstart', listenForClicksAway)
        }
    }, [showResults])

    useEffect(() => {
        if (hasData) {
            setShowResults(true)
        } else {
            setShowResults(false)
        }
    }, [data])

    return (
        <div className="flex-1 lg:pl-6 relative" ref={parentRef}>
            <label htmlFor="search" className="sr-only">
                {t("search")}
            </label>
            <div className="relative ">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 max-lg:text-white">
                    {isFetching ? (
                        <ArrowPathIcon className="animate-spin h-5 w-5" aria-hidden="true" />
                    ) : (
                        <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
                    )}
                </div>
                <input
                    onChange={doDebounceSearch}
                    className={clsx(
                        "block w-full rounded-md border-0 pl-10 pr-3 placeholder:text-gray-400 focus:ring-0",
                        "lg:text-sm lg:leading-10 lg:bg-gray-50",
                        "bg-white/[2%] max-lg:focus:bg-white/10 max-lg:hover:bg-white/10 max-lg:transition-colors max-lg:text-white"
                    )}
                    placeholder="Søg..."
                    type="search"
                    onClick={onSearchClick}
                />
                <Transition show={showResults}>
                    <nav className={clsx(
                        'z-50 border rounded-md p-1 backdrop-blur-2xl',
                        "lg:absolute lg:bg-white lg:top-full lg:w-full lg:max-w-lg",
                        "bg-white/20 fixed max-lg:top-[156px] max-lg:bottom-4 max-lg:inset-x-4 max-lg:border-white/30"
                    )}>
                        <Button className="fixed right-1 top-1 btn btn-gray size-12 p-0"
                            onClick={onClose}
                            aria-label={t("close")}
                        >
                            <XMarkIcon className='size-5' aria-hidden="true" />
                        </Button>

                        <ul className="flex flex-col overflow-y-auto min-h-20 lg:max-h-96">
                            <li className={clsx(window.location.pathname.includes("tax") ? "order-first" : "order-1")}>
                                <ul>
                                    {data?.data?.tax?.map((link, i) => (
                                        <li key={i}>
                                            <a href={link.uri}
                                                className="block hover:bg-gray-100/50 lg:focus:bg-gray-100 focus:bg-gray-100/50 px-3 py-1.5 rounded-sm text-xs max-lg:text-white/75"
                                            >
                                                TAX - {link.resourceId} - {link.name}
                                            </a>

                                        </li>
                                    ))}

                                </ul>
                            </li>
                            <li className={clsx(window.location.pathname.includes("import") ? "order-first" : "order-2", "pt-1 mt-1")}>
                                <ul>
                                    {data?.data?.import?.map((link, i) => (
                                        <li key={i}>
                                            <a href={link.uri}
                                                className="block hover:bg-gray-100/50 lg:focus:bg-gray-100 focus:bg-gray-100/50 px-3 py-1.5 rounded-sm text-xs max-lg:text-white/75"
                                            >
                                                Import - {link.resourceId} - {link.name}
                                            </a>

                                        </li>
                                    ))}
                                </ul>
                            </li>
                            <li className={clsx("pt-1 mt-1", window.location.pathname.includes("leasing") ? "order-first" : "order-3")}>
                                <ul>
                                    {data?.data?.leasing?.map((link, i) => (
                                        <li key={i}>
                                            <a href={link.uri}
                                                className="block hover:bg-gray-100/50 lg:focus:bg-gray-100 focus:bg-gray-100/50 px-3 py-1.5 rounded-sm text-xs max-lg:text-white/75"
                                            >
                                                Leasing - {link.resourceId} - {link.name}
                                            </a>

                                        </li>
                                    ))}
                                </ul>
                            </li>
                            <li className={clsx("pt-1 mt-1", window.location.pathname.includes("dealership") ? "order-first" : "order-4")}>
                                <ul>
                                    {data?.data?.user?.map((link, i) => (
                                        <li key={i}>
                                            <a href={link.uri}
                                                className="block hover:bg-gray-100/50 lg:focus:bg-gray-100 focus:bg-gray-100/50 px-3 py-1.5 rounded-sm text-xs max-lg:text-white/75"
                                            >
                                                Bruger - {link.resourceId} - {link.name}
                                            </a>

                                        </li>
                                    ))}
                                </ul>
                            </li>
                        </ul>
                    </nav>
                </Transition>
            </div >
        </div >
    )
}
