import { useEffect, useState } from "react";


export function useMedia(query: string) {
    const [matches, setMatches] = useState(window.matchMedia(query).matches);

    useEffect(() => {
        const media = window.matchMedia(query);
        if (media.matches !== matches) {
            setMatches(media.matches);
        }
        media.onchange = () => setMatches(media.matches);
        return () => { media.onchange = null };
    }, [query, matches]);

    return matches;
}
