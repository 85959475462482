import TargetBullseyeImg from "@assets/images/target-bullseye.png";
import { t } from "i18next";

export default function PreAssessed() {
    return (
        <div className="text-center">
            <img className='size-16 mx-auto mb-4' src={TargetBullseyeImg} />
            <h3 className="text-lg text-dark-blue font-medium">
                {t("content:tax.status.Your pre assessment is ready")}!
            </h3>
            <p className="text-sm mt-1">
                Accepter sagen ved at vælge <strong>Betal registreringsafgift</strong> - så modtager du kvittering for værdifastsættelse indenfor 15 minutter.
            </p>
        </div>
    )
}
