import { csrf } from '@/services/auth';
import Axios, { isAxiosError } from 'axios';
import { t } from 'i18next';
import { loadingMessage } from './toast';
import { sleep } from './utils';

const axios = Axios.create({
    baseURL: import.meta.env.VITE_API_URL,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
    },
    withCredentials: true,
    withXSRFToken: true,
});

axios.interceptors.response.use(
    (response) => response,
    async (error) => {
        if (isAxiosError(error)
            && error.config
            && error.response?.status === 419
            && error.code === "ERR_BAD_REQUEST"
            && error.response?.data?.message === 'CSRF token mismatch.'
        ) {
            // refetch CSRF Mismatch -- XSRF-TOKEN expired
            try {
                await csrf()
                return await axios(error.config)
            } catch (err) {
                return Promise.reject(err)
            }
        }
        return Promise.reject(error)
    }
)
let shouldShowLoadingMessage = true;
axios.interceptors.response.use(
    (response) => response,
    async (error) => {
        if (isAxiosError(error)) {
            if (error.response?.status === 503 && error.config) {
                if (shouldShowLoadingMessage) {
                    loadingMessage(t("content:generic.systemUpdating"))
                    shouldShowLoadingMessage = false;
                }
                await sleep(30_000)
                shouldShowLoadingMessage = true
                return await axios(error.config)
            }
        }
        return Promise.reject(error)
    }
)

export default axios;
