import { CreateTaxCaseForm, TaxCaseResource } from '@/types/taxTypes';
import { ReactNode, createContext, useContext, useState } from 'react';
import { StoreApi, createStore, useStore } from 'zustand';


type State = {
    form: CreateTaxCaseForm;
    createdTaxCase: TaxCaseResource | null;
    creationMethod: "vin" | "link";
    actions: {
        setForm: (form: CreateTaxCaseForm) => void;
        setVehicle: (vehicle: CreateTaxCaseForm['vehicle']) => void;
        setVehicleItem<K extends keyof CreateTaxCaseForm['vehicle']>(key: K, value: CreateTaxCaseForm['vehicle'][K]): void;
        setInterimFiles: (interimFiles: CreateTaxCaseForm['interimFiles']) => void;
        setEvaluationTypeId: (id: CreateTaxCaseForm['evaluationTypeId']) => void;
        setPaymentMethod: (paymentMethod: CreateTaxCaseForm['paymentMethod']) => void;
        setNote: (note: CreateTaxCaseForm['note']) => void;
        setIsPriority: (isPriority: CreateTaxCaseForm['isPriority']) => void;
        setCreatedTaxCase: (createdTaxCase: TaxCaseResource) => void;
        setCreationMethod: (creationMethod: "vin" | "link") => void;
    }
}

const TaxCaseContext = createContext<StoreApi<State> | null>(null)

type TaxCaseStoreProviderProps = {
    children: ReactNode;
    initialForm: CreateTaxCaseForm;
    initialCreationMethod: "vin" | "link";

}

export const TaxCaseCreationStoreProvider = ({ children, initialCreationMethod, initialForm }: TaxCaseStoreProviderProps) => {
    const [taxCaseCreationStore] = useState(() =>
        createStore<State>((set) => ({
            form: { ...initialForm },
            createdTaxCase: null,
            creationMethod: initialCreationMethod,
            actions: {
                setVehicleItem: (key, value) => set((state) => {
                    const temp = { ...state };
                    temp.form.vehicle[key] = value;
                    return temp;
                }),
                setVehicle(vehicle) {
                    set((state) => ({
                        form: {
                            ...state.form,
                            vehicle
                        }
                    }))
                },
                setInterimFiles: (interimFiles) => set((state) => {
                    const temp = { ...state };
                    temp.form.interimFiles = interimFiles;
                    return temp;
                }),
                setEvaluationTypeId: (id) => set((state) => {
                    const temp = { ...state };
                    temp.form.evaluationTypeId = id;
                    return temp;
                }),
                setPaymentMethod: (paymentMethod) => set((state) => {
                    const temp = { ...state };
                    temp.form.paymentMethod = paymentMethod;
                    return temp;
                }),
                setNote: (note) => set((state) => {
                    const temp = { ...state };
                    temp.form.note = note;
                    return temp;
                }),
                setIsPriority: (isPriority) => set((state) => {
                    const temp = { ...state };
                    temp.form.isPriority = isPriority;
                    return temp;
                }),
                setForm(form) {
                    set(() => ({
                        form
                    }))
                },
                setCreatedTaxCase(createdTaxCase) {
                    set(() => ({
                        createdTaxCase
                    }))
                },
                setCreationMethod(creationMethod) {
                    set({ creationMethod })
                },
            }
        }))
    )

    return (
        <TaxCaseContext.Provider value={taxCaseCreationStore}>
            {children}
        </TaxCaseContext.Provider>
    )
}



function useTaxCaseCreationStore(): State
function useTaxCaseCreationStore<T>(selector: (state: State) => T): T
function useTaxCaseCreationStore<T>(selector?: (state: State) => T) {
    const store = useContext(TaxCaseContext)
    if (!store) {
        throw new Error('Missing BearStoreProvider')
    }
    return useStore(store, selector!)
}

export const useTaxCaseCreation = () => useTaxCaseCreationStore()
export const useTaxCaseCreationActions = () => useTaxCaseCreationStore((state) => state.actions)
export const useTaxCaseCreationMethod = () => useTaxCaseCreationStore((state) => state.creationMethod)
export const useTaxCaseCreationCreatedTaxCase = () => useTaxCaseCreationStore((state) => state.createdTaxCase)
export const useForm = () => useTaxCaseCreationStore((state) => state.form)
export const usePaymentMethod = () => useTaxCaseCreationStore((state) => state.form.paymentMethod)
export const useNote = () => useTaxCaseCreationStore((state) => state.form.note)
export const useIsPriority = () => useTaxCaseCreationStore((state) => state.form.isPriority)
export const useInterimFiles = () => useTaxCaseCreationStore((state) => state.form.interimFiles)
export const useFiles = () => useTaxCaseCreationStore((state) => state.form.files)
export const useEvaluationTypeId = () => useTaxCaseCreationStore((state) => state.form.evaluationTypeId)
export const useVehicle = () => useTaxCaseCreationStore((state) => state.form.vehicle)
export const useVin = () => useTaxCaseCreationStore((state) => state.form.vehicle.vin)
export const useFirstRegDate = () => useTaxCaseCreationStore((state) => state.form.vehicle.firstRegDate)
export const useMileage = () => useTaxCaseCreationStore((state) => state.form.vehicle.mileage)
export const useLink = () => useTaxCaseCreationStore((state) => state.form.vehicle.link)
export const useIsNew = () => useTaxCaseCreationStore((state) => state.form.vehicle.isNew)
export const useUseCaseId = () => useTaxCaseCreationStore((state) => state.form.vehicle.useCaseId)
