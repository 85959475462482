import { createFileRoute } from '@tanstack/react-router'
import { t } from 'i18next'
import UpdateDealerNotificationSettings from '../../../features/dealer/UpdateDealerNotificationSettings'

export const Route = createFileRoute('/_protected/_dealership/dealership/notifications')({
    meta: () => [{ title: t("notifications") }],
    component: Notifications,
})

function Notifications() {
    return (
        <section>
            <div className="sm:p-8 p-4 border-b">
                <h2 className="text-2xl font-semibold text-dark-blue">
                    {t("notifications")}
                </h2>
                <p className='mt-1 font-medium text-[14px]'>{t("content:dealer.Determine your email recipients. These e-mails receive, for example, an invoice as a CC.")}</p>
            </div>
            <div className="sm:px-8 px-4">
                <div className="sm:py-8 py-6">
                    <UpdateDealerNotificationSettings />
                </div>
            </div>
        </section>
    )
}
