import InviteNewUser from '@/features/dealer/InviteNewUser'
import { Button } from '@headlessui/react'
import { UserPlusIcon } from '@heroicons/react/24/outline'
import { createFileRoute } from '@tanstack/react-router'
import { t } from 'i18next'
import { useState } from 'react'
import { z } from 'zod'
import ManageDealerUsers from '../../../features/dealer/ManageDealerUsers'


const tablePaginationSchema = z.object({
    page: z.number().catch(1),
    per_page: z.number().optional().catch(10),
})

export const Route = createFileRoute('/_protected/_dealership/dealership/users')({
    validateSearch: tablePaginationSchema,
    meta: () => [{ title: t("users") }],
    component: Users
})


function Users() {
    const [inviteUserOpen, setInviteUserOpen] = useState(false)

    function onInviteNewUser() {
        setInviteUserOpen(true);
    }
    return (
        <section>
            <div className="sm:p-8 p-4 border-b flex justify-between flex-wrap gap-6">
                <div>
                    <h2 className="text-2xl font-semibold text-dark-blue-hover">
                        {t("users")}
                    </h2>
                    <p className="mt-1 font-medium text-[14px]">{t("content:dealer.A list of users associated with your company.")}</p>
                </div>
                <Button onClick={onInviteNewUser}
                    className="btn btn-primary max-sm:grow justify-center">
                    <UserPlusIcon className='w-5 h-5' aria-hidden="true" />
                    {t("inviteNewUser")}
                </Button>
            </div>
            <div className="divide-y sm:px-8 px-3">
                <div className="sm:py-8 py-6">
                    <ManageDealerUsers />
                </div>
            </div>

            <InviteNewUser open={inviteUserOpen} setOpen={setInviteUserOpen} />

        </section>
    )
}

