import i18next from 'i18next';
import danishTranslations from './da/common.json';
import englishTranslations from './en/common.json';

import danishStatusTranslations from './da/status.json';
import englishStatusTranslations from './en/status.json';

import danishContentTranslations from './da/content.json';
import englishContentTranslations from './en/content.json';

export const defaultNS = "common";
export const resources = {
    en: {
        common: englishTranslations,
        status: englishStatusTranslations,
        content: englishContentTranslations
    },
    da: {
        common: danishTranslations,
        status: danishStatusTranslations,
        content: danishContentTranslations
    }
} as const;

i18next.init({
    lng: "da",
    ns: [defaultNS, 'status', 'content'],
    defaultNS,
    resources,
    initImmediate: false,
})
