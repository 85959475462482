import { CreateTaxCaseForm } from '@/types/taxTypes';
import { errorMessage, successMessage } from '@/utils/toast';
import { createFileRoute, redirect } from '@tanstack/react-router';
import { t } from 'i18next';
import ContentLoading from '../components/ContentLoading';


// const contextKeys = [
//     '127',
//     'tax',
//     'taxArchive',
//     'taxDoc',
//     'resellerTax',

//     'vehicleReport',
//     'purchase',
//     'post',
//     'merchant',

//     'leasing',
//     'partnerLeasing',
//     'import',

//     'user',
//     'customer',
//     'dealer',

//     'resetPassword',
//     'login',
//     'register',

// ] as const;

type ContextKeys = '127' | 'tax' | 'taxArchive' | 'taxDoc' | 'resellerTax' | 'vehicleReport' | 'purchase' | 'post' | 'merchant' | 'leasing' | 'partnerLeasing' | 'import' | 'user' | 'customer' | 'dealer' | 'resetPassword' | 'login' | 'register'

// const searchSchema = z.object({
//     context: z.enum(contextKeys).optional().catch("127"),
//     value: z.number().or(z.string()).optional(),
// })
type RedirectSearch = {
    context: ContextKeys;
    value?: string;
    additional?: (
        { type: "tax", sourceType: 1 | 2, sourceValue?: CreateTaxCaseForm }
    ) | (
        {
            type: "taxRecalculate";
            targetId: number;
        }
    ) | (
        {
            type: "subscription",
            priceNoTax: number;
            title: string
        }
    )
}

export const Route = createFileRoute('/redirect')({
    validateSearch: (s: Record<string, unknown>): RedirectSearch => {
        return {
            context: (s?.context as ContextKeys) || "127",
            value: (s?.value as string) || "",
            additional: (s?.additional as RedirectSearch['additional']) || undefined
        }
    },
    beforeLoad: (opts) => {
        switch (opts.search.context) {
            case "dealer": throw redirect({ to: "/dealership" })
            case "user": throw redirect({ to: "/dealership/profile" })
            case "tax": {
                if (opts.search.value) {
                    throw redirect({ to: "/tax/$id", params: { id: String(opts.search.value) } })
                } else {
                    throw redirect({ to: "/tax", search: { page: 1 } })
                }
            }
            case "import": {
                if (opts.search.value) {
                    throw redirect({ to: "/import/$id", params: { id: String(opts.search.value) } })
                } else {
                    throw redirect({ to: "/import" })
                }
            }
            case "leasing": {
                if (opts.search.value) {
                    throw redirect({ to: "/leasing/$id", params: { id: String(opts.search.value) } })
                } else {
                    throw redirect({ to: "/leasing" })
                }
            }
            case "taxArchive": throw redirect({ to: "/tax", search: { page: 1 } });
            case "resetPassword": {
                if (opts.search.value) {
                    throw redirect({ to: "/forgot-password/$token", params: { token: String(opts.search.value) } })
                } else {
                    throw redirect({ to: "/forgot-password" })
                }
            }
            case "purchase": {
                // http://localhost:5173/redirect?context=purchase&value=cancel&additional=%7B%22type%22%3A%22tax%22%2C%22sourceType%22%3A2%2C%22sourceValue%22%3A%7B%22paymentMethod%22%3A3%2C%22note%22%3A%22dfgsdfg+dfgsdf+gsdfg+sdfg+sdgsdg+sdf%22%2C%22isPriority%22%3Atrue%2C%22evaluationTypeId%22%3A1%2C%22files%22%3A%5B%5D%2C%22vehicle%22%3A%7B%22isNew%22%3Afalse%2C%22useCaseId%22%3A4%2C%22mileage%22%3A7000000%2C%22vin%22%3A%22ASDASDASD12121222%22%2C%22firstRegDate%22%3A%222006-09-16T11%3A00%3A00.000Z%22%7D%7D%7D

                if (opts.search.additional?.type === 'tax') {
                    throw redirect({
                        to: "/tax/create",
                        search: {
                            value: opts.search.value as "success" | "cancel",
                            additional: opts.search.additional,
                        }
                    })
                }

                if (opts.search.additional?.type === 'taxRecalculate' && opts.search.additional.targetId) {
                    const id = String(opts.search.additional.targetId)
                    if (opts.search.value === "success") {
                        successMessage(t("status:success.Recalculation requested for", { taxCaseId: id }))
                    } else {
                        errorMessage(t("status:error.Recalculation cancelled", { taxCaseId: id }))
                    }
                    throw redirect({
                        to: "/tax/$id",
                        params: { id: id },
                    })
                }

                if (opts.search.additional?.type === 'subscription' && opts.search.value === 'cancel') {
                    throw redirect({
                        to: "/dealership/subscription"
                    })
                }

                throw redirect({
                    to: "/purchase", search: {
                        value: opts.search.value,
                        additional: opts.search.additional
                    }
                })




            }
            case "login": throw redirect({ to: "/login" })
            case "register": {
                if (opts.search.value) {
                    throw redirect({ to: "/sign-up/$token", params: { token: String(opts.search.value) } })
                } else {
                    throw redirect({ to: "/sign-up" })
                }
            }
            default: throw redirect({ to: "/" })
        }
    },
    component: ContentLoading
})


/** The backend has these context enums */
// enum Context: string implements LocalizedEnum
// {
//     use BaseEnum;

//     case UNKNOWN = '127';
//     case TAX = 'tax';
//     case TAX_ARCHIVE = 'taxArchive';
//     case RESELLER_TAX = 'resellerTax';
//     case VEHICLE_REPORT = 'vehicleReport';
//     case TAX_DOC = 'taxDoc';
//     case LEASING = 'leasing';
//     case PARTNER_LEASING = 'partnerLeasing';
//     case IMPORT = 'import';
//     case USER = 'user';
//     case CUSTOMER = 'customer';
//     case DEALER = 'dealer';
//     case ORDER = 'purchase';
//     case POST = 'post';
//     case MERCHANT = 'merchant';
//     case RESET_PASSWORD = 'resetPassword';
// }
