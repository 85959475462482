import { Credentials, RegisterAccount, RegisterAccountAsDealer, User } from "../types/auth";
import { CVRData } from "../types/dealer";
import axios from "../utils/axios";

const API_BASE_URL = import.meta.env.VITE_API_URL;
const API_PATH = import.meta.env.VITE_API_PATH;
const API_URL = API_BASE_URL + API_PATH;

export const csrf = () => axios.get(`${API_BASE_URL}/sanctum/csrf-cookie`);

/**
 * Login
 * POST http://localhost:8007/api/v2/auth/login
 */
export const postLogin = (credentials: Credentials) => {
    return axios.post(`${API_URL}/auth/login`, credentials);
}

/**
 * Logout
 * POST http://localhost:8007/api/v2/auth/logout
 */
export const postLogout = () => {
    return axios.post(`${API_URL}/auth/logout`);
}
export const getUser = async () => {
    return await axios.get<{ data: User }>(`${API_URL}/whoami`);
}


/**
 * Send password reset email
 * POST http://localhost:8007/api/v2/auth/resetPassword/initialize
 * Initiates the password reset process by sending an email to the user.
 */
export const initializeResetPassword = async (email: string) => {
    return await axios.post(`${API_URL}/auth/resetPassword/initialize`, { email });
}


/**
 * Reset user password
 * POST http://localhost:8007/api/v2/auth/resetPassword
 */
export const resetPassword = async (data: {
    email: string,
    token: string,
    password: string
}) => {
    return await axios.post(`${API_URL}/auth/resetPassword`, data);
}




/**
 * Initialize the registration process for a new dealership
 * POST http://localhost:8007/api/v2/auth/register/initialize
 */
export const initializeDealerRegistration = async (email: string) => {
    return await axios.post(`${API_URL}/auth/register/initialize`, { email });
}

/**
 * Retrieve data associated with the given registration token
 * GET http://localhost:8007/api/v2/auth/register/{token}
 * This endpoint allows retrieval of data linked to a specific registration token.
 */
export const getRegistrationTokenData = async (token: string) => {
    return await axios.get<{
        data: {
            email: string,
            validUntil: string,
            forDealer: string | null,
            role: "dealer_sales" | "dealer_admin"
        }
    }>(`${API_URL}/auth/register/${token}`);
}

/**
 * Complete the users registration process
 * POST http://localhost:8007/api/v2/auth/registerAsUser
 * Requires a valid registration token.
 */
export const registerAsUser = async (data: RegisterAccount) => {
    return await axios.post(`${API_URL}/auth/registerAsUser`, data);
}

/**
 * Complete the dealerships registration process
 * POST http://localhost:8007/api/v2/auth/registerAsDealer
 * Requires a valid registration token.
 */
export const registerAsDealer = async (data: RegisterAccountAsDealer) => {
    return await axios.post(`${API_URL}/auth/registerAsDealer`, data);
}

/**
 * Retrieve company data by CVR number
 * POST http://localhost:8007/api/v2/auth/getCvrDataGuest
 * This endpoint enables fetching company information using the CVR number from official sources. Requires a valid registration token.
 */
export const getCVRDataASGuest = (data: {
    cvr: string;
    registrationToken: string;
}) => {
    return axios.post<{ data: CVRData }>(`${API_URL}/auth/getCvrDataGuest`, data);
}


/**
 * Check if CVR number has already been claimed
 * POST http://localhost:8007/api/v2//auth/cvrInUseGuest
 * This endpoint enables fetching company information using the CVR number from official sources. Requires a valid registration token.
 */
export const cvrInUseGuest = (data: {
    cvr: string;
    registrationToken: string;
}) => {
    return axios.post<{ data: boolean }>(`${API_URL}/auth/cvrInUseGuest`, data);
}
