import { createFileRoute } from '@tanstack/react-router'
import { t } from 'i18next'
import ManageInvoicingAddresses from '../../../features/dealer/ManageInvoicingAddresses'
import ViewSavedPaymentMethods from '../../../features/dealer/ViewSavedPaymentMethods'

export const Route = createFileRoute('/_protected/_dealership/dealership/payment-methods')({
    meta: () => [{ title: t("paymentMethods") }],
    component: PaymentMethods,
})


function PaymentMethods() {
    return (
        <section>
            <div className="sm:p-8 p-4 border-b">
                <h2 className="text-2xl font-semibold text-dark-blue">
                    {t("paymentMethods")}
                </h2>
                <p className='mt-1 font-medium text-[14px]'>{t("content:dealer.Add or correct your payment methods for subscription and payment of, for example, full registration fee.")}</p>
            </div>
            <div className="divide-y sm:px-8 px-4">
                <div className="sm:py-12 py-6">
                    <ViewSavedPaymentMethods />
                </div>
                <div className="sm:py-12 py-6">
                    <ManageInvoicingAddresses />
                </div>
            </div>
        </section>
    )
}
