import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { CatchBoundary, RouterProvider, createRouter } from '@tanstack/react-router';
import { StrictMode, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { Toaster } from 'react-hot-toast';
import ContentLoading from './components/ContentLoading';
import { useMedia } from './hooks/useMedia';
import "./index.css";
import './lang/i18n';

import * as Sentry from "@sentry/react";
import { isAxiosError } from 'axios';
import { routeTree } from './routeTree.gen';



const queryClient = new QueryClient({
    queryCache: new QueryCache({
        onError: (error) => {
            if (isAxiosError(error) &&
                error.response &&
                error.response.status !== 401
            ) {
                Sentry.captureException(error)
            }
        },
    }),
})

export interface RouterContext {
    queryClient: QueryClient;
}

export const router = createRouter({
    routeTree,
    context: {
        queryClient
    },
})

// Register the router instance for type safety
declare module '@tanstack/react-router' {
    interface Register {
        router: typeof router
    }
}

Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.tanstackRouterBrowserTracingIntegration(router)
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", import.meta.env.VITE_API_URL],
});

// Forces a reload in safari browsers, when using the browser "back button".
// Solves the issue of going to stripe, and pressing back button, resulting in
// being stuck in the loading screen.
window.addEventListener('pageshow', (event) => {
    if (event.persisted) {
        if (!navigator.userAgent.includes('Chrome') && navigator.userAgent.includes('Safari')) {
            window.location.reload();
        }
    }
});

function App() {
    const small = useMedia('(max-width: 640px)')
    return (
        <QueryClientProvider client={queryClient}>
            <Suspense fallback={<ContentLoading />}>
                <RouterProvider router={router} context={{ queryClient }} />
            </Suspense>
            <Toaster toastOptions={{
                style: { zIndex: "99999", width: "100%", maxWidth: 550 },
                duration: 8000,
                error: {
                    position: small ? "bottom-center" : 'top-left'
                },
                success: {
                    position: small ? "top-center" : 'top-right',
                }
            }} />
        </QueryClientProvider>
    )
}

// Render the app
const rootElement = document.getElementById('root')!
if (!rootElement.innerHTML) {
    const root = ReactDOM.createRoot(rootElement)
    root.render(
        <StrictMode>
            <CatchBoundary
                getResetKey={() => 'reset'}
                onCatch={(error) => console.error(error)}
            >
                <App />
            </CatchBoundary>
        </StrictMode>,
    )
}

router.history.subscribe(async () => {
    if (!window.dataLayer) {
        window.dataLayer = []
    }
    window.dataLayer.push(
        {
            event: "page_view",
            virtual_page_title: document.title,
            virtual_page_location: document.location.protocol + '//' +
                document.location.host +
                router.history.location.pathname +
                router.history.location.search,
            virtual_page_referrer: window.dmf_internal_referrer ?? document.referrer
        }
    )
    window.dmf_internal_referrer = document.location.origin
})
