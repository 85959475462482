import { isValidLink } from "./typeGuards";

export function printMakeModelOrLink(make: string | null | undefined, model: string | null | undefined, link: string | null | undefined, linkAsText?: "link-as-text") {
    if ((!make || make.toLowerCase() === 'ukendt') && isValidLink(link)) {
        if (linkAsText === 'link-as-text') {
            return link;
        }
        return (
            <a href={link} onClick={e => e.stopPropagation()} target="_blank" className="link">
                Link
            </a>
        )
    }
    return `${make ?? ''} ${model ?? ''}`
}
