import { TaxCaseResource } from '@/types/taxTypes'
import { ReactNode, createContext, useContext, useEffect, useState } from 'react'
import { StoreApi, createStore, useStore } from 'zustand'


type State = TaxCaseResource

const TaxCaseContext = createContext<StoreApi<State> | null>(null)

type TaxCaseStoreProviderProps = {
    children: ReactNode;
    taxCase: TaxCaseResource;
}

export const TaxCaseStoreProvider = ({ children, taxCase }: TaxCaseStoreProviderProps) => {
    const [taxCaseStore, setTaxCaseStore] = useState(() =>
        createStore<State>(() => ({
            ...taxCase
        }))
    )

    useEffect(() => {
        if (taxCase) {
            setTaxCaseStore(() =>
                createStore<State>(() => ({
                    ...taxCase
                }))
            )
        }
    }, [taxCase])

    return (
        <TaxCaseContext.Provider value={taxCaseStore}>
            {children}
        </TaxCaseContext.Provider>
    )
}



function useTaxCaseStore(): State
function useTaxCaseStore<T>(selector: (state: State) => T): T
function useTaxCaseStore<T>(selector?: (state: State) => T) {
    const store = useContext(TaxCaseContext)
    if (!store) {
        throw new Error('Missing BearStoreProvider')
    }
    return useStore(store, selector!)
}

export const useTaxCase = () => useTaxCaseStore()
export const useTaxCaseAssessment = () => useTaxCaseStore((state) => state.assessment)
export const useTaxCaseId = () => useTaxCaseStore((state) => state.id)
export const useTaxCaseCreateAt = () => useTaxCaseStore((state) => state.createdAt)
export const useTaxCaseLinks = () => useTaxCaseStore((state) => state.links)
export const useTaxCaseMedia = () => useTaxCaseStore((state) => state.media)
export const useTaxCaseBillingDestination = () => useTaxCaseStore((state) => state.possibleBillingDestinations)
export const useTaxCasePaymentMethods = () => useTaxCaseStore((state) => state.possiblePaymentMethods)
export const useTaxCaseStatus = () => useTaxCaseStore((state) => state.status)
export const useTaxCaseVehicle = () => useTaxCaseStore((state) => state.vehicle)
export const useTaxCaseDealerNote = () => useTaxCaseStore((state) => state.note)
export const useTaxCaseClient = () => useTaxCaseStore((state) => state.client)
export const useTaxCaseOwner = () => useTaxCaseStore((state) => state.owner)
export const useTaxCaseFlags = () => useTaxCaseStore((state) => state.flags)
