
type Props = {
    imgSrc?: string;
    initials?: string;
    size?: "sm" | "md" | "lg"
}

// large = 80x80
// medium = 40x40
// small = 32x32

export default function Avatar({ imgSrc, initials, size }: Props) {

    if (imgSrc) {
        return <AvatarImg imgSrc={imgSrc} size={size} initials={initials} />
    }

    if (initials) {
        return <AvatarInitials initials={initials} size={size} />
    }

    return <AvatarDefault size={size} />
}



type AvatarInitialsProps = {
    size: Props['size'];
    initials: string;
}
function AvatarInitials({ initials, size }: AvatarInitialsProps) {
    const sizeClass = getSizeClass(size)
    let fontClass = '';
    if (size === 'lg') {
        fontClass = 'text-base'
    } else if (size === 'sm') {
        fontClass = 'text-xs'
    } else {
        fontClass = 'text-sm'
    }
    return (
        <span className={`inline-flex ${sizeClass} flex-none items-center justify-center overflow-hidden rounded-full bg-gray-500`}>
            <span className={`${fontClass} font-medium leading-none text-white`}>{initials.toUpperCase()}</span>
        </span>
    )
}

function AvatarImg({ imgSrc, size, initials }: Props) {
    const sizeClass = getSizeClass(size)
    const width = size === 'lg'
        ? 80
        : size === 'md'
            ? 40
            : 32;
    return (
        <img
            className={`${sizeClass} flex-none rounded-full object-center object-cover`}
            src={imgSrc}
            width={width} height={width}
            alt={initials}
        />
    )
}


type AvatarDefaultProps = Pick<Props, "size">
function AvatarDefault({ size }: AvatarDefaultProps) {
    const sizeClass = getSizeClass(size)
    return (
        <span className={`inline-block ${sizeClass} flex-none overflow-hidden rounded-full bg-gray-100`}>
            <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
            </svg>
        </span>
    )
}

function getSizeClass(size: Props['size']) {
    switch (size) {
        case "lg": return 'w-20 h-20';
        case "md": return "w-10 h-10";
        case "sm": return "w-8 h-8";
        default: return "w-12 h-12";
    }
}
