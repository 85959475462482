import { createFileRoute, redirect } from '@tanstack/react-router'
import { t } from 'i18next'
import { z } from 'zod'
import ContentLoading from '../../components/ContentLoading'
import { customerPortalUrlQueryOptions } from '../../hooks/useStripe'

export const Route = createFileRoute('/_protected/redirect-to-customer-portal')({
    validateSearch: z.object({
        redirect: z.string().optional().catch(''),
    }),
    pendingComponent: ContentLoading,
    beforeLoad: () => ({ title: t("loading") }),
    loaderDeps: (opts) => opts,
    loader: async ({ context, deps }) => {
        try {
            const data = await context.queryClient.ensureQueryData(customerPortalUrlQueryOptions())
            if (data.data.result) {
                // data.result is a link to the customer portal in stripe
                window.location.replace(data.data.result)
            }
        } catch (err) {
            throw redirect({ to: deps.search.redirect || "/dashboard" })
        }
    },
    component: ContentLoading
})
