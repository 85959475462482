
type Props = React.SVGProps<SVGSVGElement>
export default function DMFIcon(props: Props) {
    return (
        <svg width="32" height="33" viewBox="0 0 32 33" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
            <path
                d="m24.17 20.01-7.624 7.742a.032.032 0 0 1-.046 0L4.73 15.803a.033.033 0 0 1 0-.046l1.41-1.431a.032.032 0 0 1 .045 0l7.925 8.046a.045.045 0 0 0 .064 0l.554-.561a.046.046 0 0 0 0-.065L6.21 13.099a.067.067 0 0 0-.096 0L3.52 15.732a.07.07 0 0 0 0 .097l12.955 13.15a.067.067 0 0 0 .095 0l8.81-8.944a.069.069 0 0 0 0-.096l-8.175-8.302a.119.119 0 0 1-.006-.16 6.47 6.47 0 0 0-2.194-9.946 6.733 6.733 0 0 0-5.3.025 6.47 6.47 0 0 0-1.778 10.33L18.256 22.37a.045.045 0 0 0 .064 0l.553-.561a.046.046 0 0 0 0-.065L8.436 11.148A5.318 5.318 0 0 1 7.263 5.45c1.62-3.996 6.317-4.656 8.953-1.98a5.576 5.576 0 0 1 .378 7.36.116.116 0 0 1-.134.036.117.117 0 0 1-.04-.027l-3.992-4.053a.066.066 0 0 0-.073-.014.067.067 0 0 0-.022.014l-2.302 2.338a.045.045 0 0 0 0 .063l.555.564a.043.043 0 0 0 .031.013.043.043 0 0 0 .031-.013l1.71-1.737a.031.031 0 0 1 .023-.009.032.032 0 0 1 .022.01l11.77 11.949a.033.033 0 0 1-.002.046Z"
                fill="currentColor"
                stroke="currentColor"
                strokeWidth="0.7"
            />
        </svg>
    )
}
