import NotFound from '@/components/NotFound'
import { Spinner } from '@/components/Spinner'
import { useDealerQuery } from '@/hooks/useDealer'
import { contactSupport } from '@/services/hub'
import { ReactFormSubmit } from '@/types/helperTypes'
import { errorMessage, successMessage } from '@/utils/toast'
import { Button, Field, Input, Label, Switch } from '@headlessui/react'
import { CheckCircleIcon } from '@heroicons/react/24/outline'
import { useMutation } from '@tanstack/react-query'
import { CatchBoundary, Outlet, createFileRoute, useLoaderData } from '@tanstack/react-router'
import clsx from 'clsx'
import { t } from 'i18next'
import { useState } from 'react'

export const Route = createFileRoute('/_protected/_leasing')({
    component: LeasingLayout
})

function LeasingLayout() {
    const { user } = useLoaderData({ from: "/_protected" })
    if (!user.products.leasing) {
        return (
            <NotFound />
        )
    }

    function renderOutlet() {
        if (!user.products.leasing) {
            return <LeasingPartnerForm />
        }
        return <Outlet />
    }

    return (
        <>
            <div className="lg:hidden bg-white shadow h-16 fixed top-0 w-full z-30"></div>
            <main className="lg:pt-20 pt-16 w-full flex-1 flex flex-col">
                <div className="rounded-lg flex-1 flex flex-col">
                    <CatchBoundary
                        getResetKey={() => 'reset'}
                        onCatch={(error) => console.error(error)}
                    >
                        {renderOutlet()}
                    </CatchBoundary>
                </div>
            </main>

        </>
    )
}



function LeasingPartnerForm() {
    const { user } = useLoaderData({ from: "/_protected" })
    const { data: dealer } = useDealerQuery()
    const [isExperiencedLeaser, setIsExperiencedLeaser] = useState(false)
    const mutateContact = useMutation({
        mutationFn: (args: Parameters<typeof contactSupport>) => contactSupport(...args),
    })
    async function handleSubmit(e: ReactFormSubmit) {
        e.preventDefault()
        try {
            const data = new FormData(e.currentTarget)

            const soldPerMonth = data.get("soldPerMonth")?.toString()
            const carsLeasedPerMonth = data.get("carsLeasedPerMonth")?.toString()
            const message = `${user.name} fra ${dealer?.data.name} er interesseret i at blive leasingpartner.\r\n\r\nHar du erfaring med at tilbyde leasing? ${isExperiencedLeaser ? "Ja" : "Nej"}\r\nHvor mange biler sælger du lige nu om måneden? ${soldPerMonth}\r\nHvor mange leasingaftaler forventer du om måneden? ${carsLeasedPerMonth}`;
            await mutateContact.mutateAsync([{
                type: "leasing",
                message: message
            }])
            successMessage(t("status:success.messageSent"));
        } catch (err) {
            errorMessage(err)
        }

    }

    return (
        <section className="p-4">
            <div className="m-auto max-w-2xl bg-white shadow rounded-lg p-4">
                {mutateContact.isSuccess ? (
                    <div>
                        <CheckCircleIcon className="mx-auto size-16 fill-primary mb-4" />
                        <h2 className="text-lg font-semibold text-dark-blue text-center mb-2">{t("content:leasing.request_sent")}</h2>
                        <p className="text-sm text-center">{t("content:leasing.response_time")}</p>
                    </div>
                ) : (
                    <Spinner loading={mutateContact.isPending}>
                        <h2 className="text-lg font-semibold text-dark-blue mb-4">{t("content:leasing.partner_prompt")}</h2>
                        <p className="text-sm mb-1">{t("content:leasing.partner_benefit")}</p>
                        <p className="text-sm">{t("content:leasing.details_prompt")}</p>
                        <form onSubmit={handleSubmit} className="mt-8">
                            <Field className="p-2.5 border flex-1 border-gray-300 border-dashed rounded-lg flex items-center gap-3 data-[disabled]:bg-gray-200 data-[disabled]:opacity-50">
                                <Label className="text-sm font-medium mr-auto">{t("content:leasing.experience_prompt")}</Label>
                                <Switch
                                    checked={isExperiencedLeaser}
                                    onChange={e => setIsExperiencedLeaser(e)}
                                    className={clsx(
                                        "group relative flex flex-none items-center h-5 w-9 cursor-pointer rounded-full border border-primary transition-colors duration-200 ease-in-out",
                                        "focus:outline-none data-[focus]:outline-1 data-[focus]:outline-primary data-[checked]:bg-primary/25")}
                                >
                                    <span
                                        aria-hidden="true"
                                        className="pointer-events-none inline-block size-4 rounded-full  ring-0 shadow-lg transition duration-200 ease-in-out translate-x-0.5 bg-primary group-data-[checked]:bg-primary-active group-data-[checked]:translate-x-4"
                                    />
                                </Switch>
                            </Field>
                            <Field className="flex justify-between sm:items-center max-sm:flex-col gap-2 mt-4">
                                <Label className="input-label flex-1">{t("content:leasing.current_sales")}</Label>
                                <Input
                                    type="number"
                                    name="soldPerMonth"
                                    required
                                    className=" input max-sm:flex-1 basis-40"
                                />
                            </Field>

                            <Field className="flex justify-between sm:items-center max-sm:flex-col gap-2 mt-4">
                                <Label className="input-label flex-1">{t("content:leasing.expected_leases")}</Label>
                                <Input
                                    type="number"
                                    name="carsLeasedPerMonth"
                                    required
                                    className="input max-sm:flex-1 basis-40"
                                />
                            </Field>

                            <Button
                                disabled={mutateContact.isPending}
                                type="submit"
                                className="btn btn-primary w-full btn-lg mt-6"
                            >
                                {t("continue")}
                            </Button>
                        </form>
                    </Spinner>
                )}
            </div>
        </section>
    )
}

