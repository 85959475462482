import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Transition,
    TransitionChild
} from '@headlessui/react'
import { Cog6ToothIcon } from '@heroicons/react/24/outline'
import { CatchBoundary, Link, Outlet, createFileRoute } from '@tanstack/react-router'
import clsx from 'clsx'
import { t } from 'i18next'
import { useEffect, useState } from 'react'


export const Route = createFileRoute('/_protected/_dealership')({
    component: HubLayout
})

function HubLayout() {
    const [open, setOpen] = useState(false);
    useEffect(() => { setOpen(true) }, [])
    return (
        <Transition appear show={open} as="div" className="transition duration-1000 data-[closed]:opacity-0">
            <DealershipNavbar />
            <main className="md:px-8 px-4 md:pb-8 pb-4 lg:pt-40 md:pt-24 pt-20 mx-auto max-w-[1400px] w-full">
                <TransitionChild>
                    <div className="bg-white shadow-md min-h-full rounded-lg transition duration-500 data-[closed]:translate-y-24">
                        <CatchBoundary
                            getResetKey={() => 'reset'}
                            onCatch={(error) => console.error(error)}
                        >
                            <Outlet />
                        </CatchBoundary>
                    </div>
                </TransitionChild>
            </main>
        </Transition>
    )
}


function DealershipNavbar() {
    return (
        <Disclosure as="header" className="bg-white shadow lg:top-20 max-lg:h-16 fixed w-full z-30">
            {({ open, close }) => (
                <>
                    <div className="lg:hidden absolute right-0 top-0 w-16 h-16 flex justify-center items-center">
                        {/* Mobile menu button */}
                        <DisclosureButton className="rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 ">
                            <span className="absolute inset-0" />
                            <span className="sr-only">Open menu</span>
                            <Cog6ToothIcon className={clsx("block h-6 w-6 duration-300 transition", open && "rotate-90 text-dark-blue")} aria-hidden="true" />
                        </DisclosureButton>
                    </div>

                    {/* Desktop Menu Header */}
                    <div className="max-lg:hidden mx-auto max-w-7xl px-8 py-2">
                        <NavMenu />
                    </div>

                    {/* MOBILE MENU */}
                    <Transition show={open}>
                        <TransitionChild>
                            <DisclosurePanel as="nav"
                                className="lg:hidden bg-white absolute inset-x-0 top-16 shadow-lg border-t py-1.5 duration-100 transition origin-top data-[closed]:opacity-0 data-[closed]:scale-y-75"
                                aria-label="Global"
                            >
                                <NavMenu close={close} />
                            </DisclosurePanel>
                        </TransitionChild>
                    </Transition>
                </>
            )
            }
        </Disclosure >
    )
}

type NavMenuProps = {
    close?: () => void;
}
function NavMenu({ close }: NavMenuProps) {


    return (
        <nav className="flex max-lg:flex-col items-center justify-center lg:gap-8 gap-4 " aria-label="Global">
            <Link
                to={"/dealership"}
                activeOptions={{ exact: true }}
                activeProps={{
                    className: "bg-gray-100",
                    'aria-current': "page"
                }}
                onClick={close}
                className='max-lg:w-full text-center hover:bg-gray-50 text-gray-900 rounded-md px-3 py-2 text-sm font-medium'
            >
                {t("my")} {t("company").toLowerCase()}
            </Link>
            <Link
                to={"/dealership/users"}
                search={{
                    page: 1,
                    per_page: 10
                }}
                activeProps={{
                    className: "bg-gray-100",
                    'aria-current': "page"
                }}
                activeOptions={{ includeSearch: true, exact: true }}
                onClick={close}
                className='max-lg:w-full text-center hover:bg-gray-50 text-gray-900 rounded-md px-3 py-2 text-sm font-medium'
            >
                {t("users")}
            </Link>
            <Link
                to={"/dealership/subscription"}
                activeProps={{
                    className: "bg-gray-100",
                    'aria-current': "page"
                }}
                onClick={close}
                className='max-lg:w-full text-center hover:bg-gray-50 text-gray-900 rounded-md px-3 py-2 text-sm font-medium'
            >
                {t("subscription")}
            </Link>
            <Link
                to={"/dealership/payment-methods"}
                activeProps={{
                    className: "bg-gray-100",
                    'aria-current': "page"
                }}
                onClick={close}
                className='max-lg:w-full text-center hover:bg-gray-50 text-gray-900 rounded-md px-3 py-2 text-sm font-medium'
            >
                {t("paymentMethods")}
            </Link>
            <Link
                to={"/dealership/notifications"}
                activeProps={{
                    className: "bg-gray-100",
                    'aria-current': "page"
                }}
                onClick={close}
                className='max-lg:w-full text-center hover:bg-gray-50 text-gray-900 rounded-md px-3 py-2 text-sm font-medium'
            >
                {t("notifications")}
            </Link>
            <Link
                to={"/dealership/payment-history"}
                search={{
                    month: new Date().getMonth() + 1,
                    year: new Date().getFullYear()
                }}
                activeProps={{
                    className: "bg-gray-100",
                    'aria-current': "page"
                }}
                onClick={close}
                className='max-lg:w-full text-center hover:bg-gray-50 text-gray-900 rounded-md px-3 py-2 text-sm font-medium'
            >
                {t("paymentHistory")}
            </Link>
        </nav>
    )
}


