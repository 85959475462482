import { useMutation, useQuery } from "@tanstack/react-query";
import { createBillingDestination, deleteBillingDestination, getBillingDestination } from "../services/hub";
import { errorMessage } from "../utils/toast";

export const useBillingDestinationQuery = () => {
    return useQuery({
        queryKey: ['billingDestinations'],
        queryFn: async () => {
            const data = await getBillingDestination();
            return data.data;
        },
        staleTime: Infinity,
    })
}

export const useBillingMutation = () => {
    return useMutation({
        mutationFn: async (data: Parameters<typeof createBillingDestination>[0]) => await createBillingDestination(data),
        onError(error) {
            errorMessage(error)
        },
    })
}

export const useDeleteBillingDestination = () => {
    return useMutation({
        mutationFn: async (data: Parameters<typeof deleteBillingDestination>[0]) => await deleteBillingDestination(data),
        onError(error) {
            errorMessage(error)
        },
    })
}
