type Props = React.SVGProps<SVGSVGElement>
export default function DashboardIcon(props: Props) {
    return (
        <svg width="32" height="33" viewBox="0 0 32 33" {...props} fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle fill="currentColor"
                className="fill-opacity-duotone"
                fillOpacity={0.2}
                cx="16" cy="16.4999" r="2.66667"
            />
            <path fill="currentColor"
                className="fill-opacity-duotone"
                fillOpacity={0.2}
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16 30C23.1797 30 29 23.9558 29 16.5C29 9.04416 23.1797 3 16 3C8.8203 3 3 9.04416 3 16.5C3 23.9558 8.8203 30 16 30ZM11.3334 23.1666H21.3334L25.3334 25.8333C24.2222 27.1666 20.8 29.8333 16 29.8333C11.2 29.8333 7.7778 27.1666 6.66669 25.8333L11.3334 23.1666Z"
            />
            <path
                fill="currentColor"
                className="fill-opacity-duotone"
                fillOpacity={0.2}
                d="M16 19.1667C17.4727 19.1667 18.6666 17.9728 18.6666 16.5001C18.6666 15.0273 17.4727 13.8334 16 13.8334C14.5272 13.8334 13.3333 15.0273 13.3333 16.5001C13.3333 17.9728 14.5272 19.1667 16 19.1667Z"
            />
            <path
                d="M29.3333 16.5001C29.3333 23.8639 23.3638 29.8334 16 29.8334C8.63616 29.8334 2.66663 23.8639 2.66663 16.5001M29.3333 16.5001H26M29.3333 16.5001C29.3333 14.6038 28.9374 12.8 28.2238 11.1667M2.66663 16.5001C2.66663 9.13628 8.63616 3.16675 16 3.16675M2.66663 16.5001H5.99996M16 3.16675V6.50008M16 3.16675C19.6339 3.16675 22.9283 4.62051 25.3333 6.97817M17.9998 14.5001L26.9998 5.50012M25.4379 25.938L25.166 25.6661C24.2436 24.7437 23.7824 24.2825 23.2442 23.9527C22.767 23.6603 22.2467 23.4448 21.7025 23.3141C21.0887 23.1668 20.4365 23.1668 19.132 23.1668L12.8679 23.1668C11.5634 23.1668 10.9112 23.1668 10.2974 23.3142C9.7532 23.4448 9.23296 23.6603 8.75578 23.9527C8.21756 24.2826 7.75636 24.7438 6.83395 25.6662L6.56213 25.938M6.56213 7.16675L8.8774 9.48202M18.6666 16.5001C18.6666 17.9728 17.4727 19.1667 16 19.1667C14.5272 19.1667 13.3333 17.9728 13.3333 16.5001C13.3333 15.0273 14.5272 13.8334 16 13.8334C17.4727 13.8334 18.6666 15.0273 18.6666 16.5001Z"
                stroke="currentColor"
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
