export function isFulfilled<T>(val: PromiseSettledResult<T>): val is PromiseFulfilledResult<T> {
    return val.status === 'fulfilled';
}


export function isValidLink(link: unknown): link is string {
    if (typeof link !== "string") return false;
    try {
        new URL(link)
        return true;
    } catch (_e) {
        return false;
    }
}
