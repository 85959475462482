type Props = React.SVGProps<SVGSVGElement>
export default function EquipmentIcon(props: Props) {
    return (
        <svg width="32" height="33" viewBox="0 0 32 33" {...props} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.33337 11.1666V23.4333C5.33337 25.6735 5.33337 26.7936 5.76935 27.6492C6.15284 28.4019 6.76476 29.0138 7.51741 29.3973C8.37306 29.8333 9.49316 29.8333 11.7334 29.8333H20.2667C22.5069 29.8333 23.627 29.8333 24.4827 29.3973C25.2353 29.0138 25.8472 28.4019 26.2307 27.6492C26.6667 26.7936 26.6667 25.6735 26.6667 23.4333V11.1666C26.6667 9.92662 26.6667 9.30664 26.5304 8.79798C26.1605 7.41761 25.0824 6.33942 23.702 5.96955C23.1933 5.83325 22.5733 5.83325 21.3334 5.83325C21.3334 6.57999 21.3334 7.48669 21.188 7.77191C21.0602 8.02279 20.8562 8.22676 20.6054 8.35459C20.3201 8.49992 19.9468 8.49992 19.2 8.49992H12.8H12.8C12.0533 8.49992 11.6799 8.49992 11.3947 8.35459C11.1438 8.22676 10.9399 8.02279 10.812 7.77191C10.6667 7.48669 10.6667 6.57999 10.6667 5.83325C9.42675 5.83325 8.80676 5.83325 8.2981 5.96955C6.91773 6.33942 5.83954 7.41761 5.46967 8.79798C5.33337 9.30664 5.33337 9.92662 5.33337 11.1666Z"
                className="fill-opacity-duotone"
                fillOpacity="0.2"
                fill="currentColor"
            />
            <path
                d="M21.3334 5.83341C22.5733 5.83341 23.1933 5.83341 23.702 5.96971C25.0824 6.33958 26.1605 7.41777 26.5304 8.79814C26.6667 9.3068 26.6667 9.92679 26.6667 11.1667V14.2334V17.3001V23.4334C26.6667 25.6736 26.6667 26.7937 26.2307 27.6494C25.8472 28.402 25.2353 29.0139 24.4827 29.3974C23.627 29.8334 22.5069 29.8334 20.2667 29.8334H11.7334C9.49316 29.8334 8.37306 29.8334 7.51741 29.3974C6.76476 29.0139 6.15284 28.402 5.76935 27.6494C5.33337 26.7937 5.33337 25.6736 5.33337 23.4334V11.1667C5.33337 9.92679 5.33337 9.3068 5.46967 8.79814C5.83954 7.41777 6.91773 6.33958 8.2981 5.96971C8.80676 5.83341 9.42675 5.83341 10.6667 5.83341M12 18.5001L14.6667 21.1667L20.6667 15.1667M12.8 8.50008H19.2C19.9468 8.50008 20.3201 8.50008 20.6054 8.35476C20.8562 8.22693 21.0602 8.02295 21.188 7.77207C21.3334 7.48685 21.3334 7.11348 21.3334 6.36675V5.30008C21.3334 4.55334 21.3334 4.17998 21.188 3.89476C21.0602 3.64388 20.8562 3.4399 20.6054 3.31207C20.3201 3.16675 19.9468 3.16675 19.2 3.16675H16H12.8C12.0533 3.16675 11.6799 3.16675 11.3947 3.31207C11.1438 3.4399 10.9399 3.64388 10.812 3.89476C10.6667 4.17998 10.6667 4.55334 10.6667 5.30008V6.36675C10.6667 7.11348 10.6667 7.48685 10.812 7.77207C10.9399 8.02295 11.1438 8.22693 11.3947 8.35476C11.6799 8.50008 12.0533 8.50008 12.8 8.50008Z"
                stroke="currentColor"
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>

    )
}
