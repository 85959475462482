import { formatPhone } from '@/utils/string';
import { Button } from '@headlessui/react';
import { t } from 'i18next';
import { useState } from 'react';
import Avatar from '../../components/Avatar';
import ContentLoading from '../../components/ContentLoading';
import PaginatedDataPaginationButtons from '../../components/PaginatedDataPaginationButtons';
import { Spinner } from '../../components/Spinner';
import { checkIsAdmin } from '../../enums/enums';
import { usePaginatedUsersQuery } from '../../hooks/useDealer';
import { useProfile } from '../../hooks/useProfile';
import { UserResource } from '../../types/auth';
import EditDealerUser from './EditDealerUser';


export default function ManageDealerUsers() {
    const usersQuery = usePaginatedUsersQuery()

    const [selectedUser, setSelectedUser] = useState<UserResource['data'] | null>(null)

    function onCloseEditUser() {
        setSelectedUser(null)
    }



    if (usersQuery.status === 'pending' || !usersQuery.data) {
        return <ContentLoading />
    }

    return (<>
        <UsersTable query={usersQuery} setSelectedUser={setSelectedUser} />
        <EditDealerUser user={selectedUser} onClose={onCloseEditUser} />
    </>)
}

type Props = {
    query: ReturnType<typeof usePaginatedUsersQuery>;
    setSelectedUser: (user: UserResource['data']) => void;
}
function UsersTable({ query, setSelectedUser }: Props) {
    const { data: profile } = useProfile();
    const isAdmin = checkIsAdmin(profile?.data.role.value || '');
    const { data, isFetching, isPending } = query
    const users = data!.data;
    const pagination = data!.meta;


    return (
        <Spinner loading={isFetching || isPending}>
            <div className="flow-root">
                <div className="overflow-x-auto sm:-mx-6 lg:-mx-8 pb-1">
                    <div className="inline-block min-w-full align-middle sm:px-6 lg:px-8">
                        <table className="min-w-full divide-y divide-gray-300">
                            <thead>
                                <tr>
                                    <th scope="col" className="dmf-table-cell first">
                                        {t("name")}
                                    </th>
                                    <th scope="col" className="dmf-table-cell hidden sm:table-cell">
                                        {t("email")}
                                    </th>
                                    <th scope="col" className="dmf-table-cell hidden md:table-cell">
                                        {t("phoneNumber")}
                                    </th>
                                    <th scope="col" className="dmf-table-cell hidden md:table-cell">
                                        {t("role")}
                                    </th>
                                    <th scope="col" className="relative pl-3 pr-4 sm:pr-0">
                                        <span className="sr-only">{t("edit")}</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                                {users.map((user) => (
                                    <tr key={user.email}>
                                        <td className="dmf-table-cell first whitespace-nowrap">
                                            <div className="flex items-center">
                                                <Avatar
                                                    imgSrc={user.avatar?.uri}
                                                    initials={user.initials}
                                                    size={"sm"}
                                                />
                                                <div className="ml-4">
                                                    <div className="font-medium text-text-primary">{user.name}</div>
                                                    <div className="mt-1 text-xs text-text-secondary">ID: {user.id}</div>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="dmf-table-cell hidden sm:table-cell">{user.email}</td>
                                        <td className="dmf-table-cell hidden md:table-cell">{formatPhone(user.phone)}</td>
                                        <td className="dmf-table-cell hidden md:table-cell">
                                            <span className="rounded-full bg-gray-100 px-2 py-1 text-xs/5 text-gray-700">
                                                {user.role.name}
                                            </span>
                                        </td>
                                        <td className="dmf-table-cell pl-3 pr-4 text-right">
                                            <Button onClick={() => setSelectedUser(user)}
                                                disabled={!isAdmin}
                                                className="link"
                                            >
                                                {t("edit.fix")}
                                                <span className="sr-only">, {user.name}</span>
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>

                        </table>
                    </div>
                </div>
                <div className="sm:pt-12 pt-6">
                    <PaginatedDataPaginationButtons pagination={pagination} />
                </div>
            </div>
        </Spinner>
    )
}
