import { companyConfig } from "@/config";

export const sleep = (time: number) => {
    return new Promise(r => setTimeout(r, time))
}

export const raise = (msg: string): never => {
    throw new Error(msg)
}

export function debounce<F extends (...args: any[]) => void>(callback: F, timeout = 500) {
    let timer: ReturnType<typeof setTimeout>;
    return function (...args: Parameters<F>) {
        clearTimeout(timer);
        timer = setTimeout(() => callback(...args), timeout);
    };
}

export function isOutsideWorkingHours(date: string | Date) {
    const d = new Date(date)
    if (d.getDay() === 6 || d.getDay() === 0) { // 6 = Saturday, 0 = Sunday
        return true;
    }
    if (d.getHours() < companyConfig.workingHours.start || d.getHours() > companyConfig.workingHours.end) {
        return true
    }
    return false;
}
