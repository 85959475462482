export const companyConfig = {
    phone: {
        company: "+4570261515",
        nicklasTax: "+4527124560"
    },
    emails: {
        info: "info@danskmotorfinans.dk",
        tax: "tax@danskmotorfinans.dk",
        import: "import@danskmotorfinans.dk",
        leasing: "leasing@danskmotorfinans.dk",
        bookKeeping: "bogholderi@danskmotorfinans.dk",
    },
    bankInfo: {
        name: "Spar Nord Bank",
        registrationNumber: "9277",
        accountNumber: "1910 110 132"
    },
    links: {
        subscriptionTerms: "https://api.danskmotorfinans.net/static/Abonnementsaftale.pdf",
        businessTerms: "https://api.danskmotorfinans.net/static/Forretningsbetingelser%20v%C3%A6rdifasts%C3%A6ttelse%20-%20240619-A.pdf"
    },
    workingHours: {
        start: 8,
        end: 16
    }
} as const

export const subscriptionConfig = {
    starterSubscription: {
        totalCredits: 3,
        title: "Starter",
    }
} as const;
