import { EnumItem } from "@/types/appTypes";

export const taxCaseProgressSteps = [
    {
        "value": ["6", "7"],
        "name": "Sag oprettet"
    },
    {
        "value": ["5"],
        "name": "Forhåndsvurderet"
    },
    {
        "value": ["4"],
        "name": "Accepteret"
    },
    {
        "value": ["10"],
        "name": "Betalt"
    },
    {
        "value": ["3"],
        "name": "Gennemført"
    },
]


export const isCancelledState = (status: EnumItem) => status.value === '1';
export const isExpiredState = (status: EnumItem) => status.value === '2';
export const isInitialState = (status: EnumItem) => taxCaseProgressSteps[0].value.includes(status.value)
export const isAssessmentState = (status: EnumItem) => taxCaseProgressSteps[1].value.includes(status.value)
export const isAcceptedState = (status: EnumItem) => taxCaseProgressSteps[2].value.includes(status.value)
export const isPurchasedState = (status: EnumItem) => taxCaseProgressSteps[3].value.includes(status.value)
export const isCompletedState = (status: EnumItem) => taxCaseProgressSteps[4].value.includes(status.value)

export const getStatusStepsCompleted = (status: EnumItem) => {

    if (isCompletedState(status)) {
        return {
            initialState: true,
            assessmentState: true,
            acceptedState: true,
            purchasedState: true,
            completedState: true,
        }
    }
    if (isPurchasedState(status)) {
        return {
            initialState: true,
            assessmentState: true,
            acceptedState: true,
            purchasedState: true,
            completedState: false,
        }
    }
    if (isAcceptedState(status)) {
        return {
            initialState: true,
            assessmentState: true,
            acceptedState: true,
            purchasedState: false,
            completedState: false,
        }
    }
    if (isAssessmentState(status)) {
        return {
            initialState: true,
            assessmentState: true,
            acceptedState: false,
            purchasedState: false,
            completedState: false,
        }
    }
    return {
        initialState: true,
        assessmentState: false,
        acceptedState: false,
        purchasedState: false,
        completedState: false,
    }
}
