import { LinkResource } from "@/types/appTypes";
import { isFulfilled } from "./typeGuards";

export const MAX_UPLOAD_SIZE_AVATAR = 2 // 2 MB

export const fileToBase64 = (file: File): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result?.toString() || '');
        reader.onerror = error => reject(error);
    })
}

export const fileListToBase64 = async (files: File[] | FileList) => {
    const base64Promises: Promise<string>[] =
        Array.from(files)
            .map((file) => new Promise((resolve, reject) => {
                fileToBase64(file)
                    .then(base64 => {
                        resolve(base64)
                    })
                    .catch(err => reject(err))
            }))
    return await Promise.allSettled(base64Promises)
}

export async function fileListToBase64LinkResource(files: File[]): Promise<LinkResource[]> {
    const hasFile = (files[0] as File).size > 0;
    let base64EncodedFileList: LinkResource[] = [];
    if (hasFile) {
        const base64Files = await fileListToBase64(files);
        base64EncodedFileList = files.map((file, i) => {
            let uri = ''
            const prom = base64Files[i]
            if (isFulfilled(prom)) {
                uri = prom.value
            }
            return {
                uri,
                name: file.name
            }
        })
    }

    return base64EncodedFileList

}

/**
 * Download contents as a file
 * Source: https://stackoverflow.com/questions/14964035/how-to-export-javascript-array-info-to-csv-on-client-side
 */
export const exportCsv = (titles: string[], data: string[][], delimiter: string = ';') => {
    const rows = [
        [...titles],
        ...data.map(item => item.map(val => String(val)))
    ]
    let csvContent = rows.map(r => r.join(delimiter)).join("\n");

    const blob = new Blob([csvContent], { type: "data:text/csv;charset=utf-8" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "export.csv");
    document.body.appendChild(link); // Required for FF

    link.click(); // This will download the data file named "my_data.csv".
    link.remove()
}

export const convertBytes = (bytes: number, unit: "b" | "kb" | "mb" | "gb") => {
    switch (unit) {
        case "b": return bytes;
        case "kb": return bytes / 1024;
        case "mb": return bytes / (1024 ** 2);
        case "gb": return bytes / (1024 ** 3);
        default: return bytes;
    }
}

export const formatFileSize = (size: number) => {
    const MB = convertBytes(size, "mb")
    let fileSizeText = MB.toFixed(2) + " MB."
    if (MB < 1) {
        const KB = convertBytes(size, "kb")
        fileSizeText = KB.toFixed(2) + " KB."
    }
    return fileSizeText;
}
